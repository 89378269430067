
export enum PricingMethod {
  FLAT_BVI = 'Flat BVI',
  FLAT_FTE = 'Flat FTE',
  FLAT_PACKAGE = 'Flat Package',
  TM = 'Time and Material',
  TM_MVC = 'Time and Material with MVC',
  TRX_BVI = 'Transactional BVI',
  TRX_BVI_MVC = 'Transactional BVI with MVC',
  TRX_FTE = 'Transactional FTE',
  TRX_FTE_MVC = 'Transactional FTE with MVC',
}
