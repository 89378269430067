import { Employee } from './employee';
import { Portfolio } from './portfolio';
import { PspElement } from './psp-element';
import { DepartmentDto } from '../dto/department.dto';

export interface BusinessArea {
  id: number;
  title: string;
  name: string;  //TODO fix interface problems - title is deprecated and should not be used anymore
  longName: string;
  originDC?: string;
  areaNumber?: number; // used in contracts
  manager: Employee;
  // managerEmail: string;
  signatureTech: Employee;
  // signatureTechEmail: string;
  signatureCommercial: Employee;
  // portfolio?: PortfolioWithBusinessAreas;
  portfolio?: Portfolio;
  pspElement?: PspElement;
  departments?: DepartmentDto[];
  // signatureCommercialEmail: string;
  parent: BusinessAreaDto | null;
  children: BusinessAreaDto[];
  type?: BusinessAreaType;
}

export interface BusinessAreaDto {
  id: number;
  title: string;
  name: string;  //TODO fix interface problems - title is deprecated and should not be used anymore
  longName: string;
  originDC?: string;
  areaNumber?: number; // used in contracts
  manager: Employee;
  // managerEmail: string;
  signatureTech: Employee;
  // signatureTechEmail: string;
  signatureCommercial: Employee;
  // portfolio?: PortfolioWithBusinessAreas;
  portfolio?: Portfolio;
  pspElement?: PspElement;
  // signatureCommercialEmail: string;
  parent: BusinessAreaDto | null;
  children: BusinessAreaDto[];
  type?: BusinessAreaType;
}

export interface BusinessAreaWithoutConvert {
  id: number;
  name: string;
}

export enum BusinessAreaType {
  BusinessArea = 'BusinessArea',
  Portfolio = 'Portfolio',
  Squad = 'Squad'
}
