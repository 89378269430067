import { Injectable } from '@angular/core';
import { DateService, Day, Week } from '@ceres/shared/services';
import {
  AccountingRow,
  Capacity,
  CapacityBase,
  CapacityChange,
  CapacityGetResponse,
  CapacityTableChanges,
  CapacityTableData
} from '@ceres/domain';
import { flatten } from 'lodash-es';
import { format } from 'date-fns';
import { Employee } from '@ceres/shared/services';
import { CapacityUnitModel } from '../interfaces/capacity-unit.model';

/// TODO: Change this back from any
// case 1: new entries -> no id + work
const newEntryPredicate_V2 = (wl: any) => {
  return (!wl.id || wl.id <= -1) && wl.hours > 0;
};

/// TODO: Change this back from any
// case 2: updated entries -> id + orig val != cur val
const updatedEntryPredicate_V2 = (wl: any) => {
  return (
    wl.id &&
    wl.id > -1 &&
    ((wl.hours && wl.hours !== 0 && wl.hours !== wl.originalHours) ||
      wl.comment != wl.originalComment)
  );
};

/// TODO: Change this back from any
// case 3: deleted -> id + no work
const deletedEntryPredicate_V2 = (wl: any) => wl.id > -1 && !wl.hours;

const missingCommentPredicate = ({
  weekSum,
  comment,
  hours,
  employeeWorkingHours
}: MissingCommentEntry) => {
  return !comment && hours > 0 && weekSum >= 1.2 * employeeWorkingHours;
};

export interface MissingCommentEntry {
  weekSum: number;
  hours: number;
  comment: string;
  from: string;
  to: string;
  employeeWorkingHours: number;
}

@Injectable({
  providedIn: 'root'
})
export class CapacityCalculationService {
  constructor(private dateService: DateService) {}

  createCapacitiesForWorkload(
    data,
    weeks: Week[],
    employee: Employee,
    capacities: Capacity[]
  ) {
    for (const item of data) {
      if (!item.capacity) {
        item.capacity = [];
      }
      for (const week of weeks) {
        const capacity = capacities.find((e) => {
          return e.mpNumber === item.mpNumber && e.weekNo === week.weekNo;
        });

        const existing = item.capacity.some((e) => {
          return e.weekNo === week.weekNo;
        });
        if (!existing) {
          const project = Object.assign({}, item);
          delete project.capacity;
          item.capacity.push({
            id: capacity ? capacity.id : -1,
            mpNumber: item.mpNumber,
            year: week.last.year,
            weekNo: week.weekNo,
            from: week.first.day,
            to: week.last.day,
            employee: employee,
            originalHours: capacity ? capacity.hours : 0,
            originalComment: capacity ? capacity.comment : null,
            hours: capacity ? capacity.hours : 0,
            comment: capacity ? capacity.comment : null,
            project: project
          } as Capacity);
        }
      }
    }
  }

  createCapacitiesForPortfolio(portfolio: any) {
    portfolio.averageWorkload =
      portfolio.employees.reduce((sum, curr) => sum + curr.averageWorkload, 0) /
      portfolio.employees.length;

    portfolio.calculatedWorkload = portfolio.employees.reduce(
      (sum, curr) => sum + curr.calculatedWorkload,
      0
    );
    portfolio.hoursWorkload = portfolio.calculatedWorkload;

    portfolio.workingTime = portfolio.employees.reduce(
      (sum, curr) => sum + curr.workingTime,
      0
    );
    portfolio.restHours = portfolio.workingTime - portfolio.calculatedWorkload;

    portfolio.workload = [];
    const workloads = portfolio.employees.map((e) => e.workload);

    for (let i = 0; i < workloads[0].length; i++) {
      portfolio.workload[i] =
        workloads.reduce((sum, curr) => sum + curr[i], 0) / workloads.length;
    }
  }

  createCapacitiesForEmployees(
    data: Employee[],
    weeks: Week[],
    capacities: Capacity[]
  ) {
    const result = [];
    for (const item of data) {
      if (!item.capacity) {
        item.capacity = [];
      }

      let calculatedWorkload = 0;
      for (const week of weeks) {
        if (capacities && capacities.length > 0) {
          const capacity = capacities.filter((e) => {
            return e.employee.id === item.id && e.weekNo === week.weekNo;
          });
          for (const cap of capacity) {
            item.capacity.push(cap);
            calculatedWorkload += cap.hours;
          }
        }
      }

      for (const week of weeks) {
        if (!item['workload']) {
          item['workload'] = [];
        }
        item['workload'][week.number] = this.calculateWorkload(item, week);
      }

      item['calculatedWorkload'] = calculatedWorkload;
      const workingTime = this.getWorkingTimePerMonthByWeeks(weeks, item);

      item['workingTime'] = workingTime;
      item['averageWorkload'] =
        calculatedWorkload > 0 && workingTime > 0
          ? calculatedWorkload / workingTime
          : 0;
      item['hoursWorkload'] = calculatedWorkload;
      item['restHours'] = workingTime - calculatedWorkload;
      result.push(new Employee(item));
    }
    return result;
  }

  calculateWorkload(employee: Employee, elementTime: Week) {
    let workload: number;
    if (employee['capacity']) {
      let sum = 0;
      for (const capacity of employee['capacity']) {
        const weekNo = capacity.weekNo;
        const year = capacity.year;

        if (weekNo === elementTime.weekNo && year === elementTime.first.year) {
          sum += capacity.hours;
        }
      }
      const timeDiff = Math.abs(
        elementTime.last.date.getTime() - elementTime.first.date.getTime()
      );
      const difference = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
      const days = this.dateService.getDays(elementTime, elementTime);
      const hours = this.getWorkingTimePerMonth(days, employee);
      const intermediateResult = (hours / 5) * difference;
      workload = intermediateResult > 0 ? sum / intermediateResult : 0;
    }
    return workload >= 0 ? parseFloat(workload.toFixed(2)) : 0;
  }

  getWorkingTimePerMonth(displayedDays: Day[], employee: Employee) {
    const workingDays = displayedDays.filter(
      (day) =>
        day.value !== 0 && // not saturday
        day.value !== 6 && // not sunday
        !day.isHoliday // not public holDay
    );
    // amount of hours per week * possible working days (excluding weekend and holDays) / workdays
    return (workingDays.length * employee.sollstunden) / 5;
  }

  getWorkingTimePerMonthByWeeks(weeks: Week[], employee: Employee) {
    if (weeks && weeks.length > 0 && employee) {
      const displayedDays = this.dateService.getDays(
        weeks[0],
        weeks[weeks.length - 1]
      );
      const workingDays = displayedDays.filter(
        (day) =>
          day.value !== 0 && // not saturday
          day.value !== 6 && // not sunday
          !day.isHolDay // not public holDay
      );
      // amount of hours per week * possible working days (excluding weekend and holDays) / workdays
      return (workingDays.length * employee.sollstunden) / 5;
    }
    return 0;
  }

  getWorkingTimePerMonthMultiple(displayedDays: Day[], employees: Employee[]) {
    const workingDays = displayedDays.filter(
      (day) =>
        day.value !== 0 && // not saturday
        day.value !== 6 && // not sunday
        !day.isHoliday // not public holDay
    );
    // amount of hours per week * possible working days (excluding weekend and holDays) / workdays

    const sumAll = employees
      .map((e) => e.sollstunden)
      .reduce((sum, curr) => Number(sum) + Number(curr), 0);

    return (workingDays.length * sumAll) / 5;
  }

  getWorkingHoursPerWeek(
    data: AccountingRow[] | AccountingRow,
    year: number,
    weekNo: number,
    from: number,
    to: number
  ) {
    if (!Array.isArray(data)) {
      data = [data];
    }

    return data
      .map((e) => e.capacity)
      .filter((e) => e)
      .map((a) =>
        a
          .filter(
            (e) =>
              e.from === from &&
              e.year === year &&
              e.weekNo === weekNo &&
              e.to === to
          )
          .map((acc) => acc.hours || 0)
          .reduce((sum, curr) => sum + curr, 0)
      )
      .reduce((sum, curr) => sum + curr, 0);
  }

  getWorkingHoursAndPercentagePerWeek(
    data: any,
    week: Week,
    employees: Employee[]
  ): {
    hoursLeft: number;
    percentageWorked: number;
  } {
    const days = this.dateService.getDays(week, week);
    const haveTo = this.getWorkingTimePerMonthMultiple(days, employees);

    if (!Array.isArray(data)) {
      data = [data];
    } else {
      if (data[0].employees) {
        data = flatten(data.filter((e) => e).map((e) => e.employees));
      }
    }
    const hours = data
      .map((e) => e.capacity)
      .filter((e) => e)
      .map((a) =>
        a
          .filter(
            (e) =>
              e.from === week.first.day &&
              e.year === week.last.year &&
              e.weekNo === week.first.weekNo &&
              e.to === week.last.day
          )
          .map((acc) => acc.hours || 0)
          .reduce((sum, curr) => Number(sum) + Number(curr), 0)
      )
      .reduce((sum, curr) => Number(sum) + Number(curr), 0);

    return {
      hoursLeft: haveTo - hours,
      percentageWorked: hours / haveTo
    };
  }

  getWorkingHoursPerProject({ capacity }: AccountingRow) {
    if (!capacity) {
      return 0;
    }
    return capacity
      .map((acc) => acc.hours || 0)
      .reduce((sum, curr) => sum + curr, 0);
  }

  getWorkingHoursPerMonth(data: AccountingRow[]) {
    return data
      .map((e) => e.capacity)
      .filter((e) => e)
      .map((a) =>
        a.map((acc) => acc.hours || 0).reduce((sum, curr) => sum + curr, 0)
      )
      .reduce((sum, curr) => sum + curr, 0);
  }

  getWorkload(item: AccountingRow, week: Week) {
    return item.capacity.find(
      (e) =>
        week.last.year === e.year &&
        week.weekNo === e.weekNo &&
        week.first.day === e.from &&
        week.last.day === e.to
    );
  }

  getWorkingTimePerDays(displayedDays: Day[], employee: Employee) {
    const workingDays = displayedDays.filter(
      (day) =>
        day.value !== 0 && // not saturday
        day.value !== 6 && // not sunday
        !day.isHoliday // not public holDay
    );
    // amount of hours per week * possible working days (excluding weekend and holDays) / workdays
    // TODO change hard coded `8`
    return (workingDays.length * 8) / 5;
  }

  //###################
  //capacities
  //###################

  createCapacities(
    capacityObject: CapacityTableData[],
    calendarUnits: CapacityUnitModel[]
  ) {
    const clonedCapacityObject = [...capacityObject];
    for (const data of clonedCapacityObject) {
      if (!data.project) {
        continue;
      }

      for (const week of calendarUnits) {
        const capacity = this.findCapacityByWeek(data, week);

        if (capacity) {
          capacity.originalHours = capacity.hours;
          capacity.originalComment = capacity.comment;
        } else {
          const newCapacity = {
            id: -1,
            comment: null,
            from: format(week.startDate, DateService.DB_DATE_FORMAT),
            to: format(week.endDate, DateService.DB_DATE_FORMAT),
            hours: 0,
            originalHours: 0,
            originalComment: null
          } as CapacityBase;
          data.capacities.push(newCapacity);
        }
      }
    }

    return clonedCapacityObject;
  }

  private findCapacityByWeek(data, week: CapacityUnitModel) {
    return data.capacities.find((e) => {
      return (
        e.from >= format(week.startDate, DateService.DB_DATE_FORMAT) &&
        e.to <= format(week.endDate, DateService.DB_DATE_FORMAT)
      );
    });
  }

  getCapacity(item, week: Week): CapacityBase {
    return (
      item.capacities &&
      item.capacities.find(
        (capacity) => format(week.first.date, 'yyyy-MM-dd') === capacity.from
      )
    );
  }

  getWorkingHoursPerMonth_V2(data: CapacityTableData[]) {
    return data
      .map((e) => e.capacities)
      .filter((e) => e)
      .map((a) =>
        a.map((acc) => acc.hours || 0).reduce((sum, curr) => sum + curr, 0)
      )
      .reduce((sum, curr) => sum + curr, 0);
  }

  private getCommentEntry(
    allData: CapacityTableData[],
    capacity: CapacityBase | CapacityChange,
    employee: Employee
  ): MissingCommentEntry {
    return {
      weekSum: this.getWorkingHoursPerWeek_V2(
        allData,
        capacity.from,
        capacity.to
      ),
      hours: capacity.hours,
      comment: capacity.comment,
      to: capacity.to,
      from: capacity.from,
      employeeWorkingHours: employee.sollstunden
    };
  }

  isCommentMissing(
    employee: Employee,
    allData: CapacityTableData[],
    element: CapacityTableData,
    capacity: CapacityBase
  ): boolean {
    let entry: MissingCommentEntry = this.getCommentEntry(
      allData,
      capacity,
      employee
    );
    return missingCommentPredicate(entry);
  }

  getMissingComments(
    allData: CapacityTableData[],
    changes: CapacityTableChanges,
    employee: Employee
  ): MissingCommentEntry[] {
    return changes.new
      .concat(changes.updated)
      .map((wl) => this.getCommentEntry(allData, wl, employee))
      .filter(missingCommentPredicate);
  }

  getWorkingHoursPerWeek_V2(
    data: CapacityTableData[],
    from: string,
    to: string
  ) {
    return data
      .map((element) => element.capacities)
      .filter((element) => element)
      .map((capacities) =>
        capacities
          .filter((capacity) => capacity.from === from && capacity.to === to)
          .map((capacity) => capacity.hours || 0)
          .reduce((sum, curr) => sum + curr, 0)
      )
      .reduce((sum, curr) => sum + curr, 0);
  }

  getWorkingHoursPerProject_V2({ capacities }: CapacityGetResponse) {
    if (!capacities) {
      return 0;
    }
    return capacities
      .map((capacity) => capacity.hours || 0)
      .reduce((sum, curr) => sum + curr, 0);
  }

  getChanges(data: CapacityTableData[]): CapacityTableChanges {
    return data
      .filter((data) => data.project)
      .map((data) => {
        const newEntry = data.capacities.filter(newEntryPredicate_V2);
        const updatedEntry = data.capacities.filter(updatedEntryPredicate_V2);
        const deletedEntry = data.capacities.filter(deletedEntryPredicate_V2);

        return {
          new:
            newEntry.length > 0
              ? newEntry.map((entry) => {
                  return { ...entry, project: data.project };
                })
              : [],
          updated:
            updatedEntry.length > 0
              ? updatedEntry.map((entry) => {
                  return { ...entry, project: data.project };
                })
              : [],
          deleted:
            deletedEntry.length > 0
              ? deletedEntry.map((entry) => {
                  return { ...entry, project: data.project };
                })
              : []
        } as CapacityTableChanges;
      })
      .reduce(
        (res, curr) => ({
          new: res.new.concat(curr.new),
          updated: res.updated.concat(curr.updated),
          deleted: res.deleted.concat(curr.deleted)
        }),
        {
          new: [],
          updated: [],
          deleted: []
        }
      );
  }

  areChangesAvailable(data: CapacityTableData[]) {
    return data
      ? data
          .filter((value) => value.capacities)
          .map((value) => [...value.capacities])
          .reduce((prev, curr) => [...prev, ...curr], [])
          .some(
            (wl) =>
              newEntryPredicate_V2(wl) ||
              updatedEntryPredicate_V2(wl) ||
              deletedEntryPredicate_V2(wl)
          )
      : false;
  }
}
