import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Workload, WorkloadChanges } from '@ceres/domain';
import { Month } from "@ceres/shared/services";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { format } from 'date-fns';
import { ImpersonatedHttpClient } from "@ceres/shared/services";

@Injectable()
export class TimeSheetsService {
  constructor(private httpClient: ImpersonatedHttpClient) {}

  public async getByIDAndMonth(
    id: number,
    monthTo: Month
  ): Promise<Workload[]> {
    if (!monthTo || !monthTo.Date) {
      throw Error('Month must be set!');
    }
    const month = `${monthTo.Date.getMonth() + 1}`;
    const year = `${monthTo.Date.getFullYear()}`;
    return await this.httpClient
      .get<Workload[]>(`${environment.edgeService}/time-sheets/${id}`, {
        params: { year, month }
      })
      .pipe(workloads => this.mapWorkloads(workloads))
      .toPromise();
  }

  public async getByMPNumberAndMonth(
    mpNumber: number,
    monthTo: Month
  ): Promise<Workload[]> {
    if (!monthTo || !monthTo.Date) {
      throw Error('Month must be set!');
    }
    const month = `${monthTo.Date.getMonth() + 1}`;
    const year = `${monthTo.Date.getFullYear()}`;
    return await this.httpClient
      .get<Workload[]>(
        `${environment.edgeService}/time-sheets/mp/${mpNumber}`,
        { params: { year, month } }
      )
      .pipe(workloads => this.mapWorkloads(workloads))
      .toPromise();
  }

  public getDataForExport(data: any[]) {
    const result = [];
    data.forEach(project => {
      project.workload.forEach(workload => {
        if (workload.workingHours) {
          result.push({
            MPNumber: project.mpNumber,
            Project: project.mpTitle,
            Day: format(new Date(workload.accountingMonth), 'dd.MM.yyyy'),
            Hours: workload.workingHours,
            Comment: workload.comment
          });
        }
      });
    });
    return result.sort((a, b) => {
      if (a.Day > b.Day) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  private mapWorkloads(workloads: Observable<Workload[]>) {
    return workloads.pipe(
      map(result => {
        if (result) {
          return result.map(workload => {
            workload.accountingMonth = new Date(workload.accountingMonth);
            return workload;
          });
        }
        return result;
      })
    );
  }
}
