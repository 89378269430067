import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { FeatureHeaderModule } from "../feature-header/feature-header.module";

@NgModule({
  imports: [CommonModule, FeatureHeaderModule],
  declarations: [LayoutComponent],
  exports: [LayoutComponent]
})
export class LayoutModule {}
