import { Inject, Injectable } from "@angular/core";
import { BusinessArea, BusinessAreaDto } from '@ceres/domain';
import { ImpersonatedHttpClient } from "@ceres/shared/services";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "@ceres/frontend-helper";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class BusinessAreasService {
  constructor(
    private readonly http: ImpersonatedHttpClient,
    @Inject(ENVIRONMENT) private readonly environment: {edgeService: string}
  ) {}

  public getAll(): Promise<BusinessArea[]> {
    return this.http
      .get<BusinessArea[]>(`${this.environment.edgeService}/business/areas`)
      .toPromise();
  }

  public getBusinessAreas(depth?: number) {
    let params: HttpParams = new HttpParams();

    if (depth) {
      params = params.set('depth', depth);
    }

    return this.http.get<BusinessAreaDto[]>(`${this.environment.edgeService}/business/areas`, {
      params,
    });
  }

  public transformBusinessAreasByType(areas: BusinessAreaDto[], type: string): BusinessAreaDto[] {
    let result: BusinessAreaDto[] = [];
    for (let i = 0; i < areas.length; i++) {
      if (areas[i].type === type) {
        result.push(areas[i]);
      }

      if(areas[i].children.length > 0) {
        const childAreas: BusinessAreaDto[] = areas[i].children;
        result = result.concat(this.transformBusinessAreasByType(childAreas, type));
      }
    }
    return result;
  }

  public createObservable({
                            name,
                            longName,
                            originDC,
                            pspElement,
                            type,
                            parent
                          }: { name: BusinessAreaDto['name'], longName: BusinessAreaDto['longName'], originDC: BusinessAreaDto['originDC'], pspElement: BusinessAreaDto['pspElement'],  type: BusinessAreaDto['type'], parent?: BusinessAreaDto }): Observable<BusinessAreaDto> {
    return this.http.post<BusinessAreaDto>(`${this.environment.edgeService}/business/areas/create`, { name, longName, originDC, pspElement, type, parentId: parent?.id });
  }

  public renameBusinessArea(businessArea: BusinessAreaDto,  name: BusinessAreaDto['name'], longName: BusinessAreaDto['longName'], originDC: BusinessAreaDto['originDC'], pspElement: BusinessAreaDto['pspElement'],  type: BusinessAreaDto['type']): Observable<BusinessAreaDto> {
    return this.http.put<BusinessAreaDto>(`${this.environment.edgeService}/business/areas/${businessArea.id}/rename`,
      {
        name,
        longName,
        originDC,
        pspElement,
        type
      });
  }

  public addParentToBusinessArea(value: { childId: number, parentId: number }): Observable<BusinessAreaDto> {
    return this.http.post<BusinessAreaDto>(
      `${this.environment.edgeService}/business/areas/parent`,
      value
    );
  }

  public update(area: BusinessArea) {
    return this.http
      .patch<BusinessArea>(
        `${this.environment.edgeService}/business/areas/${area.id}`,
        area
      )
      .toPromise();
  }

  public deleteObservable(area: BusinessAreaDto): Observable<void>{
    return this.http
      .delete<void>(`${this.environment.edgeService}/business/areas/${area.id}`);
  }

  public delete(area: BusinessArea) {
    return this.http
      .delete(`${this.environment.edgeService}/business/areas/${area.id}`)
      .toPromise();
  }

  public create(area: Partial<BusinessArea>) {
    return this.http
      .post<BusinessArea>(`${this.environment.edgeService}/business/areas`, area)
      .toPromise();
  }
}
