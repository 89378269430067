import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericConstructor } from '../types';

export function CommonPagination<T extends GenericConstructor>(
  {
    size = 50,
    index = 0,
    sort = null as { key: string; direction: 'asc' | 'desc' }
  } = {},
  Base: T = class {} as any
) {
  class CommonPaginationService extends Base {
    public pageSize: number;
    public pageIndex: number;
    public pageSort: { key: string; direction: 'asc' | 'desc' };
    protected _total: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public total$: Observable<number> = this._total.asObservable();

    constructor(...args: any[]) {
      super(...args);
      this.pageSize = size || 50;
      this.pageIndex = index || 0;
      this.pageSort = sort;
    }

    public getAll(): void {
      return;
    }

    public setPage({ pageSize = 50, pageIndex = 0 } = {}) {
      this.pageSize = pageSize || 50;
      this.pageIndex = pageIndex || 0;
      this.getAll();
    }

    public setSort(
      pageSort: { key: string; direction: 'asc' | 'desc' } | null
    ) {
      this.pageSort = pageSort;
      this.getAll();
    }

    protected getPaginationParams() {
      let params = new HttpParams();
      params = params.append('page', `${this.pageIndex}`);
      params = params.append('size', `${this.pageSize}`);
      if (this.pageSort && this.pageSort.key && this.pageSort.direction) {
        params = params.append(
          'sort',
          `${this.pageSort.key},${this.pageSort.direction.toUpperCase()}`
        );
      }
      return params;
    }
  }

  return CommonPaginationService;
}
