import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ceres-header-placeholder',
  templateUrl: './header-component-placeholder.component.html',
  styleUrls: ['./header-component-placeholder.component.scss']
})
export class HeaderComponentPlaceholder implements OnInit {
  constructor() {}

  ngOnInit() {}
}
