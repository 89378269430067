import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchFieldComponent } from "./search-field.component";
import { TranslocoModule } from "@ngneat/transloco";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslocoModule,
    ],
  declarations: [SearchFieldComponent],
  exports: [SearchFieldComponent]
})
export class SearchFieldModule {}
