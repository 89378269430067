import { BusinessArea as CeresBusinessArea, BusinessAreaDto, BusinessAreaType, PspElement, Employee } from '@ceres/domain';


export class BusinessArea implements CeresBusinessArea {
  id: number;
  title: string;
  name: string;
  longName: string;
  manager: any;
  //managerEmail: string;
  signatureTech: any;
  //signatureTechEmail: string;
  signatureCommercial: any;
  //signatureCommercialEmail: string;
  pspElement?: PspElement;
  type?: BusinessAreaType;
  parent: BusinessAreaDto | null;
  children: BusinessAreaDto[];

  constructor(object?: Partial<BusinessArea>) {
    Object.assign(this, object);
  }
}

// Used for tree dropdowns
export class BusinessAreaNode {
  children: BusinessAreaNode[];
  name: string;
  longName: string;
  id: number;
  title: string;
  manager: Employee;
  signatureTech: Employee;
  signatureCommercial: Employee;
  parent: BusinessAreaDto | null;
  type?: BusinessAreaType;
}

export class BusinessAreaFlatNode {
  businessArea: BusinessAreaDto;
  name: string;
  level: number;
  expandable: boolean;
}
