import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BusinessArea } from '@ceres/domain';
import { ImpersonatedHttpClient } from "@ceres/shared/services";

@Injectable({
  providedIn: 'root'
})
export class ContractBusinessAreasService {
  constructor(private http: ImpersonatedHttpClient) {}

  public getAll(): Promise<BusinessArea[]> {
    return this.http
      .get<BusinessArea[]>(
        `${environment.edgeService}/contracts/business/areas`
      )
      .toPromise();
  }

  public update(area: BusinessArea) {
    return this.http
      .patch<BusinessArea>(
        `${environment.edgeService}/contracts/business/areas/${area.id}`,
        area
      )
      .toPromise();
  }
}
