import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslocoModule } from "@ngneat/transloco";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MonthSelectorComponent } from "./month-selector.component";
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, TranslocoModule, MatSelectModule, MatIconModule],
  declarations: [MonthSelectorComponent],
  exports: [MonthSelectorComponent]
})
export class MonthSelectorModule {}
