import { Injectable } from '@angular/core';
import { CommonLoading } from '@ceres/frontend-helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectCountPercentageChartDataPoint } from '@ceres/domain';
import { Filter } from '@ceres/filter';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DateService } from "@ceres/shared/services";
import { ImpersonatedHttpClient } from "@ceres/shared/services";

type FiscalYear = { date: Date; dateEnd: Date; label: string };

@Injectable()
export class ProjectChartService extends CommonLoading(Object) {
  private _orderVsBilledEntry: BehaviorSubject<
    ProjectCountPercentageChartDataPoint[]
  > = new BehaviorSubject<ProjectCountPercentageChartDataPoint[]>([]);
  public orderVsBilledEntry$: Observable<
    ProjectCountPercentageChartDataPoint[]
  > = this._orderVsBilledEntry.asObservable();

  private _countsByCustomerTypeEntry: BehaviorSubject<
    ProjectCountPercentageChartDataPoint[]
    > = new BehaviorSubject<ProjectCountPercentageChartDataPoint[]>([]);
  public countsByCustomerTypeEntry$: Observable<
    ProjectCountPercentageChartDataPoint[]
    > = this._countsByCustomerTypeEntry.asObservable();

  public allFiscalYears: FiscalYear[];
  private _appliedFilters: Filter[] = [];

  constructor(
    private http: ImpersonatedHttpClient,
    private dateService: DateService
  ) {
    super();
    this.allFiscalYears = this.dateService.getBusinessYears();
  }

  public set appliedFilters(appliedFilters: Filter[]) {
    this._appliedFilters = appliedFilters;
  }

  public get appliedFilters(): Filter[] {
    return this._appliedFilters;
  }

  private appendFilters() {
    let params = new HttpParams();
    if (this.appliedFilters.length > 0) {
      return params.append('filters', JSON.stringify(this.appliedFilters));
    }
    return params;
  }

  public getOrderEntryVsBilling() {
    this.loading = true;
    let params = this.appendFilters();

    this.http
      .get<ProjectCountPercentageChartDataPoint[]>(
        `${environment.edgeService}/projects/chart-data/order-entry-vs-billing`,
        {
          params
        }
      )
      .subscribe((data) => {
        this.loading = false;
        this._orderVsBilledEntry.next(data);
      });
  }

  public getCountsByCustomerType() {
    this.loading = true;
    let params = this.appendFilters();

    this.http
      .get<ProjectCountPercentageChartDataPoint[]>(
        `${environment.edgeService}/projects/chart-data/counts/customer-types`,
        {
          params
        }
      )
      .subscribe((data) => {
        this.loading = false;
        this._countsByCustomerTypeEntry.next(data);
      });
  }

  public getCountsByCustomerDepartment(customerType: string) {
    let params = this.appendFilters();

    return this.http
      .get<ProjectCountPercentageChartDataPoint[]>(
        `${environment.edgeService}/projects/chart-data/counts/customer-types/${customerType}/departments`,
        {
          params
        }
      ).toPromise();
  }
}
