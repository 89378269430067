import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Activity, Skill, SkillSet } from '@ceres/domain';
import { ImpersonatedHttpClient } from "@ceres/shared/services";
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

export type Result<T> = {
  result?: T;
  error?: unknown;
};

@Injectable({
  providedIn: 'root'
})
export class SkillService implements OnDestroy {
  private readonly skillSets$$: Subject<SkillSet[]> = new ReplaySubject(1);
  public readonly skillSets$: Observable<SkillSet[]> =
    this.skillSets$$.asObservable();

  private readonly skillSetsLoading$$: Subject<boolean> = new BehaviorSubject(
    false
  );
  public readonly skillSetsLoading$ = this.skillSetsLoading$$.asObservable();

  private readonly activitySkillSetsLoading$$: Subject<boolean> =
    new BehaviorSubject(false);
  public readonly activitySkillSetsLoading$ =
    this.skillSetsLoading$$.asObservable();

  public readonly activitySkills$$: Subject<Activity[]> = new ReplaySubject(1);
  public readonly activitySkills$: Observable<Activity[]> =
    this.activitySkills$$.asObservable();

  private readonly subscription = new Subscription();

  constructor(private readonly httpClient: ImpersonatedHttpClient) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public getAll() {
    this.skillSetsLoading$$.next(true);
    this.subscription.add(
      this.httpClient
        .get<SkillSet[]>(`${environment.edgeService}/skills`)
        .pipe(catchError(() => of([])))
        .subscribe((skillSets) => {
          this.skillSets$$.next(skillSets);
          this.skillSetsLoading$$.next(false);
        })
    );
  }

  public getAllSkillNames(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${environment.edgeService}/skills/names`
    );
  }

  public getSelectableSkillsForCategory(category: {
    categoryId: number;
    skills: { skillId: number }[];
  }): Observable<Skill[]> {
    return this.skillSets$.pipe(
      map((skillSets) =>
        skillSets.find(
          (skillSet) =>
            !!skillSet.skillCategories.find(
              (skillSetCategory) => skillSetCategory.id == category.categoryId
            )
        )
      ),
      map((skillSet) =>
        skillSet.skillCategories.find(
          (skillSetCategory) => skillSetCategory.id == category.categoryId
        )
      ),
      map((skillSetCategory) => {
        const usedSkillIds = category.skills.map((cSkills) => cSkills.skillId);

        return skillSetCategory.skills.filter((skill) => {
          return !usedSkillIds.includes(skill.id);
        });
      })
    );
  }

  public getSelectableSkillSets(
    usedSkillSetIds: number[],
    hiddenActivities$: Observable<number[]>
  ): Observable<SkillSet[]> {
    return combineLatest(this.skillSets$, hiddenActivities$).pipe(
      map(([skillSets, hiddenActivities]) => {
        return skillSets.filter((skillSet) => {
          return SkillService.showSkillSet(skillSet, hiddenActivities);
        });
      }),
      map((skillSets) => {
        return skillSets.filter((skillSet) => {
          return !usedSkillSetIds.includes(skillSet.id);
        });
      })
    );
  }

  private static showSkillSet(
    skillSet: SkillSet,
    hiddenActivities: number[]
  ): boolean {
    const activityIds = skillSet.activities.map((activity) => activity.id);
    for (const hiddenActivity of hiddenActivities) {
      if (activityIds.includes(hiddenActivity)) {
        return false;
      }
    }
    return true;
  }

  private handleAction<T>(
    actionFn: () => Observable<T>,
    resultSubject$$: Subject<T>,
    loadingSubject$$: Subject<boolean>
  ): Observable<Result<T>> {
    return of(null).pipe(
      tap(() => loadingSubject$$.next(true)),
      switchMap(actionFn),
      tap((skillSets) => resultSubject$$.next(skillSets)),
      map((skillSets) => ({ result: skillSets })),
      catchError((error) => of({ error })),
      tap(() => loadingSubject$$.next(false))
    );
  }

  public addSkill(
    skillName: string,
    skillCategoryId: number
  ): Observable<Result<SkillSet[]>> {
    return this.handleAction(
      () =>
        this.httpClient.post<SkillSet[]>(
          `${environment.edgeService}/skills/skill`,
          {
            skillName,
            skillCategoryId
          }
        ),
      this.skillSets$$,
      this.skillSetsLoading$$
    );
  }

  public addSkillCategory(
    skillCategoryName: string,
    skillSetId: number
  ): Observable<Result<SkillSet[]>> {
    return this.handleAction(
      () =>
        this.httpClient.post<SkillSet[]>(
          `${environment.edgeService}/skills/skillCategory`,
          {
            skillCategoryName,
            skillSetId
          }
        ),
      this.skillSets$$,
      this.skillSetsLoading$$
    );
  }

  public addSkillSet(skillSetName: string): Observable<Result<SkillSet[]>> {
    return this.handleAction(
      () =>
        this.httpClient.post<SkillSet[]>(
          `${environment.edgeService}/skills/skillSet`,
          {
            skillSetName
          }
        ),
      this.skillSets$$,
      this.skillSetsLoading$$
    );
  }

  public removeSkillSet(skillSetId: number): Observable<Result<SkillSet[]>> {
    return this.handleAction(
      () =>
        this.httpClient.delete<SkillSet[]>(
          `${environment.edgeService}/skills/skillSet/${skillSetId}`
        ),
      this.skillSets$$,
      this.skillSetsLoading$$
    );
  }

  public removeSkillCategory(
    skillCategoryId: number
  ): Observable<Result<SkillSet[]>> {
    return this.handleAction(
      () =>
        this.httpClient.delete<SkillSet[]>(
          `${environment.edgeService}/skills/skillCategory/${skillCategoryId}`
        ),
      this.skillSets$$,
      this.skillSetsLoading$$
    );
  }

  public removeSkill(skillId: number): Observable<Result<SkillSet[]>> {
    return this.handleAction(
      () =>
        this.httpClient.delete<SkillSet[]>(
          `${environment.edgeService}/skills/skill/${skillId}`
        ),
      this.skillSets$$,
      this.skillSetsLoading$$
    );
  }

  public getActivities() {
    this.activitySkillSetsLoading$$.next(true);
    this.subscription.add(
      this.httpClient
        .get<Activity[]>(`${environment.edgeService}/skills/activities`)
        .pipe(catchError(() => of([])))
        .subscribe((activities) => {
          this.activitySkills$$.next(activities);
          this.activitySkillSetsLoading$$.next(false);
        })
    );
  }

  public addSkillSetToActivity(
    activityId: number,
    skillSetId: number
  ): Observable<Result<Activity[]>> {
    return this.handleAction(
      () =>
        this.httpClient.post<Activity[]>(
          `${environment.edgeService}/skills/activity`,
          {
            activityId,
            skillSetId
          }
        ),
      this.activitySkills$$,
      this.activitySkillSetsLoading$$
    );
  }

  public removeSkillSetFromActivity(
    activityId: number,
    skillSetId: number
  ): Observable<Result<Activity[]>> {
    return this.handleAction(
      () =>
        this.httpClient.delete<Activity[]>(
          `${environment.edgeService}/skills/activity/${activityId}/${skillSetId}`
        ),
      this.activitySkills$$,
      this.activitySkillSetsLoading$$
    );
  }
}
