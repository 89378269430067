import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NAVIGATOR } from '@ceres/frontend-helper';

@Injectable({
  providedIn: 'root'
})
export class CopyToClipboardService {
  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    @Inject(NAVIGATOR) private readonly nav: Navigator,
  ) {}

  public async copyToClipboard(text: string) {
    if (this.nav.clipboard) {
      // New and nice way
      await this.nav.clipboard.writeText(text);
    } else {
      // Fallback
      const selBox = this.doc.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = text;
      this.doc.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      this.doc.execCommand('copy');
      this.doc.body.removeChild(selBox);
      this.doc.execCommand('copy');
    }
  }
}
