import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SignedURLDownloadResponse,
  SignedURLTranslationsRequest
} from '@ceres/domain';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { Translation } from "@ngneat/transloco";

@Injectable()
export class CustomTranslateLoader implements Translation {

  constructor(private http: HttpClient) { }

  getTranslation(lang: string) {
    const req: SignedURLTranslationsRequest = { file: lang + '.json', stage: null };
    return this.http
      .post<SignedURLDownloadResponse>(`${environment.edgeService}/signed-url-translations`, req)
      .pipe(switchMap(res => this.http.get(res.url)))
  }
}
