import { Activity as CeresActivity } from '@ceres/domain';

export class Activity implements CeresActivity {
  id = -1;
  artikelNummer = null;
  title = '';
  shortName?: string;
  colorCode?: string;
  translationKey = '';
  rankOrder = 0;
  timeUnit = 'Std.';
  EK = 0;
  VK = 0;
  inActive = false;
  default?: boolean;
  currencyCode = 'EUR';
  additionalActivityDescription: string
  exchangeRate = {
    referenceDate: '1999-01-01',
    value: 1
  };
  validFrom?: string;

  constructor(object?: Partial<Activity>) {
    if (object) {
      Object.assign(this, object);
    }
  }

  public static checkActivities(current: Activity[], original: Activity[]) {
    if (current.length !== original.length) {
      return false;
    }

    for (let i = 0; i < current.length; i++) {
      if (current[i].id !== original[i].id) {
        return false;
      }
    }
    return true;
  }
}
