import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { Filter } from '../../lib/filters';
import { MatLegacyMenuTrigger as MatMenuTrigger, MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import * as F from '../../lib/filters';
import { FilterService } from "../../lib";

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'mat-header-cell[filter]',
  templateUrl: './header-cell-filter.component.html',
  styleUrls: ['./header-cell-filter.component.scss']
})
export class HeaderCellFilterComponent {
  @ViewChild(MatMenuTrigger) triggerMenu: MatMenuTrigger;

  @Input() align: string;
  @Input() filter: Filter | Filter[];
  @Input() filterService: FilterService;

  @Input() name: string;
  @Input() sort: boolean;
  @Input() isTranslationKey = false;
  @Input() translationPrefix: string;

  // TODO remove after refactored everything
  @Input() column: string;
  @Input() dataSource: MatTableDataSource<any>;
  @Input() filterBuilder: any;
  @Input() server: boolean;

  @Output() toggle = new EventEmitter();

  @ViewChild('filterMenu') menuRef: MatMenu;

  visible = false;

  filterTypes = F;

  isDisabled = false;
  isAnchored = false;

  hasStyles = false;

  @Input() transform = (key: string) => key;

  get xPosition() {
    return this.align === 'right' ? 'before' : 'after';
  }

  get isTabbed() {
    return Array.isArray(this.filter);
  }

  get isApplied() {
    if (Array.isArray(this.filter)) {
      return this.filter.some((f) => f && f.isApplied);
    } else {
      return this.filter && this.filter.isApplied;
    }
  }

  constructor(element: ElementRef) {
    this.hasStyles = element.nativeElement.hasAttribute('pwo');
  }

  is(filter: Filter, filterType: any) {
    return filter instanceof filterType;
  }

  open() {
    this.toggle.next(true);
  }

  close() {
    if (this.isAnchored) {
      this.toggle.next(false);
    }
    if (this.triggerMenu) {
      this.triggerMenu.closeMenu();
    }
  }
}
