<ng-container *ngIf="!multiple; else multipleAlternative">
  <mat-form-field
    class="mat-custom w-100"
    [ngClass]="{ 'input-invalid': condition === true }"
    appearance="fill"
  >
    <mat-label>{{ placeholder }}</mat-label>
    <input matInput [required]="required" [value]="getValue()" readonly />

    <mat-icon
      *ngIf="isEmployeeSet(); else other"
      (click)="openEmployeeSelection()"
      class="mat-custom icon-pointer"
      matSuffix
      >{{ isEdit ? 'edit' : 'person_add' }}</mat-icon
    >
    <ng-template #other>
      <mat-icon
        *ngIf="!readonly"
        (click)="removeSelected(null)"
        class="mat-custom icon-pointer"
        matSuffix
        >delete</mat-icon
      >
    </ng-template>
  </mat-form-field>
</ng-container>
<ng-template #multipleAlternative>
  <mat-form-field class="pwo-no-underline">
    <mat-chip-list #chipList>
      <mat-chip
        (removed)="removeSelected(employee)"
        *ngFor="let employee of value"
        [matTooltip]="activity ? getAssignedActivities(employee) : ''"
        matTooltipClass="pwo-tooltip"
      >
        {{
          employee?.name
            ? employee?.name
            : employee?.title
            ? employee?.title
            : employee?.scdName
        }}
        <ng-container *ngIf="dpt && employee.businessArea?.title">
          &nbsp;-&nbsp;{{ employee?.businessArea?.title }}</ng-container
        >
        <mat-icon *ngIf="!readonly" matChipRemove>cancel</mat-icon>
      </mat-chip>
      <ng-container *ngIf="buttonText; else justIcon">
        <ceres-button
          *ngIf="!readonly"
          [disabled]="condition"
          (click)="openEmployeeSelection(true)"
          icon="plus"
          >{{ buttonText }}</ceres-button
        >
      </ng-container>
      <ng-template #justIcon>
        <mat-chip
          (click)="openEmployeeSelection(true)"
          *ngIf="!readonly"
          [ngClass]="{ invalid: condition === true }"
        >
          <i class="a-icon a-icon--plus"></i>
        </mat-chip>
      </ng-template>

      <input
        [disabled]="readonly"
        [matChipInputFor]="chipList"
        placeholder="{{ placeholder }}"
        readonly
        hidden
      />
    </mat-chip-list>
  </mat-form-field>
</ng-template>
