<ng-container *ngIf="!isStyleModern; else modernSearch">
  <mat-form-field class="mat-custom" [ngClass]="fixMargin ? 'mat-fix-padding' : ''" appearance="fill">
    <mat-label>{{ 'shared.miscellaneous.search-dots' | transloco }}</mat-label>
    <input [(ngModel)]="filter"
           (ngModelChange)="changed($event)"
           matInput>
    <button
      (click)="resetFilter()"
      *ngIf="dataSource?.filter"
      aria-label="Clear"
      mat-button
      mat-icon-button
      matSuffix
    >
      <i class="material-icons">close</i>
    </button>
  </mat-form-field>
</ng-container>
<ng-template #modernSearch>
  <input
    type="search"
    [(ngModel)]="filter"
    (ngModelChange)="changed($event)"
    placeholder="{{ 'shared.miscellaneous.search-dots' | transloco }}"
  />
  <mat-icon class="icon">search</mat-icon>
</ng-template>
