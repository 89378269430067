import { Injectable } from "@angular/core";
import {
  MultiValueFilter,
  Filter,
  DateFilter,
  ValueFilter
} from "@ceres/filter";
import { AppUserService } from "./app-user.service";
import { EmployeeSettingsService } from '@ceres/shared/services';
import { PersistentFilter } from "../../../../../apps/ceres-frontend/src/app/myceres/components/settingsv2/custom-filter/custom-filter";

@Injectable({
  providedIn: "root"
})
export class FilterFactoryService {
  public persistentFilter: PersistentFilter | undefined
  public currentUser: any

  constructor(private appUserService: AppUserService,private employeeSettingsService: EmployeeSettingsService) {
    this.appUserService.loggedInUser$.subscribe(user => this.currentUser = user)
  }

  create(
    filterNames: string[],
    type?: string,
    skipBusinessAreaFilter?: boolean
  ): { [key: string]: Filter } {
    const filters: { [key: string]: Filter } = {};
    filterNames.forEach(key => {
      filters[key] = this.getFilter(key, type, skipBusinessAreaFilter);
    });
    return filters;
  }

  getFilter(column: string, type?: string, skipBusinessAreaFilter?: boolean): Filter {
    switch (column) {
      case "projectLead": {
        const filter = new MultiValueFilter(column);
        const persistentFVs = this.getPersistentFilter("projectLead");
        if (persistentFVs) {
          filter.selected = persistentFVs;
          filter.isApplied = true;
        }
        return filter;
      }

      case "projektGruppierung" || "ProjektGruppierung": {
        const f = new MultiValueFilter(column);
        f.scope = "remote";
        f.selected = [];
        if (!this.appUserService.isMerchant() && this.appUserService.businessArea && this.appUserService.businessArea.name && !skipBusinessAreaFilter) {
          f.selected = [this.appUserService.businessArea.name];
        }
        const persistentFVs = this.getPersistentFilter("projektGruppierung");
        if (persistentFVs && !skipBusinessAreaFilter) f.selected.push(...persistentFVs);
        return f;
      }

      case "projectStatus": {
        const psf = new MultiValueFilter(column);
        psf.scope = "remote";
        psf.selected = ["projects.project-property-options.status.in-progress", "projects.project-property-options.status.other"];
        return psf;
      }

      case "portfolio.title": {
        const f = new MultiValueFilter(column);
        const persistentFVs = this.getPersistentFilter("portfolio");
        if (persistentFVs) f.selected = persistentFVs;
        return f;
      }

      case "date":
      case "recurringDate":
        return new DateFilter(column);

      case "status": {
        // business partner
        const sf = new MultiValueFilter(column);
        sf.scope = "remote";
        sf.selected = type === "employee" ? ["active"] : ["Kunde"];
        return sf;
      }

      case "bopStatus.translationKey": {
        const sf = new MultiValueFilter(column);
        sf.scope = "remote";
        sf.selected = ['bop.bop-property-options.status.in-progress'];
        return sf;
      }

      case "isFavorite": {
        const f = new MultiValueFilter(column);
        f.valueAccessor = el => el && !!el[f.key];
        f.transform = isFavorite => (isFavorite ? "★" : "☆");
        return f;
      }

      default:
        return new MultiValueFilter(column);
    }
  }

  private getPersistentFilter(
    key: string
  ) {
    if (!this.persistentFilter) {
      return undefined;
    }
    switch(key){
      case 'projectLead': {
        return this.persistentFilter.onlyUserAsProjectLead ? [this.currentUser.details.name] : null
      }
      case 'projektGruppierung' : {
        return this.persistentFilter.projectGrouping
      }
      case 'portfolio': {
        return this.persistentFilter.portfolio
      }
    }
  }

  public setPersistentFilter(persistentFilter: PersistentFilter) {
    this.persistentFilter = persistentFilter
  }
}
