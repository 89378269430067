import { TranslocoService } from '@ngneat/transloco';
import { BusinessPartnerService } from '@ceres/shared/services';
import { BusinessPartnerService as NewBusinessPartnerService } from '../services/business-partner.service';
import { Employee, MessageService } from '@ceres/shared/services';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms';
import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  OnDestroy,
  AfterViewChecked
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { AppUserService } from '@ceres/shared/services';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TranslationOption } from '@ceres/domain';
import { BusinessPartner } from '../model/business-partner';
import { environment } from 'apps/ceres-frontend/src/environments/environment';
import { BusinessPartnerConfigService } from "@app/shared/services/business-partner-config.service";

export const INTERN = 'intern';

@Component({
  selector: 'ceres-business-partner-new',
  templateUrl: './business-partner-new.component.html',
  styleUrls: ['./business-partner-new.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BusinessPartnerNewComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  public burgerOpened = false;
  public formGroup: FormGroup;
  private checked = false;
  closePopup = false;
  showEmail = false;
  busy = false;
  businesspartner: BusinessPartner;
  defaultOptionBudgetResponsibility: TranslationOption[];

  baseControls = [
    'gid',
    'firstName',
    'surName',
    'department',
    'countryCode',
    'eMail'
  ];

  nonSCDControls = ['companyNames', 'gpType', 'personalRelation'];

  public subjectAreaFilterCtrl: FormControl<string> = new FormControl<string>(
    ''
  );
  public subjectAreasOptions: ReplaySubject<TranslationOption[]> =
    new ReplaySubject<TranslationOption[]>(1);
  public subjectAreas: TranslationOption[];
  public functions: TranslationOption[];
  public budgetOptions: TranslationOption[];
  public languageOptions: TranslationOption[];
  public statusOptions: TranslationOption[];
  public gpTypeOptions: TranslationOption[];

  public permissions: Set<string>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<BusinessPartnerNewComponent>,
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public businessPartnerService: BusinessPartnerService,
    public newBusinessPartnerService: NewBusinessPartnerService,
    public router: Router,
    public appUserService: AppUserService,
    private translate: TranslocoService,
    public businessPartnerConfig: BusinessPartnerConfigService
  ) {}

  public get businessPartner(): BusinessPartner {
    return this.formGroup
      ? this.formGroup.getRawValue()
      : new BusinessPartner();
  }

  public get companyNamesControl() {
    return (this.formGroup.get('companyNames') as FormArray).controls;
  }

  ngOnInit() {
    document.querySelector('body').classList.add('new-wrapper');
    if (this.data && this.data.closePopup) {
      this.closePopup = this.data.closePopup;
    }
    this.getMetadata();
    this.buildForm();
    this.subscribeToPermissionsChanges();

    this.subjectAreaFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterSubjectAreas();
      });

    const gpTypeControl = this.formGroup.get('gpType');
    const userControl = this.formGroup.get('user');
    const eMailControl = this.formGroup.get('eMail');

    if (gpTypeControl && userControl && eMailControl) {
      gpTypeControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((gpTypeValue) => {
          if (gpTypeValue.name === 'Extern') {
            // Wenn gpType "Extern" ist:
            // - Das `user`-Feld ist nicht erforderlich
            userControl.clearValidators();
            // - Das `eMail`-Feld ist erforderlich
            eMailControl.setValidators([Validators.required, Validators.email]);
          } else {
            // Wenn gpType nicht "Extern" ist:
            // - Das `user`-Feld ist erforderlich
            userControl.setValidators([
              Validators.required,
              this.emailConditionallyRequiredValidator
            ]);
            // - Das `eMail`-Feld ist nicht zwingend erforderlich
            eMailControl.clearValidators();
          }
          // Aktualisiere die Validierung der Felder
          userControl.updateValueAndValidity();
          eMailControl.updateValueAndValidity();
        });
    }
  }

  get tenantName(): string {
    return environment.tenant;
  }

  isIntern(item: TranslationOption): boolean {
    return item?.name?.toLowerCase()?.includes(INTERN);
  }

  getMetadata() {
    this.newBusinessPartnerService.getMetadata().then((data) => {
      this.gpTypeOptions = data.gpTypeOptions?.sort((a, b) => a.id - b.id);
      this.functions = data.functions;
      this.subjectAreas = data.subjectAreas;
      this.subjectAreasOptions.next(data.subjectAreas.slice());
      this.languageOptions = data.languageOptions;
      this.statusOptions = data.statusOptions;
      this.budgetOptions = data.budgetOptions;

      this.defaultOptionBudgetResponsibility = this.budgetOptions.filter(
        (option) => {
          return (
            option.translationKey === 'businesspartner.options.budget.unknown'
          );
        }
      );
      this.setDefaultValues();
    });
  }

  setDefaultValues() {
    this.formGroup.controls['gpType'].setValue(
      this.gpTypeOptions.find((type) => type.name === 'Siemens')
    );
    this.setFirstCompanyName('SIEMENS');
    this.disableBaseControls();
    this.disableSCDFields();
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('new-wrapper');
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.formGroup
      .get('gpType')
      .valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((_) => {
        this.formGroup.get('user').updateValueAndValidity();
      });
  }

  canDeactivate() {
    if (!this.formGroup.dirty) return true;
    return this.messageService.openConfirmDialog(
      'shared.dialogs.unsaved-changes'
    );
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      user: [null, [this.emailConditionallyRequiredValidator]],
      gpType: [this.businessPartner.gpType, Validators.required],
      gid: [this.businessPartner.gid],
      department: [this.businessPartner.department],
      salutation: [this.businessPartner.salutation],
      firstName: [this.businessPartner.firstName],
      surName: [this.businessPartner.surName, Validators.required],
      subjectArea: [this.businessPartner.subjectArea, Validators.required],
      function: [this.businessPartner.function, Validators.required],
      customerContact: [[]],
      budgetResponsibility: [
        this.businessPartner.budgetResponsibility
          ? this.businessPartner.budgetResponsibility
          : this.defaultOptionBudgetResponsibility
          ? this.defaultOptionBudgetResponsibility[0].translationKey
          : '',
        Validators.required
      ],
      mainCustomerContact: [
        this.businessPartner.mainCustomerContact,
        Validators.required
      ],
      personalRelation: [this.businessPartner.personalRelation],
      phone: [this.businessPartner.phone],
      fax: [this.businessPartner.fax],
      mobile: [this.businessPartner.mobile],
      eMail: [
        this.businessPartner.eMail,
        [Validators.required, Validators.email]
      ],
      location: [this.businessPartner.location],
      are: [this.businessPartner.are],
      street: [this.businessPartner.street],
      zip: [this.businessPartner.zip],
      countryCode: [this.businessPartner.countryCode],
      language: [this.businessPartner.language, Validators.required],
      status: [this.businessPartner.status, Validators.required],
      newsletterCandidate: [
        this.businessPartner.newsletterCandidate
          ? this.businessPartner.newsletterCandidate
          : false
      ],
      advertisingBarrier: [
        this.businessPartner.advertisingBarrier
          ? this.businessPartner.advertisingBarrier
          : false
      ],
      newsletterBarrier: [
        this.businessPartner.newsletterBarrier
          ? this.businessPartner.newsletterBarrier
          : false
      ],
      christmasCard: [
        this.businessPartner.christmasCard
          ? this.businessPartner.christmasCard
          : false
      ],
      deleteBookmarked: [
        this.businessPartner.deleteBookmarked
          ? this.businessPartner.deleteBookmarked
          : false
      ],
      inFocus: [
        this.businessPartner.inFocus ? this.businessPartner.inFocus : false
      ],
      companyNames: this.formBuilder.array([
        this.formBuilder.group({ name: 'SIEMENS' })
      ]),
      title: [this.businessPartner.title],
      poBox: [this.businessPartner.poBox],
      country: [this.businessPartner.country],
      city: [this.businessPartner.city],
      costCenter: this.businessPartner.costCenter,
      orgId: this.businessPartner.orgId
    });
    this.changeMainCustomerContact(
      this.appUserService.loggedInUser$.getValue().details
    );
    const missingData = this.businessPartnerConfig.getDisabledValues();
    this.formGroup.patchValue(missingData);
  }

  // emailConditionallyRequiredValidator(formControl: AbstractControl) {
  //   if (!formControl.parent) {
  //     return null;
  //   }
  //   if (!formControl.parent.get('gpType').value) {
  //     return Validators.required(formControl);
  //   }
  //   if (formControl.parent.get('gpType').value === 'Extern') {
  //     return formControl.clearValidators();
  //   }
  //   return null;
  // }

  emailConditionallyRequiredValidator(formControl: AbstractControl) {
    const formGroup = formControl.parent;
    if (!formGroup) return null;
  
    const gpTypeControl = formGroup.get('gpType');
    
    // Wenn gpType "Extern" ist, muss `eMail` validiert werden, nicht `user`
    if (gpTypeControl && gpTypeControl.value && gpTypeControl.value.name === 'Extern') {
      return null; // Keine Validierung für `user`
    }
  
    // Wenn gpType nicht "Extern" ist, muss `user` validiert werden
    const userControl = formGroup.get('user');
    return userControl && userControl.value ? null : { required: true };
  }
  

  checkCompany(index: number, company: any) {
    return company && this.businessPartner.companyNames.length - 1 === index;
  }

  deleteCompany(index: number) {
    const control = this.formGroup.controls['companyNames'] as FormArray;
    control.removeAt(index);
  }

  addNewRow() {
    const control = this.formGroup.controls['companyNames'] as FormArray;
    control.push(this.formBuilder.group({ name: '' }));
  }

  chooseEmployee(employee) {
    if (employee && employee.scdEMail) {
      this.businessPartnerService
        .checkMail(employee.scdEMail)
        .then((result) => {
          if (!result) {
            this.formGroup.patchValue({
              user: employee ? { name: employee.scdName } : null,
              gid: employee ? employee.scdGid : '',
              firstName: employee ? employee.firstName : '',
              surName: employee ? employee.surName : '',
              department: employee ? employee.department : '',
              phone: employee ? employee.phone : '',
              fax: employee ? employee.fax : '',
              mobile: employee ? employee.mobile : '',
              location: employee ? employee.location : '',
              countryCode: employee ? employee.country : '',
              eMail: employee ? employee.scdEMail : '',
              street: employee ? employee.street : '',
              city: employee ? employee.locality : '',
              zip: employee ? employee.postalCode : '',
              are: employee ? employee.costLocationUnit : '',
              costCenter: employee ? employee.costLocation : '',
              orgId: employee ? employee.orgId : '',
              salutation: employee
                ? this.mapSalutation(employee.personalTitle)
                : '',
              budgetResponsibility: this.defaultOptionBudgetResponsibility[0]
            });
            this.enableSCDControls();
          } else {
            this.showMessage(
              'control-center.businesspartner.already-exists',
              0
            );
          }
        });
    } else {
      this.formGroup.reset();
      this.buildForm();
    }
  }

  private mapSalutation(title: string | undefined): string {
    if (!title) return 'none';

    // Entfernt alle Punkte und wandelt den Titel in Kleinbuchstaben um für eine konsistente Verarbeitung
    const normalizedTitle = title.replace(/\./g, '').toLowerCase();

    // Definiert die Zuordnung basierend auf dem normalisierten Titel
    const salutationMap: { [key: string]: string } = {
      herr: 'Mr.',
      mr: 'Mr.',
      frau: 'Ms.',
      mrs: 'Ms.',
      ms: 'Ms.'
    };

    return salutationMap[normalizedTitle] || 'none';
  }

  chooseGPType() {
    if (
      this.formGroup.value.gpType &&
      this.formGroup.value.gpType.name === 'Extern'
    ) {
      this.showEmail = true;
      this.setFirstCompanyName('');
      this.enableBaseControls();
      this.enableSCDControls();
    } else {
      this.disableBaseControls();
      this.disableSCDFields();
      this.setFirstCompanyName(
        this.formGroup.value['gpType'].name.toLocaleUpperCase()
      );
      this.showEmail = false;
    }
  }

  private setFirstCompanyName(name: string) {
    const firstCompanyNameControl = (
      (this.formGroup.controls['companyNames'] as FormArray).controls[
        '0'
      ] as FormGroup
    ).controls['name'];

    firstCompanyNameControl.setValue(name);
    firstCompanyNameControl.disable();
  }

  private disableBaseControls() {
    this.baseControls.forEach((key) => this.formGroup.controls[key].disable());
  }

  private enableBaseControls() {
    this.baseControls.forEach((key) => this.formGroup.controls[key].enable());
  }

  private disableSCDFields() {
    Object.keys(this.formGroup.controls).forEach((key) => {
      if (!this.baseControls.concat(this.nonSCDControls).includes(key)) {
        this.formGroup.controls[key].disable();
      }
    });
  }

  private enableSCDControls() {
    Object.keys(this.formGroup.controls).forEach((key) => {
      if (!this.baseControls.includes(key)) {
        this.formGroup.controls[key].enable();
      }
    });
  }

  changeCustomerContact(employees: any[]) {
    this.formGroup.patchValue({
      customerContact: employees.length
        ? employees.map((e) => ({
            ...{
              name: e.name ? e.name : e.scdName,
              id: e.id
            },
            ...e
          }))
        : []
    });
  }

  changeMainCustomerContact(employee: Employee) {
    this.formGroup.patchValue({
      mainCustomerContact: employee
        ? {
            ...{
              name: employee.name, //employee.scdName ? employee.scdName :
              email: employee.email, //employee.scdEMail ? employee.scdEMail :
              id: employee.id
            },
            ...employee
          }
        : null
    });
  }

  close() {
    this.dialog.close();
  }

  public save() {
    this.checked = true;
    this.busy = true;
  
    this.formGroup.markAllAsTouched();
  
    if (this.formGroup.invalid) {
      this.busy = false;
      this.showMessage('control-center.general.required-fields-empty', 0);
      return; 
    }
  
    const formValue = this.formGroup.getRawValue();
    const user = formValue.user;
    const gpType = formValue.gpType;
  
    if (gpType.name !== 'Extern' && (!user || !user.name)) {
      this.busy = false;
      this.showMessage('control-center.general.required-fields-empty', 0);
      return;
    }

    if (this.formGroup.valid && !this.formGroup.pristine) {
      this.businessPartnerService
        .checkMail(this.businessPartner.eMail)
        .then((result) => {
          if (!result) {
            this.businessPartnerService
              .create({
                ...this.formGroup.getRawValue(),
                ...this.formGroup.value,
                ...this.businessPartner
              })
              .then((partner) => {
                this.showMessage(
                  'control-center.businesspartner.saved-success',
                  1
                );
                if (this.closePopup) {
                  partner.gpType = this.businessPartner.gpType;
                  partner.fullName =
                    this.businessPartner.surName +
                    ', ' +
                    this.businessPartner.firstName;
                  const companies: any = partner.companyNames;
                  if (companies.length) {
                    const convertedCompanies = companies.map(
                      (company: string) => {
                        return {
                          name: company
                        };
                      }
                    );
                    partner.companyNames = convertedCompanies;
                  }
                  this.dialog.close(partner);
                } else {
                  setTimeout(
                    () =>
                      this.router.navigateByUrl(
                        '/businesspartner/details/' + partner.id,
                        { state: { saved: true } }
                      ),
                    1000
                  );
                }
              })
              .catch((error) => {
                this.busy = false;
                this.showMessage(error.error.message, 0);
              });
          } else {
            this.busy = false;
            this.showMessage(
              'control-center.businesspartner.already-exists',
              0
            );
          }
        });
    } else {
      this.busy = false;
      this.showMessage('control-center.general.required-fields-empty', 0);
    }
  }

  checkBPExists() {
    if (this.formGroup.value.eMail) {
      this.businessPartnerService
        .checkMail(this.formGroup.value.eMail)
        .then((result) => {
          if (result) {
            this.showMessage(
              'control-center.businesspartner.already-exists',
              0
            );
          }
        });
    }
  }

  showMessage(msg: string, type: number) {
    this.messageService.pushMessage({
      message: msg,
      title: this.translate.translate('businesspartner.general.title'),
      type
    });
  }

  pickerCondition(field: string) {
    if (field === 'businessPartner') {
      return !this.formGroup.controls['user'].valid && this.checked;
    }
    return !this.formGroup.controls[field].valid && this.checked;
  }

  private subscribeToPermissionsChanges() {
    this.appUserService.permissions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((permissions) => (this.permissions = permissions));
  }

  filterSubjectAreas() {
    if (!this.subjectAreas) {
      return;
    }
    // get the search keyword
    let search = this.subjectAreaFilterCtrl.value;
    if (!search) {
      this.subjectAreasOptions.next(this.subjectAreas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filters
    this.subjectAreasOptions.next(
      this.subjectAreas.filter((value) =>
        value.name.toLowerCase().includes(search)
      )
    );
  }

  protected checkLastTagsDisabled() {
    return (
      !this.businessPartnerConfig.validateLabel('business-partner.status') &&
      !this.businessPartnerConfig.validateLabel(
        'business-partner.newsletter-candidate'
      ) &&
      !this.businessPartnerConfig.validateLabel(
        'business-partner.ad-blocking'
      ) &&
      !this.businessPartnerConfig.validateLabel(
        'business-partner.newsletter-blocking'
      ) &&
      !this.businessPartnerConfig.validateLabel(
        'business-partner.checked-marked-for-deletion'
      ) &&
      !this.businessPartnerConfig.validateLabel('business-partner.focus') &&
      !this.businessPartnerConfig.validateLabel(
        'business-partner.christmas-card'
      )
    );
  }
}
