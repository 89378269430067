import { NgModule } from '@angular/core';
import { SliderCardComponent } from './slider-card.component';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, MatSliderModule],
  declarations: [SliderCardComponent],
  exports: [SliderCardComponent]
})
export class SliderCardModule {}
