import { Router } from '@angular/router';
import { Inject, Injectable } from "@angular/core";
import { User } from "./employee";
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY, throwError } from 'rxjs';
import { ImpersonatedHttpClient } from "../http";
import { catchError } from "rxjs/operators";
import { ENVIRONMENT } from "@ceres/frontend-helper";
import {RoleType} from "@ceres/domain";
import { AuthUser } from '@app/auth/models/auth-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: ImpersonatedHttpClient,
    private translate: TranslocoService,
    @Inject(ENVIRONMENT) private readonly environment: { edgeService: string },
    private router: Router
  ) { }

  async getUser(gid: string): Promise<User> {
    return await this.httpClient
      .get<User>(`${this.environment.edgeService}/users/byGid/${gid}`).pipe(catchError(e => {
        //TODO refactor
        if (e.status === 401) {
          this.router.navigateByUrl('/unauthorized');
          return EMPTY;
        } else {
          return throwError(e);
        }
      })
      )
      .toPromise();
  }

  async getUserById(id: number) {
    const language = this.translate.getActiveLang();
    return await this.httpClient
      .get<User>(`${this.environment.edgeService}/users/${id}`, {
        params: { language }
      })
      .toPromise();
  }

  async createUser(userRequest: User): Promise<User> {
    return await this.httpClient
      .post<User>(`${this.environment.edgeService}/users`, userRequest)
      .toPromise();
  }

  async deleteUserById(id: number) {
    return await this.httpClient
      .delete<void>(`${this.environment.edgeService}/users/${id}`)
      .toPromise();
  }

  async createGuestUser(userRequest: Omit<User, 'id' | 'settings'>): Promise<User> {
    return await this.httpClient
      .post<User>(`${this.environment.edgeService}/guestusers`, userRequest)
      .toPromise();
  }

  async createGuestUserFromToken(token: AuthUser): Promise<User> {
    const shortName = token.given_name[0].toUpperCase() + token.family_name.slice(0, 2).toUpperCase();

    const userRequest: Omit<User, 'id' | 'settings'> = {
      gid: token['custom:gid'] as string,
      corporateName: token['custom:company'] as string,
      country: token['custom:country'],
      department: token['custom:org_code'],
      email: token.email,
      isActive: true,
      name: `${token.family_name}, ${token.given_name}`,
      nameShort: shortName,
      role: { id: 9, name: RoleType.Guest }
    };

    return await this.createGuestUser(userRequest);
  }
}
