<ng-container *ngIf="data">
  <div class="row dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="col-11 left">
      <h4>{{data.header ? (data.header | transloco) : ('shared.dialogs.unsaved-changes-title' | transloco)}}</h4>
    </div>
    <div class="col-1 right">
      <button *ngIf="data.disableClose" (click)="cancel()">
        <i class="a-icon a-icon--close"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2">
      <p>{{data.text | transloco}} </p>
      <p *ngIf="data.warningText" class="warning-text">{{data.warningText | transloco}}</p>
      <p *ngIf="data.amount">{{'control-center.general.amount-selected' | transloco}} {{data.amount}}</p>
    </div>
  </div>
  <div *ngIf="!data.disableClose" class="row">
    <div class="col-12">
      <div class="d-flex gap-10">
        <ceres-button (click)="confirm()" [icon]="'check-mark'" [style]="'red'">
          {{(data.confirmButtonText ? data.confirmButtonText : 'shared.buttons.apply') | transloco }}
        </ceres-button>
        <ceres-button *ngIf="!data.disableCancleButton" (click)="cancel()" [icon]="'close'">
          {{ (data.cancleButtonText ? data.cancleButtonText : 'shared.buttons.cancel') | transloco }}
        </ceres-button>
      </div>
    </div>
  </div>
</ng-container>

