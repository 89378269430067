<ceres-dialog-header
  [title]="title"
  (close)="handleCancel()"
></ceres-dialog-header>

<mat-dialog-content>
  <div class="row mb-2">
    <div class="col-12">
      <div>
        <mat-form-field class="mat-custom mat-fix-padding" appearance="fill">
          <mat-label>{{ 'shared.miscellaneous.search-dots' | transloco }}</mat-label>
          <input [formControl]="filterControl" type="search" matInput #searchInput>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <div class="table-container">
        <mat-table
          [dataSource]="dataSource"
          matSort
        >
          <ng-container matColumnDef="selected">
            <mat-header-cell *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox
                (change)="select(element, $event)"
                [checked]="element === selectedValue"
              ></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container *ngFor="let column of columns; let i = index">
            <ng-container *ngIf="i !== 0" matColumnDef="{{ column.key }}">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header>{{
                  column.title | transloco
                  }}</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element[column.key] }}
              </mat-cell>
            </ng-container>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnKeys"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnKeys"></mat-row>
        </mat-table>
      </div>
      <mat-paginator
        [length]="dataSource.data.length"
        [pageSizeOptions]="[15, 30, 50]"
        [pageSize]="15"
      >
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="actions--container">
  <ceres-button
    (click)="handleCancel()"
    [icon]="'close'"
    [style]="'primary'"
  >{{
    'shared.buttons.cancel' | transloco
    }}</ceres-button>
  <ceres-button
    (click)="handleApply()"
    icon="check-mark"
  >{{
    'shared.buttons.apply' | transloco
    }}</ceres-button>
</mat-dialog-actions>

