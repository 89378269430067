import { CreatedUpdated } from "./created_updated";
import { Employee } from "./employee";
import { Department } from './department';

export enum ServicePriceUnit {
  AMOUNT = 'AMOUNT',
  HOURS = 'HOURS'
}

export interface ServicePricingPackage extends CreatedUpdated {
  id?: number;
  name: string;
  description: string;
  price: string;
  materialLong?: string;
  priceUnit: ServicePriceUnit;
  gbsServiceId: string;
  departments?: Department[];
  validFrom?: string;
  validTo?: string;
  hasRequiredComment?: boolean;
}

export interface ServiceDepartmentPackage extends CreatedUpdated {
  id?: number;
  department?: Department;
  poNumber: string;
  poPosition: string;
  validFrom?: string;
  validTo?: string;
}


export interface TransactionalWorkload {
    id?: number;
    quantity: number;
    //service: Service;
    servicePricingPackage?: ServicePricingPackage;
    serviceDepartmentPackage?: ServiceDepartmentPackage;
    manuallyPoNumber?: string;
    manuallyPoPosition?: string;
    servicePricingPackageId?: number;
    workingHours: number;
    country: string;
    ucr_tv?: string;
    commentReason?: string;
    comment?: string;
    date: Date;
    employeeId: number;
    project: string;
    employee?: Employee;
    department: Department;
    creator?: Employee;
}

export interface TransWorkloadsGetRequest {
    employeeId?: number;
}

export interface TransWorkloadsExportRequest {
  employeeId?: string | null;
  validFrom?: string;
  validTo?: string;
}
