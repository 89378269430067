import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FeatureTogglesService } from "./services/feature-toggles/feature-toggles.service";
import { RequiredFeaturesDirective } from './directives/required-features.directive';
import { FEATURES } from "./tokens/features.token";
import { FeatureToggleConfig, property } from "./interfaces/feature-toggle-config.interface";

@NgModule({
  imports: [CommonModule],
  declarations: [
    RequiredFeaturesDirective,
  ],
  exports: [RequiredFeaturesDirective]
})
export class FeatureTogglesModule {
  public static forRoot<T extends FeatureToggleConfig>(): ModuleWithProviders<FeatureTogglesModule> {
    return {
      ngModule: FeatureTogglesModule,
      providers: [
        FeatureTogglesService,
        {
          provide: FEATURES,
          useValue: property<T>(),
        }
      ],
    }
  }

  public static forChild(): ModuleWithProviders<FeatureTogglesModule> {
    return { ngModule: FeatureTogglesModule }
  }
}
