import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SCDService } from "@ceres/shared/services";
import { CommonLoading } from '@ceres/frontend-helper';
import { CeresSCDUser } from "@ceres/shared/services";

@Component({
  selector: 'ceres-scd-extern-dialog',
  templateUrl: './scd-extern-dialog.component.html',
  styleUrls: ['./scd-extern-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScdExternDialogComponent extends CommonLoading(Object) implements OnInit {

  @ViewChild('searchInput2') searchField2!: ElementRef;
  input: ElementRef;

  displayedColumns = [
    'selected',
    'surName',
    'firstName',
    'eMail',
    'department'
  ];

  dataSource: MatTableDataSource<any>;

  firstName: string;
  surName: string;
  eMail: string;
  department: string;

  selectedUser: any;
  multiple = false;
  selectedUsers = [];
  fullUser = false;


  constructor(
    public dialogRef: MatDialogRef<ScdExternDialogComponent>,
    private scdService: SCDService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    if (data.multiple) {
      this.multiple = data.multiple;
    }
    if (data.fullUser) {
      this.fullUser = data.fullUser;
    }
    if (this.data.users) {
      if (this.data.users instanceof Array) {
        this.selectedUsers = [...this.data.users];
      } else {
        this.selectedUsers = [this.data.users];
      }
      if (this.selectedUsers && this.selectedUsers.length) {
        this.loading = true;
        this.scdService
          .searchByGID(this.selectedUsers)
          .subscribe((result: CeresSCDUser[]) => {
            this.selectedUsers = result;
            for (const element of this.selectedUsers) {
              element.selected = true;
            }
            this.dataSource = new MatTableDataSource(this.selectedUsers);
            this.loading = false;
          });
      }
    }
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    setTimeout(() => {
      this.searchField2.nativeElement.focus();
    }, 1000);
  }

  applyFilter(event: KeyboardEvent | MouseEvent) {
    if (
      (this.firstName || this.surName || this.eMail) &&
      ((event instanceof KeyboardEvent && event.keyCode === 13) ||
        event.type === 'click')
    ) {
      this.loading = true;
      this.scdService
        .searchUser(this.firstName, this.surName, this.eMail)
        .subscribe(results => {
          const data = results.map(element => ({
            ...element,
            selected: this.selectedUser
              ? this.selectedUser.some(user => user.gid === element.gid)
              : false
          }));
          this.dataSource = new MatTableDataSource(data);
          this.loading = false;
        });
    }
  }

  changeSelected(element, event) {
    if (!this.multiple) {
      if (event.checked) {
        for (const user of this.dataSource.data) {
          user.selected = false;
        }
        this.selectedUser = element;
        element.selected = event.checked;
      } else {
        delete this.selectedUser;
      }
    } else {
      element.selected = event.checked;
      if (event.checked) {
        this.selectedUsers.push(element);
      } else {
        this.selectedUsers.forEach((item, idx) => {
          if (item.gid === element.gid) {
            this.selectedUsers.splice(idx, 1);
            return;
          }
        });
      }
    }
  }

  remove(element) {
    this.changeSelected(element, { checked: false });
    for (const item of this.dataSource.data) {
      if (item === element) {
        item.selected = false;
      }
    }
  }
  async closeDialog() {
    this.loading = true;

    if (this.multiple) {
      this.dialogRef.close(
        this.selectedUsers.map(e => {
          return {
            if(e) {
              return {
                Id: e.Id,
                scdGid: e.gid,
                scdName:
                  e.surName + ', ' + e.firstName + ' (' + e.orgUnit + ')',
                scdEMail: e.mail
              };
            }
          };
        })
      );
    } else if (this.selectedUser) {
      let element = {
        Id: this.selectedUser.Id,
        scdGid: this.selectedUser.gid,
        scdName:
          this.selectedUser.surName +
          ', ' +
          this.selectedUser.firstName +
          ' (' +
          this.selectedUser.department +
          ')',
        scdEMail: this.selectedUser.mail,
        ...this.selectedUser
      };

      if (this.selectedUser.location) {
        await this.scdService
          .getByLocation(this.selectedUser.location)
          .then(result => {
            if (result && result[this.selectedUser.location]) {

              const location = result[this.selectedUser.location].find(singleLocation => {
                return singleLocation.dn.includes(this.selectedUser.organizationUnit) ? singleLocation.dn.includes(this.selectedUser.organizationUnit) : result[this.selectedUser.location][1];
              });

              element = {
                ...element,
                ...location
              };
            }
          }).catch(reason => console.error(reason));
      }
      this.loading = false;
      this.dialogRef.close(element);
    }
  }
}
