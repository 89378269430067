import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Component, HostListener, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'ceres-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
      amount?: number;
      header: string;
      disableClose: boolean;
      confirmButtonText: string;
      cancleButtonText: string;
      disableCancleButton: boolean;
      warningText?: string;
    },
    private matDialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  ngOnInit() {
    //console.log('Init');
  }

  public cancel() {
    this.matDialogRef.close();
  }

  public confirm() {
    this.matDialogRef.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.matDialogRef.close();
  }
}
