import { BusinessPartnerSelectionComponent } from '../business-partner-selection/business-partner-selection.component';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ScdExternDialogComponent } from '../scd-extern-dialog/scd-extern-dialog.component';
import { ScdDialogComponent } from '../scd-dialog/scd-dialog.component';
import { EmployeeService } from '@ceres/shared/services';
import { MessageService } from '@ceres/shared/services';
import { BusinessPartner, Employee, IUser } from '@ceres/domain';
import { Employee as AppEmployee } from '@ceres/shared/services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ceres-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserPickerComponent implements OnInit {
  @Input() extern!: boolean;
  @Input() dpt!: boolean;
  @Input() appUser!: boolean;
  @Input() noCheck!: boolean;
  @Input() multiple!: boolean;
  @Input() fullUser!: boolean;
  @Input() placeholder!: string;
  @Input() value!: any; //string /* name, email, phone */ | Employee | Employee[] | BusinessPartner | IUser;
  @Input() onlyString!: boolean;
  @Input() readonly!: boolean;
  @Input() component!: boolean;
  @Input() kind!: string;
  @Input() isEdit!: boolean;
  @Input() buttonText!: string;

  @Input() required!: boolean;
  @Input() activity!: boolean;
  @Input() condition!: boolean;
  @Input() businessPartner!: boolean;

  @Output()
  selected = new EventEmitter<
    string | Employee | Employee[] | BusinessPartner | IUser | null
  >();

  constructor(
    private dialog: MatDialog,
    private employeeService: EmployeeService,
    private messageService: MessageService,
    private translateService: TranslocoService
  ) {}

  ngOnInit() {
    if (this.value && this.multiple) {
      this.value = (this.value as Employee[])
        .filter((e) => e)
        .filter((e: any, idx, arr: any[]) => {
          if (idx === 0) {
            return true;
          }
          return e.scdGid !== arr[idx - 1].scdGid || e.id !== arr[idx - 1].id;
        });
    }
  }

  openEmployeeSelection(multiple: boolean = false) {
    let dialogRef;
    if (this.extern) {
      dialogRef = this.dialog.open(ScdExternDialogComponent, {
        disableClose: true,
        width: '1000px',

        data: {
          name: 'Confirm',
          users: this.value,
          multiple: multiple,
          fullUser: this.fullUser
        }
      });
    } else if (this.businessPartner) {
      dialogRef = this.dialog.open(BusinessPartnerSelectionComponent, {
        disableClose: true,
        width: '1000px',

        data: {
          partners: this.value,
          multiple: multiple
        }
      });
    } else {
      dialogRef = this.dialog.open(ScdDialogComponent, {
        disableClose: true,
        width: '1000px',

        data: {
          name: 'Confirm',
          employees: this.value,
          multiple: multiple,
          fullUser: this.fullUser,
          kind: this.kind,
          activity: this.activity
        }
      });
    }

    dialogRef
      .afterClosed()
      .subscribe((selected: any | Employee | Employee[]) => {
        if (this.appUser && selected) {
          if (this.noCheck) {
            this.selected.emit(selected);
          } else {
            this.employeeService
              .getUserByEmail(selected.scdEMail, false)
              .then((result) => {
                if (result) {
                  this.messageService.pushMessage({
                    message: 'control-center.others.user-exists',
                    title: this.translateService.translate(
                      'employee.general.employee-title'
                    )
                  });
                } else if (selected) {
                  this.selected.emit(selected);
                }
              })
              .catch(() => {
                this.selected.emit(selected);
              });
          }
        } else if (selected) {
          this.selected.emit(selected);
        }
      });
  }

  removeSelected(employee?: any /*AppEmployee???*/) {
    if (employee) {
      this.selected.emit(
        this.value.filter((e: any) => {
          return e.scdGid ? e.scdGid !== employee.scdGid : e.id !== employee.id;
        })
      );
    } else {
      this.selected.emit(null);
    }
  }

  public isDE() {
    return this.translateService.getActiveLang() === 'de';
  }

  public getAssignedActivities(employee: Employee) {
    return employee && employee.assignedActivities
      ? employee.assignedActivities
          .map((e) => this.translateService.translate(e.translationKey))
          .join(', ')
      : '';
  }

  public getValue() {
    if (this.businessPartner) {
      return this.value
        ? `${this.value.fullName} (${this.value.department})`
        : '';
    }

    return this.value
      ? this.value.name
        ? this.value.name
        : this.value.user && this.value.user.scdName
        ? this.value.user.scdName
        : this.value.id
        ? '-'
        : this.value
      : '';
  }

  public isEmployeeSet() {
    return (
      !this.readonly &&
      (!this.value ||
        !this.value.name ||
        !this.value.user ||
        !this.value.user.scdName)
    );
  }
}
