
export enum ForecastMethod {
  FLAT_BVI = 'FlatBVI',
  FLAT_FTE = 'FlatFTE',
  TRX_FTE = 'TrxFTE',
  TRX_BVI = 'TrxBVI',
  AVG_TRX_BVI = 'AvgTrxBVI',
  MANUAL = 'Manual',
  FLAT_PACKAGE = 'FlatPackage'
}
