import { News as CeresNews } from '@ceres/domain'

import { BusinessArea } from './business-area';
import { Portfolio } from './portfolio';


export class News implements CeresNews {
  id: number;
  title: string;
  content: string;
  collapsed: boolean;
  critical: boolean;
  validTo: Date;
  priority: number;
  target: string;
  targetGroup: Portfolio;
  targetTeam: BusinessArea;


  constructor(object?: Partial<News>) {
    Object.assign(this, object);
  }
}
