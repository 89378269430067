<div
  class="control-center-container"
  [ngClass]="{
    'first-message': showMessageInstantly && toast && !showMessageList,
    collapsed: showMessageList,
    green:
      showMessageInstantly && toast && !showMessageList && toast?.type === 1,
    yellow:
      showMessageInstantly && toast && !showMessageList && toast?.type === 2
  }"
>
  <ng-container *ngIf="showMessageList">
    <div class="messages-container" data-testid="message-list-container">
      <div
        *ngFor="let message of messageService.messages$ | async; let i = index"
      >
        <h4 *ngIf="message.source">{{ message.source }}</h4>
        <div class="message-container">
          <p *ngIf="noMessageArray(message); else multiLineMessage">
            <b>{{ message.title }}:</b>
            <br />
            {{ message.message | transloco: message.obj }}
          </p>
          <ng-template #multiLineMessage>
            <p>
              <b>{{ message.title + ': ' }}</b>
              <br />
              <span *ngFor="let msg of message.message">
                {{ msg | transloco: message.obj }}
              </span>
            </p>
          </ng-template>

          <i
            (click)="messageService.deleteMessage(message)"
            class="material-icons close"
            data-testid="deleteMessage"
          >
            close
          </i>
        </div>
      </div>
      <div
        *ngIf="!(messageService.hasMessages$ | async)"
        class="message-container"
      >
        <p>{{ 'control-center.general.no-messages' | transloco }}</p>
      </div>
    </div>

    <div class="settings-container">
      <mat-checkbox [(ngModel)]="showMessageInstantly">{{
        'control-center.general.automatically' | transloco
      }}</mat-checkbox>
    </div>
  </ng-container>

  <ng-container *ngIf="!showMessageList && toast">
    <div
      data-testid="toast"
      *ngIf="noMessageArray(toast); else multiLineMessage"
    >
      <p>
        <b>
          {{ toast.title + ': ' + (toast.message | transloco: toast.obj) }}
        </b>
      </p>
    </div>
    <ng-template #multiLineMessage>
      <p>
        <b>
          {{ toast.title + ': ' }}
          <span *ngFor="let msg of toast.message">
            {{ msg | transloco: toast.obj }}
          </span>
        </b>
      </p>
    </ng-template>
  </ng-container>

  <i
    (click)="toggleMessageList()"
    data-testid="toggle-message-list-on"
    *ngIf="!showMessageList"
    [ngClass]="{
      alert: !showMessageInstantly && toast && toast?.type === 0,
      good: !showMessageInstantly && toast && (toast?.type === 1 || toast?.type === 2),
      'pulse-inform':
        !showMessageList &&
        (messageService.messages$ | async).length > 0 &&
        messagesType(messageService.messages$ | async) === 2,
      'pulse-info':
        !showMessageList &&
        (messageService.messages$ | async).length > 0 &&
        messagesType(messageService.messages$ | async) === 1,
      'pulse-error':
        !showMessageList &&
        (messageService.messages$ | async).length > 0 &&
        messagesType(messageService.messages$ | async) === 0,
      'first-message': showMessageInstantly && toast
    }"
    class="material-icons"
    >info
  </i>
  <div class="info--counter" *ngIf="!showMessageList" data-testid="msg-counter">
    {{ (messageService.messages$ | async).length }}
  </div>
  <i
    (click)="toggleMessageList()"
    data-testid="toggle-message-list-off"
    *ngIf="showMessageList"
    class="material-icons"
    >cancel
  </i>
</div>
