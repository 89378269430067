<div class="dialog-header">
  <h3 class="title">{{ data.title }}</h3>
</div>
<mat-dialog-content>
  <div class="info-text" *ngIf="data.infoText">
    {{ data.infoText }}
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions-container">
  <ceres-button (click)="confirm()">{{ data.buttons.confirm }}</ceres-button>
  <ceres-button (click)="cancel()" [style]="'red'">{{
    data.buttons.cancel
  }}</ceres-button>
</mat-dialog-actions>
