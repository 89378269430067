import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SharedModule } from '@app/shared/shared.module';
import { FeaturesModule } from "@app/features/features.module";
import { HasPermissionModule } from "@ceres/ui";



@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    SharedModule, //TODO ID1: NOT NEEDED MAYBE
    FeaturesModule.forChild(),
    HasPermissionModule
  ],
  exports: [HomeComponent]
})
export class HomeModule { }
