import { Injectable } from "@angular/core";
import { FeatureTogglesConfigLoaderService } from "@ceres/feature-toggles";
import { Observable, of } from "rxjs";
import FEATURES_CONFIG, { CeresFeaturesConfig } from '@ceres/shared/features';

@Injectable()
export class StaticFeaturesAdapterService extends FeatureTogglesConfigLoaderService<CeresFeaturesConfig> {
  private config = of(FEATURES_CONFIG)
  loadFeatureConfig(): Observable<CeresFeaturesConfig> {
    return this.config;
  }
}
