import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiSelectComponent } from "./multi-select.component";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { TranslocoModule } from "@ngneat/transloco";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatCheckboxModule,
    TranslocoModule,
    FormsModule
  ],
  declarations: [MultiSelectComponent],
  exports: [MultiSelectComponent]
})
export class MultiSelectModule {}
