import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(
    private translateService: TranslocoService
  ) { }

  transform(value: any, pattern = 'mediumDate'): any {
    if (!value) {
      return value;
    }
    try {
      const datePipe: DatePipe = new DatePipe(this.translateService.getActiveLang());
      return datePipe.transform(value, pattern);
    } catch {
      return value;
    }
  }

  parseDate(input) {
    if (this.translateService.getActiveLang() === 'de') {
      const parts = input.match(/(\d+)/g);
      return new Date(parts[2], parts[1] - 1, parts[0]);
    }
    return new Date(input);
  }
  getMonthName(month: number) {
    const monthsEN = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];
    const monthsDE = ['Okt', 'Nov', 'Dez', 'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep'];
    if (this.translateService.getActiveLang() === 'de') {
      return monthsDE[month - 1];
    }
    return monthsEN[month - 1];
  }
}
