<div class="pwo-filter filter-header">
  <div class="input">
    <mat-form-field class="mat-custom w-100 mat-fix-padding" appearance="fill">
      <mat-label>{{ 'shared.miscellaneous.search-dots' | transloco }}</mat-label>
      <input [(ngModel)]="searchTerm"
             (ngModelChange)="search()"
             (keyup.enter)="selected && apply()"
             matInput />
    </mat-form-field>
  </div>
</div>

<div *ngIf="filterValues" class="pwo-filter filter-values">
  <mat-radio-group [(ngModel)]="selected">
    <mat-radio-button *ngFor="let f of filterValues" [value]="f">
      {{ isTranslationKey && f ? translationPrefix ? (translationPrefix + f | transloco) : (f | transloco) : f }}
    </mat-radio-button>
  </mat-radio-group>
</div>

<button (click)="apply()"
        class="a-button a-button--pwo full"
        type="button" mat-button>
  <mat-icon>check</mat-icon>
  {{'shared.buttons.apply' | transloco}}
</button>
<button *ngIf="filter.isApplied" (click)="clear()"
        class="a-button a-button--pwo full"
        type="button" mat-button>
  <mat-icon>delete</mat-icon>
  Clear
</button>
