import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminGeneralComponent } from './components/admin-general/admin-general.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule, LayoutModule } from '@ceres/ui';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    RouterModule,
    ButtonModule,
    LayoutModule
  ],
  declarations: [AdminGeneralComponent],
  exports: [AdminGeneralComponent]
})
export class AdminGeneralModule {}
