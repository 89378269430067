import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading = false;
  private _state: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public state: Observable<boolean>;

  constructor() {
    this.state = this._state.asObservable();
  }

  public setState(isLoading: boolean) {
    this.isLoading = isLoading;
    this._state.next(this.isLoading);
  }

  public load() {
    this.setState(true);
  }

  public done() {
    this.setState(false);
  }

  public promise(promise) {
    this.setState(true);
    promise.then(() => {
      this.setState(false);
    });
  }
}
