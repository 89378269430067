import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServicePricingPackage } from '@ceres/domain';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicePackagesService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public async getAll(): Promise<ServicePricingPackage[]> {
    return await this.httpClient
      .get<ServicePricingPackage[]>(`${environment.edgeService}/service-pricing-package`)
      .toPromise();
  }

  public async create(servicePricingPackage: ServicePricingPackage) {
    return await this.httpClient
      .post<ServicePricingPackage>(`${environment.edgeService}/service-pricing-package`, servicePricingPackage)
      .toPromise();
  }

  public async update(servicePricingPackage: ServicePricingPackage) {
    return await this.httpClient
      .patch<ServicePricingPackage>(
        `${environment.edgeService}/service-pricing-package/${servicePricingPackage.id}`,
        servicePricingPackage
      )
      .toPromise();
  }

  public async delete(servicePricingPackage: ServicePricingPackage) {
    return await this.httpClient
      .delete(`${environment.edgeService}/service-pricing-package/${servicePricingPackage.id}`)
      .toPromise();
  }
}
