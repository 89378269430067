import { Classification, ClassificationQuestion } from '@ceres/domain';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProgramServicesModule } from './program-services.module';
import { ImpersonatedHttpClient } from "@ceres/shared/services";

@Injectable({
  providedIn: ProgramServicesModule
})
export class ClassificationService {
  public gradingSystem = {
    D: [{ first: 8, last: 8 }, { first: 24, last: 25 }],
    C3: [{ first: 9, last: 9 }, { first: 26, last: 28 }],
    C2: [{ first: 10, last: 14 }, { first: 29, last: 43 }],
    C1: [{ first: 15, last: 19 }, { first: 44, last: 58 }],
    B3: [{ first: 20, last: 24 }, { first: 59, last: 73 }],
    B2: [{ first: 25, last: 29 }, { first: 74, last: 88 }],
    B1: [{ first: 30, last: 34 }, { first: 89, last: 103 }],
    A3: [{ first: 35, last: 39 }, { first: 104, last: 118 }],
    A2: [{ first: 40, last: 44 }, { first: 119, last: 133 }],
    A1: [{ first: 45, last: 48 }, { first: 134, last: 144 }]
  };

  private questionMetaData: ClassificationQuestion[][];

  constructor(private http: ImpersonatedHttpClient) {}

  public getAll(): Promise<Classification[]> {
    return this.http
      .get<Classification[]>(`${environment.edgeService}/classifications`)
      .toPromise();
  }

  public getById(id: number): Promise<Classification[]> {
    return this.http
      .get<Classification[]>(
        `${environment.edgeService}/classifications/programs/${id}`
      )
      .toPromise();
  }

  public async getQuestions(): Promise<ClassificationQuestion[][]> {
    if (!this.questionMetaData) {
      this.questionMetaData = await this.http
        .get<ClassificationQuestion[][]>(
          `${environment.edgeService}/classifications/questions`
        )
        .toPromise();
    }
    return this.questionMetaData;
  }

  public create(classification: Partial<Classification>) {
    return this.http
      .post<Classification>(
        `${environment.edgeService}/classifications`,
        classification
      )
      .toPromise();
  }

  public update(classification: Classification) {
    return this.http
      .patch<Classification>(
        `${environment.edgeService}/classifications/${classification.id}`,
        classification
      )
      .toPromise();
  }
}
