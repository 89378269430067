import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { DateFilter } from '../../../../lib/filters';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { FilterService } from '../../../../lib/filter-service';

@Component({
  selector: 'lib-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {

  @ViewChild('tabGroup') tabs: MatTabGroup;

  _filter: DateFilter;

  @Input() filter: DateFilter;
  @Input() filterService: FilterService;

  @Output() close = new EventEmitter();

  startDate: Date;
  endDate: Date;

  index = 0;

  constructor() {}

  apply() {
    this.filter.start = this.startDate;
    this.filter.end = this.endDate;
    this.filterService.applyFilter(this.filter);
    this.close.next();
  }

  clear() {
    this.startDate = undefined;
    this.endDate = undefined;
    this.apply();
  }

}
