<div
  class="row dialog-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="col-11 left">
    <h4>{{ 'businesspartner.buttons.select-business-partner' | transloco }}</h4>
  </div>
  <div class="col-1 right">
    <button (click)="dialogRef.close()">
      <i class="a-icon a-icon--close"></i>
    </button>
  </div>
</div>
<div class="row px-3">
  <div class="col-8 mb-2 d-flex align-center">
    <mat-form-field class="mat-custom mat-fix-padding" appearance="fill">
      <mat-label>{{
        'shared.miscellaneous.begin-search' | transloco
      }}</mat-label>
      <input [formControl]="filterValue" #searchInput matInput />
    </mat-form-field>
  </div>
  <div class="col-4 mb-2 d-flex align-center justify-content-end">
    <ceres-button
      (click)="openNewBusinessPartnerDialog()"
      [icon]="'check-mark'"
    >
      {{ 'businesspartner.buttons.new-businesspartner' | transloco }}
    </ceres-button>
  </div>
  <div class="col-12 px-3">
    <div class="table-container">
      <mat-table
        [dataSource]="dataSource"
        matSort
        cdkFocusInitial
        matSortActive="fullName"
      >
        <ng-container matColumnDef="selected">
          <mat-header-cell *matHeaderCellDef>
            <ng-container *ngIf="multiple">
              <mat-checkbox
                (change)="selectAll()"
                *ngIf="!fewSelected"
                [(ngModel)]="allChecked"
              ></mat-checkbox>
              <mat-checkbox
                (change)="selectAll()"
                *ngIf="fewSelected"
                class="mat-checkbox-semichecked"
              >
              </mat-checkbox>
            </ng-container>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.selected"
              (change)="changeSelected(element, $event)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container *ngFor="let column of displayedColumns; let i = index">
          <ng-container *ngIf="i !== 0" matColumnDef="{{ column }}">
            <mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{ 'columns.' + column | transloco }}</span>
            </mat-header-cell>
            <ng-container *ngIf="column === 'status'; else default">
              <mat-cell *matCellDef="let element">
                {{ element | extractTranslationKey : column | transloco }}
              </mat-cell>
            </ng-container>
            <ng-template #default>
              <mat-cell *matCellDef="let element">
                {{ element[column] }}
              </mat-cell>
            </ng-template>
          </ng-container>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <ceres-loading *ngIf="busy" [insideTable]="true"></ceres-loading>
    </div>
    <mat-paginator
      #paginator
      [length]="dataSource.data.length"
      [pageSizeOptions]="[15, 30, 50]"
      [pageSize]="15"
    >
    </mat-paginator>
  </div>
  <div class="col-12">
    <div class="d-flex gap-10">
      <ceres-button
        (click)="closeDialog(selectedPartners)"
        [icon]="'check-mark'"
      >
        {{ 'shared.buttons.apply' | transloco }}
      </ceres-button>
      <ceres-button
        (click)="closeDialog(allChecked)"
        [style]="'red'"
        [icon]="'close'"
      >
        {{ 'shared.buttons.cancel' | transloco }}
      </ceres-button>
    </div>
  </div>
</div>
