import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FeatureHeaderComponent } from "./feature-header.component";

@NgModule({
  imports: [CommonModule],
  declarations: [FeatureHeaderComponent],
  exports: [FeatureHeaderComponent]
})
export class FeatureHeaderModule {}
