import { EventEmitter } from './event-emitter';
import { Filter, SearchFilter, MultiValueFilter } from './filters';
import {
  filterItems,
  generateFilterValuesFor,
  localeCompare
} from './filtering';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { takeWhile } from 'lodash-es';
import {
  map,
  distinctUntilChanged,
  switchMap,
  shareReplay
} from 'rxjs/operators';
import { ListItem } from '@ceres/frontend-helper';

export class FilterService<TData extends { [key: string]: any } = unknown> {
  public dataChanged = new EventEmitter<TData[]>();
  private readonly filteredData$$ = new BehaviorSubject<TData[]>([]);
  public readonly filteredData$ = this.filteredData$$.asObservable();
  public filtersApplied = new EventEmitter<Filter>();

  public globalFilters: Filter[] = [];
  public columnFilters: Filter[] = [];
  protected searchFilters: SearchFilter[] = [];
  protected filterValues: Map<string, Observable<any[]>> = new Map();
  public allData: TData[];
  public fixedFilterValues: object;
  private ignoreDistinctUntilChangeWhenEmpty: boolean = false;
  public initialColumnSetup: ListItem[] = [];
  public availableColumns: ListItem[] = [];
  private _displayedColumns$: BehaviorSubject<ListItem[]> = new BehaviorSubject<
    ListItem[]
  >(this.initialColumnSetup);
  public displayedColumns: Observable<ListItem[]> =
    this._displayedColumns$.asObservable();

  readonly appliedFilters$ = new BehaviorSubject<Filter[]>([]);

  setData(data: TData[]) {
    this.allData = data;
    this.triggerFilter();
  }

  /**
   * Sets all active filters to initialize the service.
   */
  // prettier-ignore
  init(filters: Record<string, Filter>, ignoreDistinctUntilChangeWhenEmpty?: boolean): void;
  // prettier-ignore
  init(filters: Filter[], ignoreDistinctUntilChangeWhenEmpty?: boolean): void;
  // prettier-ignore
  init(filters: Filter[] | Record<string, Filter>, ignoreDistinctUntilChangeWhenEmpty?: boolean): void {
    if (!Array.isArray(filters)) {
      filters = Object.values(filters);
    }
    filters.forEach((f) => {
      if (f.isActive) {
        this.setFilter(f);
      }
      this.filterValues.set(f.key, this.createFilterValuesRequest(f.key));
    });
    this.ignoreDistinctUntilChangeWhenEmpty = ignoreDistinctUntilChangeWhenEmpty || false;
    this.triggerFilter();
  }

  /**
   * Same as applyFilter, but doesn't trigger filtering.
   * Call triggerFilter when you have set all filters.
   * When filtering by remote the allData is set to empty to trigger the change detection.
   */
  setFilter(filter: Filter) {
    if (filter.scope === 'remote') {
      this.dataChanged.emit([]);
      this.filteredData$$.next([]);
    }

    this.replaceColumnFilter(filter);

    filter.isApplied = filter.isActive();

    this.filtersApplied.emit(filter);
  }

  applySearch(term: string) {
    this.searchFilters.forEach((filter) => {
      filter.term = term;
      filter.isApplied = filter.isActive();
      this.filtersApplied.emit(filter);
    });
    this.triggerFilter();
  }

  getSearchTerm() {
    return this.searchFilters.map(({ term }) => term)[0] || '';
  }

  private replaceColumnFilter(filter: Filter) {
    const filterIdx = this.columnFilters.findIndex(
      ({ key }) => filter.key === key
    );
    if (filterIdx >= 0) {
      if (this.columnFilters[filterIdx].isApplied) {
        // already applied, just update values
        this.columnFilters = [
          ...this.columnFilters.slice(0, filterIdx),
          filter,
          ...this.columnFilters.slice(filterIdx + 1)
        ];
      } else {
        // not applied yet, move to end
        this.columnFilters = [
          ...this.columnFilters.slice(0, filterIdx),
          ...this.columnFilters.slice(filterIdx + 1),
          filter
        ];
      }
    } else {
      // insert last
      this.columnFilters = [...this.columnFilters, filter];
    }
  }

  getColumnFilters(...keys: string[]) {
    const found = keys
      .map((ele) => this.columnFilters.find(({ key }) => key === ele))
      .filter((e) => e);
    if (keys.length > 1) {
      return found;
    }
    return found[0];
  }

  /**
   * Sets the filter and triggers filtering
   */
  applyFilter(filterKey: string): void;
  applyFilter(filter: Filter): void;
  applyFilter(filter: string | Filter) {
    if (typeof filter === 'string') {
      filter = this.columnFilters.find(({ key }) => key === filter);
    }

    if (filter) {
      this.setFilter(filter);
      this.triggerFilter();
    }
  }

  /**
   * Triggers filtering.
   * Is called after applyFilter automatically.
   */
  triggerFilter() {
    const appliedFilters = [
      ...this.globalFilters,
      ...this.searchFilters,
      ...this.columnFilters
    ].filter((f) => f.isApplied);
    this.appliedFilters$.next(appliedFilters);

    const filtered = filterItems(
      appliedFilters.filter((f) => f.scope !== 'remote'),
      this.allData
    );
    this.dataChanged.emit(filtered);
    this.filteredData$$.next(filtered);
  }

  getAppliedRemoteFilters() {
    return this.columnFilters.filter(
      (f) => f.isApplied && f.scope === 'remote'
    );
  }

  /** Return filter values for given filter. */
  getFilterValues({ key }: Filter): Observable<any> {
    if (this.filterValues.has(key)) {
      return this.filterValues.get(key);
    }
    if (this.fixedFilterValues && this.fixedFilterValues[key]) {
      return of(this.fixedFilterValues[key]);
    }

    const request = this.createFilterValuesRequest(key);
    this.filterValues.set(key, request);
    return request;
  }

  refreshFilterValues(keys: string[]): void {
    keys.forEach((key) => {
      const request = this.createFilterValuesRequest(key);
      this.filterValues.set(key, request);
    });
  }

  /** Add currently selected values to the list of available filter values. */
  private addSelectedFilterValues(values: any[] = [], key: string) {
    const filter = this.columnFilters.find(
      (filter) => filter.key === key
    ) as MultiValueFilter;

    if (filter && filter.selected) {
      return [
        ...values,
        ...filter.selected.filter((val) => !values.includes(val))
      ];
    }

    return values;
  }

  /** Request new filter values from server.
   *  Override to provide custom fetch logic per entity.
   */
  protected fetchFilterValues(
    field: string,
    filters: string
  ): Observable<object> {
    if (this.fixedFilterValues && this.fixedFilterValues[field]) {
      return of(this.fixedFilterValues);
    }
    const filter = this.columnFilters.find(({ key }) => key === field);
    if (!filter) {
      return of({});
    }
    return of({
      [field]: generateFilterValuesFor(
        filter,
        Object.values(this.columnFilters),
        this.allData
      )
    });
  }

  /** Returns all filters that were applied before this one. */
  private getPreviousColumnFilters(filters: Filter[], key: string) {
    return takeWhile(
      filters,
      (filter) =>
        !(this.columnFilters as Filter[]).includes(filter) || filter.key !== key
    );
  }

  /** Create request for new filter values based on applied filters */
  private createFilterValuesRequest(key: string) {
    return this.appliedFilters$.pipe(
      map((filters) => this.getPreviousColumnFilters(filters, key)),
      map((filters) => JSON.stringify(filters)),
      distinctUntilChanged((prev, curr) => {
        return !this.ignoreDistinctUntilChangeWhenEmpty
          ? prev === curr
          : prev === curr && prev !== '[]';
      }), // only fetch new, when filters change or prev was empty before
      switchMap((filters) => this.fetchFilterValues(key, filters)),
      map((filterValues) => filterValues[key] as any[]),
      map((filterValues) => this.addSelectedFilterValues(filterValues, key)),
      map((filters) => filters.sort(localeCompare)),
      shareReplay(1)
    );
  }

  public setDisplayedColumns(displayedColumns: ListItem[]) {
    this._displayedColumns$.next(displayedColumns);
  }
}
