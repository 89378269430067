import { Favorite as CeresFavorite, ProjectProfile, Employee, Activity, Workload } from '@ceres/domain';

export class Favorite implements CeresFavorite {
    
    id: number;
    project: ProjectProfile;
    employee: Employee;
    sortOrder: number;
    activity: Activity[];
    businessPartner: string;
    isFavorite: boolean;
    workload: Workload[];


    constructor(object?: Partial<Favorite>) {
        if (object) {
            Object.assign(this, object);
        }
    }

}