<mat-tab-group #tabGroup class="pwo-filter date-filter" [(selectedIndex)]="index">
  <mat-tab label="{{ 'shared.miscellaneous.from' | transloco}}">
    <mat-calendar [(selected)]="startDate" (selectedChange)="index = 1"></mat-calendar>
  </mat-tab>
  <mat-tab label="{{ 'shared.miscellaneous.to' | transloco}}">
    <mat-calendar [(selected)]="endDate" [minDate]="startDate"></mat-calendar>
  </mat-tab>
</mat-tab-group>

<div class="d-flex gap-2">
<button (click)="apply()"
        class="a-button a-button--pwo full"
        type="button" mat-button>
  <mat-icon>check</mat-icon>
  {{'shared.buttons.apply' | transloco}}
</button>
<button (click)="clear()"
        class="a-button a-button--pwo full"
        type="button" mat-button>
  <mat-icon>delete</mat-icon>
  {{ 'shared.buttons.cancel' | transloco}}
</button>
</div>