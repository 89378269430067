import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RetryStrategy } from './retry-strategy';

@Injectable({
  providedIn: "root"
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen((errors) => new RetryStrategy().build(errors)),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          const error = err as HttpErrorResponse;

          if (error) {
            if (error.status === 401 && !request.url.includes('signed-url-translations')) {
              this.router.navigate(['/unauthorized']);
            }

            if (error.status === 403) {
              this.router.navigate(['/unauthorized']);

              //TODO id1: check if this still is needed
              /*if (CHECK IS TOKEN EXPIRED) { this.authenticationService.logout(); this.router.navigate(['/logout']); }
              else { this.router.navigate(['/unauthorized']); }*/
            }
          }
        }
        return throwError(err);
      })
    );
  }
}
