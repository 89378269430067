import { NgModule } from '@angular/core';
import { DialogHeaderComponent } from './dialog-header.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [DragDropModule, TranslocoModule],
  declarations: [DialogHeaderComponent],
  exports: [DialogHeaderComponent]
})
export class DialogHeaderModule {}
