import { isMoment, Moment } from 'moment';

export const adjustDateForTimezone = (d: Moment | Date): Date => {
  const date: Date = isMoment(d) ? d.toDate() : d;
  if (date) {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    //GMT -X needs to be added and for GMT+X the offset needs to be substracted
    date.setTime(timeOffsetInMS > 0 ? date.getTime() + timeOffsetInMS : date.getTime() - timeOffsetInMS);
  }
  return date
}

export const getDateType = (d: Moment | Date): Date => {
  const date: Date = isMoment(d) ? d.toDate() : d;
  return date
}

export class CeresDate extends Date {
  // To be used instead of Date for timezone issues.
  // If this does not work use the regular new Date() but wrap it with adjustDateForTimezone().
  constructor();
  constructor(value: number | string | Date);
  constructor(value: string);
  constructor(value: Date);
  constructor(year: number, month: number, date?: number, hours?: number, minutes?: number, seconds?: number, ms?: number);
  constructor(...args: [number | string | Date] | [number, number, number?, number?, number?, number?, number?] | []) {
    if (args.length === 0) {
      // No arguments: new Date()
      super();
    } else if (args.length === 1 && typeof args[0] === 'string') {
      // One string argument: new Date("dateString")
      super(args[0]);
    } else if (args.length === 1 && typeof args[0] === 'number') {
      // One number argument: new Date(timestamp)
      super(args[0]);
    } else if (args.length === 1 && typeof args[0] === 'object') {
      super(args[0]);
    }
    else {
      // Multiple arguments: new Date(year, month, date?, hours?, minutes?, seconds?, ms?)
      super(...(args as [number, number, number?, number?, number?, number?, number?]));
    }

    adjustDateForTimezone(this);
  }
}
