import { NgModule } from '@angular/core';
import { SelectModalComponent } from './select-modal.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from "../button/button.module";

@NgModule({
  declarations: [SelectModalComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatDialogModule,
    TranslocoModule,
    ButtonModule
  ],
  exports: [SelectModalComponent]
})
export class SelectModalModule {}
