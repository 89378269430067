<header class="h-100 container-fluid">
  <div class="row">
    <div class="col-8 col-sm-6 d-flex align-items-center">
      <a class="mod-logo ms-md-5 me-sm-4" routerLink="/"></a>
      <h1 class="fs-4 d-none d-sm-block app-title" routerLink="/">
        {{ 'main-title.header-title' | transloco }}
      </h1>
    </div>

    <div class="col-4 col-sm-6"></div>
  </div>
</header>
