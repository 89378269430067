import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {
  transform(permission: string | string[], permissions: Set<string>): boolean {
    if (!permission || permission === '') {
      return true;
    }
    if (!permissions) {
      return false;
    }

    let hasPermission;
    if (Array.isArray(permission)) {
      hasPermission = permission.some(value => permissions.has(value))
    } else {
      hasPermission = permissions.has(permission)
    }

    return hasPermission;
  }
}
