<div
  class="row dialog-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="col-11 left">
    <h4>{{ 'shared.buttons.scd-add-person' | transloco }}</h4>
  </div>
  <div class="col-1 right">
    <button (click)="dialogRef.close()">
      <i class="a-icon a-icon--close"></i>
    </button>
  </div>
</div>
<div class="row mb-2">
  <div class="col-9">
    <div class="d-flex flex-wrap gap-10">
      <mat-form-field class="mat-custom mat-fix-padding" appearance="fill">
        <mat-label>{{ 'shared.miscellaneous.lastname' | transloco }}</mat-label>
        <input #searchInput2
               (keyup)="applyFilter($event)"
               [(ngModel)]="surName"
               matInput>
      </mat-form-field>
      <mat-form-field class="mat-custom mat-fix-padding" appearance="fill">
        <mat-label>{{ 'shared.miscellaneous.firstname' | transloco }}</mat-label>
        <input (keyup)="applyFilter($event)"
               [(ngModel)]="firstName"
               matInput>
      </mat-form-field>
      <mat-form-field class="mat-custom mat-fix-padding" appearance="fill">
        <mat-label>{{ 'shared.properties.e-mail' | transloco }}</mat-label>
        <input (keyup)="applyFilter($event)"
               [(ngModel)]="eMail"
               matInput>
      </mat-form-field>
    </div>
  </div>
  <div class="col-3 d-flex align-center justify-content-end">
    <ceres-button (click)="applyFilter($event)" [icon]="'search'">
      {{ 'shared.buttons.search-button' | transloco }}
    </ceres-button>
  </div>
</div>
<div class="row" *ngIf="(loading$ | async) === false">
  <div class="col-12">
    <div class="table-container">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="selected">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.selected"
              (change)="changeSelected(element, $event)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="surName">
          <mat-header-cell *matHeaderCellDef
            ><span>{{
              'shared.miscellaneous.lastname' | transloco
            }}</span></mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.surName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef
            ><span>{{
              'shared.miscellaneous.firstname' | transloco
            }}</span></mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.firstName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="eMail">
          <mat-header-cell *matHeaderCellDef
            ><span>{{
              'shared.properties.e-mail' | transloco
            }}</span></mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.mail }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="department">
          <mat-header-cell *matHeaderCellDef
            ><span>{{
              'shared.properties.department' | transloco
            }}</span></mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.department
          }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
<ceres-loading *ngIf="loading$ | async" [insideTable]="true"></ceres-loading>
<div class="row mt-2">
  <div class="col-12">
    <div class="d-flex gap-10">
      <ceres-button (click)="closeDialog()" [icon]="'check-mark'">
        {{ 'shared.buttons.apply' | transloco }}
      </ceres-button>
      <ceres-button
        (click)="dialogRef.close()"
        [style]="'red'"
        [icon]="'close'"
      >
        {{ 'shared.buttons.cancel' | transloco }}
      </ceres-button>
    </div>
  </div>
</div>
