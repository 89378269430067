import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-gms-angular-client',
  template: `
    <p>Auth Callback</p>
  `,
  styles: []
})

export class AuthCallbackComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.navigateByUrl('/');
  }
}
