import { Component, OnInit } from '@angular/core';
import { isIE } from '@app/app.component';

@Component({
  selector: 'ceres-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  isIE = isIE;

  ngOnInit() {}
}
