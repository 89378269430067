import { Injectable } from "@angular/core";
import {
  FeatureTogglesConfigLoaderService
} from "../feature-toggles-config-loader/feature-toggles-config-loader.service";
import { FeatureToggleConfig, FeatureOf } from "../../interfaces/feature-toggle-config.interface";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Injectable()
export class FeatureTogglesService<T extends FeatureToggleConfig> {

  private readonly config$: Observable<T>;

  public constructor(private readonly featureToggleConfigLoader: FeatureTogglesConfigLoaderService<T>) {
    this.config$ = this.featureToggleConfigLoader.loadFeatureConfig().pipe(
      shareReplay({
        bufferSize: 1,
        refCount: false
      })
    );
  }

  /**
   * @returns Observable with value of true if all features are set to true, false otherwise
   */
  // this enforces at least one feature
  public hasFeatures(feature: FeatureOf<T, boolean>, ...features: FeatureOf<T, boolean>[]): Observable<boolean> {
    return this._hasFeatures(feature, ...features)
  }

  private _hasFeatures(...features: FeatureOf<T, boolean>[]): Observable<boolean> {
    return this.config$.pipe(
      map(config => {
        for (const feature of features) {
          if (!this.getFeatureSetting(config, feature)) {
            return false;
          }
        }
        return true;
      })
    );
  }

  private getFeatureSetting(configuration: T, feature: FeatureOf<T, boolean>): boolean {
    return !!feature.getKeys()
      .reduce((acc: T, current: string) => {
        return acc[current] as T;
      }, configuration);
  }
}
