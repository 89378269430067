import { CeresBusinessPartnerConfig } from "./interfaces/ceres-business-partner-config.interface";

const BUSINESSPARTNERCONFIG: CeresBusinessPartnerConfig = {
    'business-partner.salutation': false,
    'business-partner.title': false,
    'business-partner.subject-area': false,
    'business-partner.function': false,
    'business-partner.budget-responsibility': false,
    'business-partner.language': false,
    'business-partner.status': false,
    'business-partner.phone': false,
    'business-partner.fax': false,
    'business-partner.po-box': false,
    'business-partner.country': false,
    'business-partner.newsletter-candidate': false,
    'business-partner.newsletter-blocking': false,
    'business-partner.focus': false,
    'business-partner.ad-blocking': false,
    'business-partner.checked-marked-for-deletion': false,
    'business-partner.christmas-card': false,
}

export default BUSINESSPARTNERCONFIG;
