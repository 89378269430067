
export interface CountryCode {
  name_en: string;
  name_de: string;
  code: string;
}

export const CountryCodeValues = [
  {
    name_de: 'China',
    name_en: 'China',
    code: 'CN'
  },
  {
    name_de: 'Brasilien',
    name_en: 'Brasil',
    code: 'BR'
  },
  {
    name_de: 'Brasilien (Sao Paolo)',
    name_en: 'Brasil (Sao Paolo)',
    code: 'BR/SP'
  },
  {
    name_de: 'Indien',
    name_en: 'India',
    code: 'IND'
  },
  {
    name_de: 'Hongkong',
    name_en: 'Hongkong',
    code: 'HK'
  },
  {
    name_de: 'Deutschland',
    name_en: 'Germany',
    code: 'DE'
  },
  {
    name_de: 'Deutschland (Brandenburg)',
    name_en: 'Germany (Brandenburg)',
    code: 'DE/BB'
  },
  {
    name_de: 'Deutschland (Berlin)',
    name_en: 'Germany (Berlin)',
    code: 'DE/BE'
  },
  {
    name_de: 'Deutschland (Baden-Württemberg)',
    name_en: 'Germany (Baden-Württemberg)',
    code: 'DE/BW'
  },
  {
    name_de: 'Deutschland (Bayern)',
    name_en: 'Germany (Bayern)',
    code: 'DE/BY'
  },
  {
    name_de: 'Deutschland (Bayern Stadt Augsburg)',
    name_en: 'Germany (Bayern City Augsburg)',
    code: 'DE/BY/A'
  },
  {
    name_de: 'Deutschland (Bayern EVANG)',
    name_en: 'Germany (Bayern EVANG)',
    code: 'DE/BY/EVANG'
  },
  {
    name_de: 'Deutschland (Bremen)',
    name_en: 'Germany (Bremen)',
    code: 'DE/HB'
  },
  {
    name_de: 'Deutschland (Hessen)',
    name_en: 'Germany (Hessen)',
    code: 'DE/HE'
  },
  {
    name_de: 'Deutschland (Hamburg)',
    name_en: 'Germany (Hamburg)',
    code: 'DE/HH'
  },
  {
    name_de: 'Deutschland (Mecklenburg Vorpommern)',
    name_en: 'Germany (Mecklenburg Vorpommern)',
    code: 'DE/MV'
  },
  {
    name_de: 'Deutschland (Niedersachsen)',
    name_en: 'Germany (Niedersachsen)',
    code: 'DE/NI'
  },
  {
    name_de: 'Deutschland (Nordrhein-Westfalen)',
    name_en: 'Germany (Nordrhein-Westfalen)',
    code: 'DE/NW'
  },
  {
    name_de: 'Deutschland (Rheinland-Pfalz)',
    name_en: 'Germany (Rheinland-Pfalz)',
    code: 'DE/RP'
  },
  {
    name_de: 'Deutschland (Schleswig-Holstein)',
    name_en: 'Germany (Schleswig-Holstein)',
    code: 'DE/SH'
  },
  {
    name_de: 'Deutschland (Saarland)',
    name_en: 'Germany (Saarland)',
    code: 'DE/SL'
  },
  {
    name_de: 'Deutschland (Sachsen)',
    name_en: 'Germany (Sachsen)',
    code: 'DE/SN'
  },
  {
    name_de: 'Deutschland (Sachsen Bautzen)',
    name_en: 'Germany (Sachsen Bautzen)',
    code: 'DE/SN/BZ'
  },
  {
    name_de: 'Deutschland (Sachsen-Anhalt)',
    name_en: 'Germany (Sachsen-Anhalt)',
    code: 'DE/ST'
  },
  {
    name_de: 'Österreich',
    name_en: 'Austria',
    code: 'AT'
  },
  {
    name_de: 'Österreich (Burgenland)',
    name_en: 'Austria (Burgenland)',
    code: 'AT/1'
  },
  {
    name_de: 'Österreich (Kärnten)',
    name_en: 'Austria (Carinthia)',
    code: 'AT/2'
  },
  {
    name_de: 'Österreich (Niederösterreich)',
    name_en: 'Austria (Lower Austria)',
    code: 'AT/3'
  },
  {
    name_de: 'Österreich (Oberösterreich)',
    name_en: 'Austria (Upper Austria)',
    code: 'AT/4'
  },
  {
    name_de: 'Österreich (Land Salzburg)',
    name_en: 'Austria (Region Salzburg)',
    code: 'AT/5'
  },
  {
    name_de: 'Österreich (Steiermark)',
    name_en: 'Austria (Steiermark)',
    code: 'AT/6'
  },
  {
    name_de: 'Österreich (Tirol)',
    name_en: 'Austria (Tirol)',
    code: 'AT/7'
  },
  {
    name_de: 'Österreich (Vorarlberg)',
    name_en: 'Austria (Vorarlberg)',
    code: 'AT/8'
  },
  {
    name_de: 'Österreich (Wien)',
    name_en: 'Austria (Wien)',
    code: 'AT/9'
  },
  {
    name_de: 'Belgien',
    name_en: 'Belgium',
    code: 'BE'
  },
  {
    name_de: 'Bulgarien',
    name_en: 'Bulgaria',
    code: 'BG'
  },
  {
    name_de: 'Schweiz',
    name_en: 'Switzerland',
    code: 'CH'
  },
  {
    name_de: 'Tschechische Republik',
    name_en: 'Czech Republic',
    code: 'CZ'
  },
  {
    name_de: 'Dänemark',
    name_en: 'Denmark',
    code: 'DK'
  },
  {
    name_de: 'Spanien',
    name_en: 'Spain',
    code: 'ES'
  },
  {
    name_de: 'Frankreich',
    name_en: 'France',
    code: 'FR'
  },
  {
    name_de: 'Vereinigtes Königreich',
    name_en: 'United Kingdom',
    code: 'GB'
  },
  {
    name_de: 'Irland',
    name_en: 'Ireland',
    code: 'IE'
  },
  {
    name_de: 'Südkorea',
    name_en: 'South Korea',
    code: 'KR'
  },
  {
    name_de: 'Lichtenstein',
    name_en: 'Lichtenstein',
    code: 'LI'
  },
  {
    name_de: 'Niederlande',
    name_en: 'Netherlands',
    code: 'NL'
  },
  {
    name_de: 'Neuseeland',
    name_en: 'New Zealand',
    code: 'NZ'
  },
  {
    name_de: 'Polen',
    name_en: 'Poland',
    code: 'PL'
  },
  {
    name_de: 'Pakistan',
    name_en: 'Pakistan',
    code: 'PAK'
  },
  {
    name_de: 'Portugal',
    name_en: 'Portugal',
    code: 'PT'
  },
  {
    name_de: 'Slowenien',
    name_en: 'Slovenia',
    code: 'SI'
  },
  {
    name_de: 'USA',
    name_en: 'USA',
    code: 'US'
  },
  {
    name_de: 'USA (Alabama)',
    name_en: 'USA (Alabama)',
    code: 'US/AL'
  },
  {
    name_de: 'USA (Alaska)',
    name_en: 'USA (Alaska)',
    code: 'US/AK'
  },
  {
    name_de: 'USA (Arizona)',
    name_en: 'USA (Arizona)',
    code: 'US/AZ'
  },
  {
    name_de: 'USA (Arkansas)',
    name_en: 'USA (Arkansas)',
    code: 'US/AR'
  },
  {
    name_de: 'USA (California)',
    name_en: 'USA (California)',
    code: 'US/CA'
  },
  {
    name_de: 'USA (California LA)',
    name_en: 'USA (California LA)',
    code: 'US/CA/LA'
  },
  {
    name_de: 'USA (Colorado)',
    name_en: 'USA (Colorado)',
    code: 'US/CO'
  },
  {
    name_de: 'USA (Connecticut)',
    name_en: 'USA (Connecticut)',
    code: 'US/CT'
  },
  {
    name_de: 'USA (Delaware)',
    name_en: 'USA (Delaware)',
    code: 'US/DE'
  },
  {
    name_de: 'USA (District of Columbia)',
    name_en: 'USA (District of Columbia)',
    code: 'US/DC'
  },
  {
    name_de: 'USA (Florida)',
    name_en: 'USA (Florida)',
    code: 'US/FL'
  },
  {
    name_de: 'USA (Georgia)',
    name_en: 'USA (Georgia)',
    code: 'US/GA'
  },
  {
    name_de: 'USA (Hawaii)',
    name_en: 'USA (Hawaii)',
    code: 'US/HI'
  },
  {
    name_de: 'USA (Idaho)',
    name_en: 'USA (Idaho)',
    code: 'US/ID'
  },
  {
    name_de: 'USA (Illinois)',
    name_en: 'USA (Illinois)',
    code: 'US/IL'
  },
  {
    name_de: 'USA (Indiana)',
    name_en: 'USA (Indiana)',
    code: 'US/IN'
  },
  {
    name_de: 'USA (Iowa)',
    name_en: 'USA (Iowa)',
    code: 'US/IA'
  },
  {
    name_de: 'USA (Kansas)',
    name_en: 'USA (Kansas)',
    code: 'US/KS'
  },
  {
    name_de: 'USA (Kentucky)',
    name_en: 'USA (Kentucky)',
    code: 'US/KY'
  },
  {
    name_de: 'USA (Louisiana)',
    name_en: 'USA (Louisiana)',
    code: 'US/LA'
  },
  {
    name_de: 'USA (Louisiana NO)',
    name_en: 'USA (Louisiana NO)',
    code: 'US/LA/NO'
  },
  {
    name_de: 'USA (Maine)',
    name_en: 'USA (Maine)',
    code: 'US/ME'
  },
  {
    name_de: 'USA (Maryland)',
    name_en: 'USA (Maryland)',
    code: 'US/MD'
  },
  {
    name_de: 'USA (Massachusetts)',
    name_en: 'USA (Massachusetts)',
    code: 'US/MA'
  },
  {
    name_de: 'USA (Michigan)',
    name_en: 'USA (Michigan)',
    code: 'US/MI'
  },
  {
    name_de: 'USA (Minnesota)',
    name_en: 'USA (Minnesota)',
    code: 'US/MN'
  },
  {
    name_de: 'USA (Mississippi)',
    name_en: 'USA (Mississippi)',
    code: 'US/MS'
  },
  {
    name_de: 'USA (Missouri)',
    name_en: 'USA (Missouri)',
    code: 'US/MO'
  },
  {
    name_de: 'USA (Montana)',
    name_en: 'USA (Montana)',
    code: 'US/MT'
  },
  {
    name_de: 'USA (Nebraska)',
    name_en: 'USA (Nebraska)',
    code: 'US/NE'
  },
  {
    name_de: 'USA (Nevada)',
    name_en: 'USA (Nevada)',
    code: 'US/NV'
  },
  {
    name_de: 'USA (New Hampshire)',
    name_en: 'USA (New Hampshire)',
    code: 'US/NH'
  },
  {
    name_de: 'USA (New Jersey)',
    name_en: 'USA (New Jersey)',
    code: 'US/NJ'
  },
  {
    name_de: 'USA (New Mexico)',
    name_en: 'USA (New Mexico)',
    code: 'US/NM'
  },
  {
    name_de: 'USA (New York)',
    name_en: 'USA (New York)',
    code: 'US/NY'
  },
  {
    name_de: 'USA (North Carolina)',
    name_en: 'USA (North Carolina)',
    code: 'US/NC'
  },
  {
    name_de: 'USA (North Dakota)',
    name_en: 'USA (North Dakota)',
    code: 'US/ND'
  },
  {
    name_de: 'USA (Ohio)',
    name_en: 'USA (Ohio)',
    code: 'US/OH'
  },
  {
    name_de: 'USA (Oklahoma)',
    name_en: 'USA (Oklahoma)',
    code: 'US/OK'
  },
  {
    name_de: 'USA (Oregon)',
    name_en: 'USA (Oregon)',
    code: 'US/OR'
  },
  {
    name_de: 'USA (Pennsylvania)',
    name_en: 'USA (Pennsylvania)',
    code: 'US/PA'
  },
  {
    name_de: 'USA (Rhode Island)',
    name_en: 'USA (Rhode Island)',
    code: 'US/RI'
  },
  {
    name_de: 'USA (South Carolina)',
    name_en: 'USA (South Carolina)',
    code: 'US/SC'
  },
  {
    name_de: 'USA (South Dakota)',
    name_en: 'USA (South Dakota)',
    code: 'US/SD'
  },
  {
    name_de: 'USA (Tennessee)',
    name_en: 'USA (Tennessee)',
    code: 'US/TN'
  },
  {
    name_de: 'USA (Texas)',
    name_en: 'USA (Texas)',
    code: 'US/TX'
  },
  {
    name_de: 'USA (Utah)',
    name_en: 'USA (Utah)',
    code: 'US/UT'
  },
  {
    name_de: 'USA (Vermont)',
    name_en: 'USA (Vermont)',
    code: 'US/VT'
  },
  {
    name_de: 'USA (Virginia)',
    name_en: 'USA (Virginia)',
    code: 'US/VA'
  },
  {
    name_de: 'USA (Washington)',
    name_en: 'USA (Washington)',
    code: 'US/WA'
  },
  {
    name_de: 'USA (West Virginia)',
    name_en: 'USA (West Virginia)',
    code: 'US/WV'
  },
  {
    name_de: 'USA (Wisconsin)',
    name_en: 'USA (Wisconsin)',
    code: 'US/WI'
  },
  {
    name_de: 'USA (Wyoming)',
    name_en: 'USA (Wyoming)',
    code: 'US/WY'
  },
  {
    name_de: 'Mexiko',
    name_en: 'Mexico',
    code: 'MX'
  },
  {
    name_de: 'Saudi Arabien',
    name_en: 'Saudi Arabia',
    code: 'AE'
  },
  {
    name_de: 'Ägypten',
    name_en: 'Egypt',
    code: 'EG'
  }
];
