import {
  HttpBackend,
  HttpHandler,
  HTTP_INTERCEPTORS,
  HttpInterceptor,
  HttpRequest, HttpEvent
} from "@angular/common/http";
import { ImpersonationInterceptor } from './impersonation.interceptor';
import { Injector } from '@angular/core';
import { Observable } from "rxjs";

/**
 * `HttpHandler` which applies an `HttpInterceptor` to an `HttpRequest`.
 */
class HttpInterceptorHandler implements HttpHandler {
  constructor(
    private readonly next: HttpHandler,
    private readonly interceptor: HttpInterceptor
  ) {}

  handle(req: HttpRequest<unknown>) {
    return this.interceptor.intercept(req, this.next);
  }
}

export class ImpersonationHttpHandler implements HttpHandler {
  private chain: HttpHandler | null = null;

  constructor(
    private readonly backend: HttpBackend,
    private readonly injector: Injector
  ) {}

  handle(req: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    const chain = this.chain ?? this.buildChain()
    return chain.handle(req);
  }

  private buildChain(): HttpHandler {
    const defaultInterceptors = this.injector.get<HttpInterceptor[]>(
      HTTP_INTERCEPTORS,
      []
    );
    const impersonationInterceptor = this.injector.get<HttpInterceptor>(
      ImpersonationInterceptor
    );

    const interceptors = [...defaultInterceptors, impersonationInterceptor];

    this.chain = interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor),
      this.backend
    );
    return this.chain;
  }
}
