import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ProjectCountPercentageChartDataPoint } from '@ceres/domain';
import { debounce } from 'lodash-es';
import { ProjectChartService } from '@app/shared/services/project-chart.service';
import { FilterService } from "@ceres/filter";

@Component({
  selector: 'ceres-project-chart-order-billing-entry',
  templateUrl: './project-chart-order-billing-entry.component.html',
  styleUrls: ['./project-chart-order-billing-entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectChartOrderBillingEntryComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() filterService: FilterService;
  @Input() viewDividerValue: number = 1.125;
  view: any[] = [];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = 'Value';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  orderEntryVsBillingEntry: ProjectCountPercentageChartDataPoint[];

  constructor(
    public readonly projectChartService: ProjectChartService
    //readonly filterService: ProjectFilterService
  ) {}

  ngOnInit() {
    //TODO standalone filter options for widget
    //const initialFiscalYear = this.projectChartService.allFiscalYears[1];
    //this.filterService.applyFiscalYear(initialFiscalYear);

    // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
    document.querySelector('body').classList.add('new-wrapper');

    this.subscribeToAppliedFilters();
    this.subscribeToChangedData();
    this.view = [window.innerWidth / this.viewDividerValue, 400];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
    document.querySelector('body').classList.remove('new-wrapper');
  }

  ngAfterViewInit() {
    this.onResize = debounce(this.onResize, 150, {
      leading: false,
      trailing: true
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = [event.target.innerWidth / this.viewDividerValue, 400];
  }

  private subscribeToAppliedFilters() {
    this.filterService.appliedFilters$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100)
      )
      .subscribe(filters => {
        console.log('FILTER');
        console.log(filters);
        this.projectChartService.appliedFilters = filters;
        this.projectChartService.getOrderEntryVsBilling();
      });
  }

  private subscribeToChangedData() {
    this.projectChartService.orderVsBilledEntry$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100)
      )
      .subscribe(data => {
        this.orderEntryVsBillingEntry = data;
      });
  }
}
