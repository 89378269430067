import { NgModule } from '@angular/core';
import { HeaderCellFilterComponent } from './components/header-cell-filter.component';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { DateFilterComponent } from './components/filter-types/date-filter/date-filter.component';
import { MultiSelectFilterComponent } from './components/filter-types/multi-select-filter/multi-select-filter.component';
import { SingleSelectFilterComponent } from './components/filter-types/single-select-filter/single-select-filter.component';
import { RangeFilterComponent } from './components/filter-types/range-filter/range-filter.component';
import { SearchFilterComponent } from './components/filter-types/search-filter/search-filter.component';
import { FilterComponent } from './components/filter.component';
import { StandaloneFilterComponent } from './components/standalone-filter/standalone-filter.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDatepickerModule,
    MatTooltipModule,
    ScrollingModule,
    TranslocoModule,
    MatIconModule
  ],
  exports: [
    HeaderCellFilterComponent,
    FilterComponent,
    StandaloneFilterComponent,
    StandaloneFilterComponent
  ],
  declarations: [
    HeaderCellFilterComponent,
    FilterComponent,
    DateFilterComponent,
    MultiSelectFilterComponent,
    SingleSelectFilterComponent,
    RangeFilterComponent,
    SearchFilterComponent,
    StandaloneFilterComponent
  ]
})
export class FilterModule {
}
