import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TRANSLOCO_MISSING_HANDLER
} from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { CustomTranslateLoader } from './shared/services/custom-translate-loader';
import { MyMissingTranslationHandler } from './shared/handler/missingtranslation.handler';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'de'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: CustomTranslateLoader,
      deps: [HttpClient]
    },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: MyMissingTranslationHandler
    }
  ]
})
export class TranslocoRootModule {}
