import {
  BusinessPartner as CeresBusinessPartner,
  Employee,
  TranslationOption
} from '@ceres/domain';

export class BusinessPartner implements CeresBusinessPartner {
  id: number;
  gpType: TranslationOption;
  gid: string;
  department: string;
  salutation: string;
  firstName: string;
  surName: string;
  fullName: string;
  subjectArea: TranslationOption;
  function: TranslationOption;
  budgetResponsibility: TranslationOption;
  customerContactId: Employee[] | { results: [number] };
  customerContact: Employee[];
  mainCustomerContact: Employee;
  mainCustomerContactId: number;
  personalRelation: string;
  phone: string;
  fax: string;
  mobile: string;
  eMail: string;
  location: string;
  street: string;
  zip: string;
  countryCode: string;
  language: TranslationOption;
  status: TranslationOption;
  newsletterCandidate: boolean;
  advertisingBarrier: boolean;
  newsletterBarrier: boolean;
  christmasCard: boolean;
  deleteBookmarked: boolean;
  inFocus: boolean;
  companyNames: { name: string }[];
  are: string;
  costCenter: string;
  orgId: string;
  title: string;
  city: string;
  country: string;
  poBox: string;
  lastUpdateBySCD: Date;

  constructor(object?: Partial<BusinessPartner>) {
    if (object) {
      Object.assign(this, object);
    }
  }
}
