<div class="mat-calendar-header">
  <!-- Fiscal Year Selection -->
  <div class="mat-calendar-controls">
    <button
      mat-icon-button
      type="button"
      [disabled]="isFirstIndex"
      (click)="setPreviousFiscalYear()"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <mat-select
      class="pwo-select selector fy-select"
      [formControl]="fiscalYearCtrl"
    >
      <mat-option *ngFor="let fiscalYear of fiscalYears" [value]="fiscalYear">
        {{
          ('shared.calendar.abbreviations.fiscal-year' | transloco) +
            ' ' +
            fiscalYear.label
        }}</mat-option
      >
    </mat-select>
    <button
      mat-icon-button
      type="button"
      [disabled]="isLastIndex"
      (click)="setNextFiscalYear()"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <!-- Navigation by mopnth -->
  <div class="mat-calendar-controls">
    <button
      mat-icon-button
      type="button"
      [disabled]="isFirstPossibleMonth"
      (click)="setPreviousMonth()"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="mat-calendar-spacer header-label">
      {{ periodLabel }}
    </div>
    <button
      mat-icon-button
      type="button"
      [disabled]="isLastPossibleMonth"
      (click)="setNextMonth()"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
