import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as F from '../../lib/filters';
import { Filter } from '../../lib/filters';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Input() filter: Filter;
  @Input() filterService: Filter;
  @Input() isTranslationKey: boolean;
  @Input() translationPrefix: string;

  @Output() close = new EventEmitter();

  filterTypes = F;

  is(filter: Filter, filterType: any) {
    return filter instanceof filterType;
  }
}
