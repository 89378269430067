import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'translateOrder'
})
export class TranslateOrderPipe implements PipeTransform {
  constructor(private translate: TranslocoService) {}

  transform(keys: any[]): any[]  {
    if (!Array.isArray(keys)) {
      return keys;
    }

    return keys.sort((a,b) => {
        if(this.translate.translate(a.translationKey) < this.translate.translate(b.translationKey)) { return -1; }
        if(this.translate.translate(a.translationKey) > this.translate.translate(b.translationKey)) { return 1; }
        return 0;
    })
  }
}
