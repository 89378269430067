<div class="search-container" [class.opened]="results">
  <div class="search-input">
    <input #searchInput [formControl]="searchControl" (blur)="blur()" autofocus
      placeholder="{{ 'shared.miscellaneous.search-dots' | transloco }}" />
  </div>
  <span class="icon-container" (click)="clear()">
    <i class="head-nav-icon icon a-icon a-icon--close"></i>
  </span>
</div>
<div (clickOutside)="closeSearchField()" *ngIf="results || isLoading" class="search-results-container">
  <div class="grid" *ngIf="!isLoading">
    <div *ngFor="let category of categories" class="col">
      <p class="title">{{ category.name | transloco }}</p>
      <a class="item" *ngFor="let res of results[category.name]" (click)="onNavigate()"
        routerLink="/{{ category.key }}/details/{{ res.id }}">
        <span class="main">{{ res.title }}</span>
        <span class="sub">{{ res.subtitle }}</span>
      </a>
      <p *ngIf="!results[category.name]?.length">{{'shared.miscellaneous.no-matches' | transloco}}</p>
    </div>
  </div>
  <div *ngIf="isLoading" class="spinner flex-center">
    <mat-spinner class="main-spinner" diameter="200" mode="indeterminate"></mat-spinner>
  </div>
</div>
