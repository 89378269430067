import { Location as CeresLocation } from '@ceres/domain';

export class Location implements CeresLocation {

    id = -1;
    title = '';
    translationKey = '';
    rankOrder = 1000;
    abbreviation = '';
    countryCode = '';

    constructor(object?: Partial<Location>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
