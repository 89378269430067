import { Injectable } from '@angular/core';
import { BusinessArea, Contract, ContractStatus, Portfolio, Task } from '@ceres/domain';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ImpersonatedHttpClient } from "@ceres/shared/services";
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  public tasks: BehaviorSubject<Task[]> = new BehaviorSubject([]);

  constructor(
    private readonly httpClient: ImpersonatedHttpClient,
    private translate: TranslocoService,
  ) {}

  public async getTasks(employeeId: number): Promise<Task[]> {
    return await this.httpClient
      .get<Task[]>(`${environment.edgeService}/tasks/employees/${employeeId}`)
      .toPromise();
  }

  public async getMyTasks(employeeId: number): Promise<Task[]> {
    let tasks: Task[] = [];

    if(employeeId) {
      tasks = await this.httpClient
        .get<Task[]>(`${environment.edgeService}/tasks/employees/${employeeId}`)
        .toPromise();
    }

    return tasks;
  }

  public async getMyTasksWithStatus(employeeId: number, status: string): Promise<Task[]> {
    let tasks: Task[] = [];

    if(employeeId) {
       tasks = await this.httpClient
        .get<Task[]>(`${environment.edgeService}/tasks/employees/${employeeId}`, {
          params: { status }
        })
        .toPromise();
      if (status === 'open') {
        this.tasks.next(tasks);
      }
    }

    return tasks;
  }

  public async uploadTask(taskData: Partial<Task>) {
    const tasks = await this.httpClient
      .post<Task>(`${environment.edgeService}/tasks`, taskData)
      .toPromise();
    return tasks;
  }

  public async uploadMultipleTasks(tasks: Task[]) {
    const result = await this.httpClient
      .post<Task>(`${environment.edgeService}/tasks`, tasks)
      .toPromise();
    return result;
  }

  public async updateMyTask(item: Task) {
    const result = await this.httpClient
      .patch<Task>(`${environment.edgeService}/tasks/${item.id}`, item)
      .toPromise();
    return result;
  }

  public async createTask(contract: Contract, portfolios: Portfolio[]) {
    const status = contract.contractStatus as ContractStatus;
    const businessArea = contract.businessArea as BusinessArea;
    const task: Partial<Task> = {
      itemId: contract.id,
      description: `Status of contract '${contract.title}' changed to '${this.translate.translate(status.translationKey)}'`,
      title: `Status change for contract '${contract.title}'`,
      dueDate: new Date(),
      module: 'Contracts',
      status: 'open',
      portfolio: portfolios.find(e => e.abbreviation === businessArea.title)
    };

    if (status.rankOrder === 1) {
      task.action = 'Check';
      task.employees = [contract.serviceProvider];
    } else if (status.rankOrder === 2) {
      task.action = 'Review';
      task.employees = [businessArea.manager];
    } else if (status.rankOrder === 3) {
      task.action = 'Sign';
      task.employees = [businessArea.signatureTech];
    } else if (status.rankOrder === 4) {
      task.action = 'Sign';
      task.employees = [businessArea.signatureCommercial];
    } else {
      return;
    }

    if (task.employees.length) {
      await this.uploadTask(task);
    }
  }
}
