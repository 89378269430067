import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BarCellComponent } from "./bar-cell.component";

@NgModule({
  imports: [CommonModule],
  declarations: [BarCellComponent],
  exports: [BarCellComponent]
})
export class BarCellModule {}
