import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Activity } from '@ceres/domain';
import { ImpersonatedHttpClient } from "@ceres/shared/services";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(private http: ImpersonatedHttpClient) {}

  public getAll() {
    return this.http
      .get<Activity[]>(environment.edgeService + '/activities')
      .toPromise();
  }

  public getActive(): Observable<Activity[]> {
    return this.http.get<Activity[]>(
      environment.edgeService + '/activities?inactive=false'
    );
  }

  create(activity: Activity) {
    return this.http
      .post<Activity>(environment.edgeService + '/activities', activity)
      .toPromise();
  }

  update(activity: Activity) {
    return this.http
      .put<Activity>(
        `${environment.edgeService}/activities/${activity.id}`,
        activity
      )
      .toPromise();
  }

  updateAll(activities: Activity[]): Observable<unknown> {
    return this.http
      .put(`${environment.edgeService}/activities/all`, activities)
  }

  delete(activity: Activity) {
    return this.http
      .delete(`${environment.edgeService}/activities/${activity.id}`)
      .toPromise();
  }
}
