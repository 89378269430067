import { FormService } from './form.service';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';

interface BeforeUnload {
  formService: FormService;
  formGroup: FormGroup;

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DeactivationPermission implements CanDeactivate<BeforeUnload> {
  constructor(private router: Router) {
  }

  canDeactivate(
    component: BeforeUnload,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state && navigation.extras.state['saved']) return true;

    if (component && component.formService) {
      return component.formService.formIsDirty(component);
    }
    return component.canDeactivate();
  }
}
