import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { SCDUser } from './scd-user';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { ImpersonatedHttpClient } from '../http';

//TODO id1: remove manual httpOptions if possible or adjust TokenInjector interceptor

@Injectable({
  providedIn: 'root'
})
export class SCDService {
  constructor(private http: ImpersonatedHttpClient,
              @Inject(ENVIRONMENT) private readonly environment: { edgeService: string, scd_url: string }) { }

  public searchUser(
    firstname: string,
    surname: string,
    email: string
  ): Observable<SCDUser[]> {
    let params = new HttpParams();
    if (firstname) {
      params = params.append('firstName', `${firstname.trim().toLowerCase()}*`);
    }
    if (surname) {
      params = params.append('surName', `${surname.trim().toLowerCase()}*`);
    }
    if (email) {
      params = params.append('mail', `${email.trim().toLowerCase()}*`);
    }

    return this.http
      .get<{ records: SCDUser[] }>(`${this.environment.edgeService}/scd-user-directory`, { params })
      .pipe(
        map(result => result.records || []),
        catchError(() => of([]))
      );
  }

  public searchByGID(users: SCDUser[]): Observable<SCDUser[]> {
    return from(users).pipe(
      mergeMap(user =>
        this.getByGID(user.gid).pipe(
          map(result => result.records || []),
          mergeMap(records => of(...records))
        )
      ),
      toArray(),
      catchError(() => of([]))
    );
  }

  getByGID(gid: string): Observable<{ records: SCDUser[] }> {
    let params = new HttpParams();
    params = params.append('gid', `${gid}*`);

    return this.http.get<{ records: SCDUser[] }>(`${this.environment.edgeService}/scd-user-directory`, {
      params
    });
  }

  async getByLocation(location: string) {
    return this.http.get<any>(`${this.environment.edgeService}/scd-user-directory/locations/${location}`).toPromise();
  }
}
