import { ProgramFavorite as CeresProgramFavorite, Program, Employee, Workload } from '@ceres/domain';

export class ProgramFavorite implements CeresProgramFavorite {

    id: number;   
    program: Program;
    employee: Employee;
    sortOrder: number;
    businessPartner: string;
    isFavorite: boolean;
    workload: Workload[];

    constructor(object?: Partial<ProgramFavorite>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}