import { Component, Input } from '@angular/core';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from "@angular/material/legacy-progress-spinner";

@Component({
  selector: 'ceres-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})

export class LoadingComponent {
  @Input() message!: string;
  @Input() minimumHeight = false;
  @Input() overrideSpace = false;
  @Input() insideButton = false;
  @Input() diameterSize!: number;
  @Input() insideTable = false;
  @Input() noMessage = false;

  spinnerMode: ProgressSpinnerMode = 'indeterminate';

}
