import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timer',
  pure: false
})
export class TimerPipe implements PipeTransform {
  transform(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${
      minutesLeft < 10 ? '0' : ''
    }${minutesLeft}`;
  }
}
