import { Day as CeresDay, days, daysEN, Holiday } from '@ceres/domain';
import { TranslocoService } from '@ngneat/transloco';
import { format } from 'date-fns';

//TODO future: needs to be refactored and typesafe

export class Day implements CeresDay {
  // @ts-ignore
  totalSum: number;
  // @ts-ignore
  filteredSum: number;
  // @ts-ignore
  value: number;
  // @ts-ignore
  day: number;
  // @ts-ignore
  month: number;
  // @ts-ignore
  year: number;
  // @ts-ignore
  date: Date;
  // @ts-ignore
  weekNo: number;
  // @ts-ignore
  isHoliday: boolean;
  // @ts-ignore
  holiday: Holiday;
  // @ts-ignore
  placeholder: string;
  sum: any;

  constructor(public translate: TranslocoService) {}

  toFormatString(): string {
    return format(this.date, 'dd.MM.yyyy');
  }

  abbr?(): string {
    return this.translate.getActiveLang() === 'en'
      ? daysEN[this.value]
      : days[this.value];
  }
}
