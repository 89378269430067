<div
  class="row dialog-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="col-11 left">
    <h4>
      {{ 'shared.dialogs.import-title' | transloco }}
    </h4>
  </div>
  <div class="col-1 right">
    <button (click)="cancel()">
      <i class="a-icon a-icon--close"></i>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12 mb-2">
    <div class="options-form">
      <ceres-upload-file
        [uploads]="uploads"
        [(dndFiles)]="dndFiles"
        (dndFilesChange)="dndFilesChange($event)"
        [uploading]="processing"
        [multipleUpload]="false"
        [validFileTypes]="allowedFileTypes"
      ></ceres-upload-file>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="d-flex gap-10">
      <ceres-button (click)="confirm()" [icon]="'check-mark'">
        {{ 'shared.buttons.upload' | transloco }}
      </ceres-button>
      <ceres-button (click)="cancel()" [icon]="'close'" [style]="'red'">
        {{ 'shared.buttons.cancel' | transloco }}
      </ceres-button>
    </div>
  </div>
</div>
