import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FilterService, SearchFilter } from './../../../../lib';

@Component({
  selector: 'lib-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFilterComponent implements OnInit {

  @Input() filter: SearchFilter;
  @Input() filterService: FilterService;

  @Output() close = new EventEmitter();

  searchTerm = '';

  ngOnInit() {
    this.searchTerm = this.filter.term;
  }

  apply() {
    this.filter.term = this.searchTerm;
    this.filterService.applyFilter(this.filter);
    this.close.next();
  }

}
