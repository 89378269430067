import { Injectable } from '@angular/core';
import {
  CustomFilter,
  PersistentFilter,
  PersonalSettings
} from '@app/myceres/components/settingsv2/custom-filter/custom-filter';
import { SettingsMetadata } from '@ceres/domain';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImpersonatedHttpClient } from '../http';
import { environment } from '../../../../../../apps/ceres-frontend/src/environments/environment';
import { MessageService } from '../message';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSettingsService {
  public isLoading = false;

  private _settingsMetadata: BehaviorSubject<SettingsMetadata> =
    new BehaviorSubject<SettingsMetadata>(null);
  public settingsMetadata$: Observable<SettingsMetadata> =
    this._settingsMetadata.asObservable();

  private _persistentFilter: BehaviorSubject<PersistentFilter> =
    new BehaviorSubject<PersistentFilter>(null);
  public persistentFilter$: Observable<PersistentFilter> =
    this._persistentFilter.asObservable();

  constructor(
    private readonly httpClient: ImpersonatedHttpClient,
    private readonly messageService: MessageService,
    private readonly translateService: TranslocoService
  ) {}

  public getAllCustomFilters(gid: string) {
    return this.httpClient.get<CustomFilter[]>(
      `${environment.edgeService}/settings/filter/${gid}`
    );
  }

  public getCustomFilterBySection(gid: string, filterSection: string) {
    return this.httpClient.get<CustomFilter[]>(
      `${environment.edgeService}/settings/filter/${gid}/${filterSection}`
    );
  }

  public updateCustomFilter(gidCurrentUser: string, filter: any) {
    return this.httpClient.put(
      `${environment.edgeService}/settings/filter/${gidCurrentUser}`,
      filter
    );
  }

  public deleteCustomFilter(filterId: string, gid: string) {
    return this.httpClient.delete(
      `${environment.edgeService}/settings/filter/${filterId}`
    );
  }

  public createCustomFilter(gid: string, filter: CustomFilter) {
    return this.httpClient.post<CustomFilter>(
      `${environment.edgeService}/settings/filter/${gid}`,
      filter
    );
  }

  public getPersonalSettings(gid: string) {
    return this.httpClient.get<PersonalSettings>(
      `${environment.edgeService}/settings/personal/${gid}`
    );
  }

  public updatePersonalSettings(
    gidCurrentUser: string,
    settings: PersonalSettings
  ) {
    const { sortKey, gid, ...settingsObject } = settings;
    const item = {
      sortKey: sortKey,
      gid: gidCurrentUser,
      settingsObject: settingsObject
    };
    return this.httpClient.put<PersonalSettings>(
      `${environment.edgeService}/settings/personal/${gidCurrentUser}`,
      item
    );
  }

  public getMetadata() {
    if (this._settingsMetadata.value === null) {
      this.httpClient
        .get<SettingsMetadata>(`${environment.edgeService}/settings/metadata`)
        .subscribe((data) => {
          this._settingsMetadata.next(data);
        });
    }
  }

  public getPersistentFiltersForAppInitialization(gidCurrentUser: string) {
    return this.httpClient
      .get<PersistentFilter>(
        `${environment.edgeService}/settings/persistent-filter/${gidCurrentUser}`
      )
      .pipe(
        tap((data) => {
          this._persistentFilter.next(data);
        })
      );
  }

  public getPersistentFilters(gidCurrentUser: string) {
    this.isLoading = true;
    this.httpClient
      .get<PersistentFilter>(
        `${environment.edgeService}/settings/persistent-filter/${gidCurrentUser}`
      )
      .subscribe((data) => {
        this._persistentFilter.next(data);
        this.isLoading = false;
      });
  }

  public updatePersistentFilters(
    gidCurrentUser: string,
    persistentFilter: PersistentFilter
  ) {
    this.isLoading = true;
    const { sortKey, gid, ...settingsObject } = persistentFilter;
    const item = {
      sortKey: sortKey,
      gid: gidCurrentUser,
      settingsObject: settingsObject
    };
    this.httpClient
      .put<PersistentFilter>(
        `${environment.edgeService}/settings/persistent-filter/${gidCurrentUser}`,
        item
      )
      .subscribe((_) => {
        this.getPersistentFilters(gidCurrentUser);
        this.showMessage(
          'my-ceres.settings.custom-filter.messages.persistent-filter-edited',
          this.translateService.translate(
            'my-ceres.settings.options.persistent-filter'
          ),
          1
        );
      });
  }

  public createPersistentFilter(
    gidCurrentUser: string,
    persistentFilters: PersistentFilter
  ) {
    this.isLoading = true;
    this.httpClient
      .post<PersistentFilter>(
        `${environment.edgeService}/settings/persistent-filter/${gidCurrentUser}`,
        persistentFilters
      )
      .subscribe((_) => {
        this.getPersistentFilters(gidCurrentUser);
        this.showMessage(
          'my-ceres.settings.custom-filter.messages.persistent-filter-created',
          this.translateService.translate(
            'my-ceres.settings.options.persistent-filter'
          ),
          1
        );
      });
  }

  public showMessage(msg: string, title: string, type: number) {
    this.messageService.pushMessage({
      message: msg,
      title: title,
      type
    });
  }
}
