import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FeatureToggleConfig } from '../interfaces/feature-toggle-config.interface';
import { FeatureTogglesService } from '../services/feature-toggles/feature-toggles.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleGuard<T extends FeatureToggleConfig> implements CanActivate {
  constructor(
    private readonly featureToggleService: FeatureTogglesService<T>,
    private readonly router: Router
  ) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const features = next.data['features'];
    if (features && features.length) {
      const [firstFeature, ...otherFeatures] = features;
      return this.featureToggleService.hasFeatures(firstFeature, ...otherFeatures)
        .pipe(map(hasFeatures => hasFeatures || this.router.parseUrl('/')));
    } else {
      return of(this.router.parseUrl('/'));
    }
  }
}
