import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from "./upload-file.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [CommonModule, DragDropModule, MatProgressBarModule, TranslocoModule],
  declarations: [UploadFileComponent],
  exports: [UploadFileComponent]
})
export class UploadFileModule {
}
