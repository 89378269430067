<div class="dialog-header">
  <h3 class="title">{{ data.title }}</h3>
</div>
<mat-dialog-content>
  <div class="info-text" *ngIf="data.infoText">
    {{ data.infoText }}
  </div>
  <mat-form-field class="full-width">
    <mat-label *ngIf="data.options.length < 1">{{
      'shared.select.no-selection' | transloco
    }}</mat-label>
    <mat-label *ngIf="data.options.length > 0">{{
      'shared.select.please-select-placeholder' | transloco
    }}</mat-label>
    <mat-select (valueChange)="chooseOption($event)">
      <mat-option *ngFor="let option of data.options" [value]="option"
        >{{ option[data.nameSelector || 'name'] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <ceres-button (click)="cancel()" [icon]="'close'" [style]="'grey'">{{
    'shared.buttons.cancel' | transloco
  }}</ceres-button>
</mat-dialog-actions>
