<div class="pwo-filter filter-header">
  <div class="input">
    <mat-form-field class="mat-custom w-100 mat-fix-padding" appearance="fill">
      <mat-label>{{ 'shared.miscellaneous.search-dots' | transloco }}</mat-label>
      <input [(ngModel)]="searchTerm"
             (matInput />
    </mat-form-field>
  </div>
</div>

<button (click)="apply()"
        class="a-button a-button--pwo full"
        type="button" mat-button>
  <mat-icon>check</mat-icon>
  {{'shared.buttons.apply' | transloco}}
</button>
