import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats
} from '@angular/material/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import * as moment from 'moment';
import { DateRangePickerComponent } from "../date-range-picker/date-range-picker.component";

@Component({
  selector: 'ceres-date-range-picker-fy-header',
  templateUrl: './date-range-picker-fy-header.component.html',
  styleUrls: ['./date-range-picker-fy-header.component.scss']
})
export class DateRangePickerFyHeaderComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();

  public get currentIndex(): number {
    return this.fiscalYears.indexOf(this.fiscalYearCtrl.value);
  }

  public get isFirstIndex(): boolean {
    return this.currentIndex === 0;
  }

  public get isLastIndex(): boolean {
    return this.currentIndex === this.fiscalYears.length - 1;
  }

  public get isFirstPossibleMonth(): boolean {
    return this.calendar.activeDate.isSame(this.calendar.minDate, 'month');
  }

  public get isLastPossibleMonth(): boolean {
    return this.calendar.activeDate.isSame(this.calendar.maxDate, 'month');
  }

  public get fiscalYearCtrl(): FormControl {
    return this.dateRangePicker.fiscalYearCtrl;
  }

  public get fiscalYears(): {
    date: Date;
    dateEnd: Date;
    label: string;
  }[] {
    return this.dateRangePicker.fiscalYears;
  }

  get periodLabel() {
    return this.dateAdapter
      .format(
        this.calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  public constructor(
    public readonly cdr: ChangeDetectorRef,
    private readonly calendar: MatCalendar<moment.Moment>,
    private readonly dateAdapter: DateAdapter<moment.Moment>,
    private readonly dateRangePicker: DateRangePickerComponent<moment.Moment>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats
  ) {
    this.subscriptions.add(
      this.calendar.stateChanges.subscribe({
        next: () => this.cdr.markForCheck()
      })
    );

    this.subscriptions.add(
      this.fiscalYearCtrl.valueChanges
        .pipe(
          filter((fiscalYear) => !!fiscalYear),
          distinctUntilChanged()
        )
        .subscribe({
          next: ({ date, dateEnd }) => {
            this.updateCalendar({ date, dateEnd });
            this.selectMinAndMaxRange({ date, dateEnd });
          }
        })
    );

    this.updateCalendar(this.fiscalYearCtrl.value);
  }

  public setNextFiscalYear(): void {
    if (this.isLastIndex) {
      throw new Error('There is further fiscal year selectable');
    }

    this.fiscalYearCtrl.setValue(this.fiscalYears[this.currentIndex + 1]);
  }

  public setPreviousFiscalYear(): void {
    if (this.isFirstIndex) {
      throw new Error('There is no previous fiscal year selectable');
    }

    this.fiscalYearCtrl.setValue(this.fiscalYears[this.currentIndex - 1]);
  }

  public setNextMonth(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
      this.calendar.activeDate,
      1
    );
  }

  public setPreviousMonth(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
      this.calendar.activeDate,
      -1
    );
  }

  private updateCalendar({
    date,
    dateEnd
  }: {
    date: Date;
    dateEnd: Date;
  }): void {
    const minDate = moment(date);
    const maxDate = moment(dateEnd);
    const currentMinDate = this.calendar.minDate || minDate;

    const yearDifference = minDate.year() - currentMinDate.year();

    this.calendar.minDate = minDate;
    this.calendar.maxDate = maxDate;

    setTimeout(() => {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(
        this.calendar.activeDate,
        yearDifference
      );
    }, 0);
  }

  private selectMinAndMaxRange({
    date,
    dateEnd
  }: {
    date: Date;
    dateEnd: Date;
  }): void {
    const minDate = moment(date);
    const maxDate = moment(dateEnd);

    this.dateRangePicker.range.setValue({
      start: minDate,
      end: maxDate
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
