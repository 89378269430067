import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ValuePickerComponent } from "./value-picker.component";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { ButtonModule } from '../button/button.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, MatInputModule, ButtonModule, MatIconModule],
  declarations: [ValuePickerComponent],
  exports: [ValuePickerComponent]
})
export class ValuePickerModule {}
