import { Bop, BOPMetadata, PaginationResponse } from '@ceres/domain';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Filter } from '@ceres/filter';
import { map } from 'rxjs/operators';
import { ImpersonatedHttpClient } from "@ceres/shared/services";

@Injectable({
  providedIn: 'root'
})
export class BopService {
  public columnTitles = [
    'bopNumber',
    'bopTitle',
    'bopLeader.name',
    'businessArea.title',
    'businessPartner.fullName',
    'bopStatus.translationKey',
    'plannedProjectStart',
    'plannedProjectEnd',
    'probabilityClass.probability',
    'totalVolume',
    'expectedValue'
  ];

  private bopMetadata: BOPMetadata = null;

  private cachedBOP: Bop;

  constructor(private httpClient: ImpersonatedHttpClient) {}

  public get bop(): Bop {
    const cached = this.cachedBOP;
    this.cachedBOP = null;
    return cached;
  }

  public set bop(val: Bop) {
    this.cachedBOP = val;
  }

  public getAll(): Promise<Bop[]> {
    return this.httpClient
      .get<PaginationResponse<Bop>>(`${environment.edgeService}/bops`)
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  public getBy(query: { filters: Filter[] }): Promise<Bop[]> {
    let params = new HttpParams();
    if (query.filters) {
      params = params.append('filters', JSON.stringify(query.filters));
    }
    return this.httpClient
      .get<PaginationResponse<Bop>>(`${environment.edgeService}/bops`, {
        params
      })
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  public async getBOPMetadata(): Promise<BOPMetadata> {
    if (!this.bopMetadata) {
      this.bopMetadata = await this.httpClient
        .get<BOPMetadata>(`${environment.edgeService}/bops/metadata`)
        .toPromise();
    }
    return this.bopMetadata;
  }

  public getPossibleBOPNumber() {
    return this.httpClient
      .get<{ bopNumber: string }>(`${environment.edgeService}/bops/number`)
      .toPromise()
      .then((bop) => bop.bopNumber);
  }

  public getByBusinessAreaID(id: number): Promise<Bop[]> {
    return this.httpClient
      .get<Bop[]>(`${environment.edgeService}/bops/business/areas/${id}`)
      .toPromise();
  }

  /*public getByProbabilityClass(
    id: number,
    probability: number
  ): Promise<Bop[]> {
    return this.httpClient
      .get<Bop[]>(`${environment.edgeService}/bops/business/areas/${id}`, {
        params: { probability: probability.toString() }
      })
      .toPromise();
  }*/

  public getById(id: number): Promise<Bop> {
    return this.httpClient
      .get<Bop>(`${environment.edgeService}/bops/${id}`)
      .toPromise();
  }

  public createBop(bop: Bop): Promise<Bop> {
    return this.httpClient
      .post<Bop>(`${environment.edgeService}/bops`, bop)
      .toPromise();
  }

  public updateBop(bop: Bop) {
    return this.httpClient
      .put<Bop>(`${environment.edgeService}/bops/${bop.id}`, bop)
      .toPromise()
      .then((res) => {
        //console.log(res);
      });
  }

  public getDataToExport(data: Bop[], columns: string[]) {
    return data.map((bopData) => {
      const result = {};
      columns.forEach((col) => {
        const title = columns.find(function (element: string) {
          return element === col;
        });
        // mapped Elements
        if (title && title.split('.').length > 1) {
          result[title.split('.')[0]] =
            bopData[title.split('.')[0]][title.split('.')[1]] || '';
        } else {
          result[title] = bopData[col] || '';
        }
      });
      return result;
    });
  }
}
