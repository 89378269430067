import { Week as CeresWeek, daysEN, days } from '@ceres/domain';
import { TranslocoService } from '@ngneat/transloco';
import { Day } from './day';

export class Week {
  number: number;
  first: Day;
  last: Day;
  weekNo: number;

  constructor(public translate: TranslocoService) {}

  public asString?() {
    const firstDayName =
      this.translate.getActiveLang() === 'en'
        ? daysEN[this.first.value]
        : days[this.first.value];
    const lastDayName =
      this.translate.getActiveLang() === 'en'
        ? daysEN[this.last.value]
        : days[this.last.value];
    return this.weekNo
      ? `${firstDayName} ${this.first.day}.${this.first.month} - ${lastDayName} ${this.last.day}.${this.last.month}`
      : `${firstDayName} ${this.first.day} - ${lastDayName} ${this.last.day}`;
  }

  days?() {
    return `${this.first.day}-${this.last.day}`;
  }
}
