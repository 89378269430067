<lib-date-filter
  *ngIf="is(filter, filterTypes.DateFilter)"
  [filter]="filter"
  [filterService]="filterService"
  (close)="close.next()"
>
</lib-date-filter>

<lib-single-select-filter
  *ngIf="is(filter, filterTypes.ValueFilter)"
  [filter]="filter"
  [filterService]="filterService"
  [isTranslationKey]="isTranslationKey"
  [translationPrefix]="translationPrefix"
  (close)="close.next()"
>
</lib-single-select-filter>

<lib-multi-select-filter
  *ngIf="is(filter, filterTypes.MultiValueFilter)"
  [filter]="filter"
  [filterService]="filterService"
  [isTranslationKey]="isTranslationKey"
  [translationPrefix]="translationPrefix"
  (close)="close.next()"
>
</lib-multi-select-filter>

<lib-range-filter
  *ngIf="is(filter, filterTypes.RangeFilter)"
  [filter]="filter"
  [filterService]="filterService"
  (close)="close.next()"
>
</lib-range-filter>

<lib-search-filter
  *ngIf="is(filter, filterTypes.SearchFilter)"
  [filter]="filter"
  [filterService]="filterService"
  (close)="close.next()"
>
</lib-search-filter>
