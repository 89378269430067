import { Injectable } from "@angular/core";
import { BusinessPartner, TranslationOption } from "@ceres/domain";
import BUSINESSPARTNERCONFIG from "../../../configs/business-partner/business-partner.config";
import {
    CeresBusinessPartnerConfig
} from "../../../configs/business-partner/interfaces/ceres-business-partner-config.interface";


@Injectable()
export class BusinessPartnerConfigService {
    businessPartnerConfig: CeresBusinessPartnerConfig = BUSINESSPARTNERCONFIG;
    getDisabledValues(): Partial<BusinessPartner> {
        const formData: Partial<BusinessPartner> = {};
        const conf = this.businessPartnerConfig;

        const subjectArea: TranslationOption = {
            id: 19,
            name: 'Others',
            translationKey: 'businesspartner.options.subjects.others',
            rankOrder: null,
        };

        const bpFunction: TranslationOption = {
            id: 5,
            name: 'Mitarbeiter',
            translationKey: 'functions.worker',
            rankOrder: null,
        };

        const language: TranslationOption = {
            id: 2,
            name: 'Englisch',
            translationKey: 'general.en',
            rankOrder: null,
        };

        const status: TranslationOption = {
            id: 1,
            name: 'Kunde',
            translationKey: 'businesspartner.options.status.customer',
            rankOrder: null,
        };

        for (const k in conf) {
            const isActive = conf[k]
            if (!isActive) {
                switch (k) {
                    case 'business-partner.salutation':
                        formData.salutation = '';
                        break;
                    case 'business-partner.title':
                        formData.title = '';
                        break;
                    case 'business-partner.subject-area':
                        formData.subjectArea = subjectArea;
                        break;
                    case 'business-partner.function':
                        formData.function = bpFunction;
                        break;
                    case 'business-partner.budget-responsibility':
                        formData.budgetResponsibility = null;
                        break;
                    case 'business-partner.language':
                        formData.language = language;
                        break;
                    case 'business-partner.status':
                        formData.status = status
                        break;
                    default:
                        break;
                }
            }
        }
        return formData;
    }

    validateLabel(label: string): boolean {
        return this.businessPartnerConfig[label];
    }
}
