<div  *ngIf='isAppInitialProcessLoaded; else loading'>
  <ceres-header id="header" class="lay-wrapper lay-wrapper--header"></ceres-header>
  <main>
    <!--ceres-breadcrumb></ceres-breadcrumb-->

    <router-outlet></router-outlet>
  </main>
  <ceres-control-center *ngIf="!isIE && (appUserService.loggedInUser$ | async)"></ceres-control-center>
</div>
<ng-template #loading>
  <ceres-header-placeholder id="header-placeholder" class="lay-wrapper lay-wrapper--header"></ceres-header-placeholder>
  <ceres-loading></ceres-loading>
</ng-template>

<ceres-footer></ceres-footer>

