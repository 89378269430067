import PROJECTTYPES from "../../../project-types/project-types.config";
import { Injectable } from "@angular/core";
import { ProjectProfile } from "../models";
import { ProjectProfileService } from "./project-profile.service";
import { TranslationOption } from "@ceres/domain";


export class ProjectTypeConfig {
  name: string;
  config: { [key: string]: boolean };
  referenceEmployee: number;
  referenceProjectPreliminary?: number;
}

@Injectable()
export class ProjectTypeService {
  types: ProjectTypeConfig[] = PROJECTTYPES;
  selectedTypeConf: ProjectTypeConfig;

  constructor(
    private projectService: ProjectProfileService
  ) { }

  async initializeProjectTypes() {
    PROJECTTYPES.forEach(type => this.types.push(type));
  }

  setProjectTypeConfig(typeName: string) {
    this.selectedTypeConf = this.types.find(type => type.name === typeName);
  }

  getMetaDataLocation(location) {
    const metaLocations = this.projectService.locations;
    return metaLocations.find(loc => loc.title === location.localityEnglish)
  }

  async getDisabledValues(location): Promise<Partial<ProjectProfile>> {
    const formData: Partial<ProjectProfile> = {};
    const conf = this.selectedTypeConf.config;

    const maturityLevel: TranslationOption = {
      id: 1,
      name: 'Project Idea',
      translationKey: 'projects.status.idea',
      rankOrder: 1,
    };

    for (const k in conf) {
      const isActive = conf[k]
      if (!isActive) {
        switch (k) {
          case 'new-project.master-data.mpNumber':
            break;
          case 'new-project.master-data.title':
            formData.mpTitle = 'Auto generated title';
            break;
          case 'new-project.master-data.description':
            formData.projektbeschreibung = 'Auto generated description';
            break;
          case 'new-project.master-data.comment':
            formData.projectComment = '';
            break;
          case 'new-project.master-data.project-space-title':
            formData.projectSpaceTitle = 'Auto generated project space title';
            break;
          case 'new-project.master-data.project-space-link':
            formData.projectSpaceTitle = 'Auto generated project space link';
            break;
          case 'new-project.accounting-info.project-classification':
            break;
          case 'new-project.master-data.project-lead':
            break;
          case 'new-project.master-data.customer':
            break;
          case 'new-project.master-data.planned-start':
            formData.startDate = new Date().toDateString();
            break;
          case 'new-project.master-data.planned-end':
            formData.endDate = new Date(new Date().getMonth() + 8).toDateString();
            break;
          case 'new-project.master-data.location':
            formData.location = location;
            break;
          case 'new-project.master-data.status':
            formData.projectStatus = 'projects.project-property-options.status.in-progress';
            break;
          case 'new-project.master-data.fsgGesamtprojekt':
            formData.fsgGesamtprojekt = 100;
            break;
          case 'new-project.master-data.maturity':
            formData.maturityLevel = maturityLevel;
            break;
          case 'new-project.accounting-info.customer-type':
            formData.kundenart = 'Siemens';
            break;
          case 'new-project.accounting-info.beneficial':
            formData.leistungsempfaenger = null;
            break;
          case 'new-project.accounting-info.cost-center':
            formData.costCenterInternal = null;
            break;
          case 'new-project.accounting-info.country-code':
            formData.verrechnungsland = 'DE';
            break;
          case 'new-project.accounting-info.currency':
            formData.currencyCode = 'EUR';
            break;
          case 'new-project.accounting-info.contract-type':
            formData.verrechnungsart = 'TimeAndMaterial';
            break;
          case 'new-project.accounting-info.accounting-cycle':
            formData.verrechnungszyklus = 'Monthly'
            break;
          case 'new-project.accounting-info.invest':
            formData.invest = false;
            break;
          case 'new-project.accounting-info.foreign-customer':
            formData.auslandskunde = false;
            break;
          case 'new-project.accounting-info.tech-approval':
            formData.approvalTec = false;
            break;
          case 'new-project.accounting-info.commercial-approval':
            formData.approvalCom = false;
            break;
          case 'new-project.accounting-info.project-group':
            formData.program = null;
            break;
          case 'new-project.accounting-info.project-employees':
            //formData.employees = null;
            break;
          case 'new-project.financials.show-templates':
            formData;
            break;
          default:
            break;
        }
      }

      if (isActive) {
        switch (k) {
          case 'new-project.accounting-info.customer-type-readOnly':
            formData.kundenart = 'Siemens';
            break;
          case 'new-project.accounting-info.contract-type-readOnly':
            formData.verrechnungsart = 'TimeAndMaterial';
            break;
          case 'new-project.accounting-info.accounting-cycle-readOnly':
            formData.verrechnungszyklus = 'Monthly'
            break;
        }
      }
    }
    return formData;
  }

  validateLabel(label: string): boolean {
    if (this.selectedTypeConf) {
      return this.selectedTypeConf.config[label];
    }

    return false;
  }

  getPreliminaryProjectReference() {
    const prelimConfig = this.types.find(type => type.name === 'Preliminary');
    return prelimConfig && prelimConfig.referenceProjectPreliminary ? prelimConfig.referenceProjectPreliminary : null;
  }
}
