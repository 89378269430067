import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserPickerComponent } from "./user-picker/user-picker.component";
import { ScdExternDialogComponent } from "./scd-extern-dialog/scd-extern-dialog.component";
import { ScdDialogComponent } from "./scd-dialog/scd-dialog.component";
import { BusinessPartnerSelectionComponent } from "./business-partner-selection/business-partner-selection.component";
import { TranslocoModule } from "@ngneat/transloco";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatIconModule } from "@angular/material/icon";
import {
  ButtonModule,
  HasPermissionModule,
  LoadingModule,
  SearchFieldModule,
  TranslateOrderModule,
  TranslationKeyExtractModule
} from "@ceres/ui";
import { FilterModule } from "@ceres/filter/module";
import { BusinessPartnerNewComponent } from "./business-partner-new/business-partner-new.component";
import { RouterModule } from "@angular/router";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoModule,
    DragDropModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
    LoadingModule,
    TranslationKeyExtractModule,
    FilterModule,
    SearchFieldModule,
    HasPermissionModule,
    TranslateOrderModule,
    RouterModule,
    ButtonModule,
  ],
  declarations: [
    UserPickerComponent,
    ScdExternDialogComponent,
    ScdDialogComponent,
    BusinessPartnerSelectionComponent,
    BusinessPartnerNewComponent
  ],
  exports: [UserPickerComponent, BusinessPartnerNewComponent]
})
export class SharedUserPickerModule {
}
