import { Inject, Injectable } from '@angular/core';
import { User, UserService } from './user';
import { ImpersonatedHttpClient } from './http';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { AuthUser } from '@app/auth/models/auth-user.model';
import { SCDService, SCDUser } from './scd';
import { BusinessPartnerService } from './business-partner.service';
import { Router } from '@angular/router';
import { AppUserService } from './app-user.service';

@Injectable({
  providedIn: 'root',
})
export class AppUserSessionService {

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: { edgeService: string, guestAccess: boolean },
    private readonly router: Router,
    private readonly http: ImpersonatedHttpClient,
    private readonly userService: UserService,
    private readonly businessPartnerService: BusinessPartnerService,
    private readonly appUserService: AppUserService,
    private readonly scdService: SCDService,
  ) {
  }

  public setSession(user: AuthUser): Promise<void> {
    return this.userService
      .getUser(user['custom:gid'])
      .then((user) => {
        this.loginSuccess(user);
      })
      .catch(async (error) => {
        if (this.environment?.guestAccess && error.status == 404) {
          await this.createGuestUser(user);
        } else {
          this.setSessionFailed();
        }
      });
  }

  private async createGuestUser(user: AuthUser): Promise<void> {
    try {
      let proceed = true;
      await this.userService.createGuestUserFromToken(user).catch(_ => proceed = false);

      const foundScdUsers: { records: SCDUser[] } = await (await this.scdService.getByGID(user.sub).toPromise());
      if (foundScdUsers && proceed) {
        await this.businessPartnerService.createBusinessPartnerFromSCDUser(foundScdUsers.records[0]);
      } else {
        throw new Error("[Authentication] No SCD User found or User exists in System.")
      }
      await this.router.navigate(['/']);
    } catch {
      this.setSessionFailed();
    }
  }

  private setSessionFailed(): void {
    this.appUserService.logout('/unauthorized');
  }

  private loginSuccess(user: User) {
    this.appUserService.setMainUser(user);
  }
}
