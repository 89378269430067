import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Employee } from './employee';
import {
  EmployeeMetadata,
  PaginationResponse,
  SCDResponse
} from '@ceres/domain';
import { AppUserService } from '../app-user.service';
import { ImpersonatedHttpClient } from '../http';
import { TranslocoService } from '@ngneat/transloco';
import { HttpParams } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ENVIRONMENT } from '@ceres/frontend-helper';

export enum EmployeeActiveFilter {
  All = 'GetAll',
  Active = 'GetActive',
  Inactive = 'GetInactive'
}

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  columnTitles = [
    'name',
    'contractType',
    'businessArea.title',
    'role',
    'portfolio',
    'sollstunden',
    'urlaubstage',
    'FK',
    'KFM',
    'skills'
  ];

  constructor(
    private httpClient: ImpersonatedHttpClient,
    private translate: TranslocoService,
    private appUserService: AppUserService,
    private router: Router,
    @Inject(ENVIRONMENT) private readonly environment: { edgeService: string }
  ) {}

  //NOT USED
  public async getUser(gid: string) {
    return await this.httpClient
      .get<Employee>(`${this.environment.edgeService}/employees/byGid/${gid}`)
      .pipe(
        catchError((e) => {
          if (e.status === 401) {
            this.router.navigateByUrl('/unauthorized');
            return EMPTY;
          } else {
            return throwError(e);
          }
        })
      )
      .toPromise();
  }

  public async getEmployeesByRole(): Promise<Employee[]> {
    let employees: Employee[] = [];

    if (this.appUserService.isAdmin() || this.appUserService.isSupport()) {      
      employees = await this.getAllUsers();
    } else if (this.appUserService.isGroupLeader()
      || this.appUserService.isTeamLeader() || this.appUserService.isTeamLeaderCharging() || this.appUserService.isLearningArchitect()) {
      employees = await this.getByBusinessAreaTree();
    }

    const loggedInUser = this.appUserService.loggedInUser$.getValue();
    const assignedEmployees = loggedInUser?.details?.assignedUsers
      ? loggedInUser?.details?.assignedUsers.map((user) => user.details)
      : [];
    const employeeList =
      loggedInUser && loggedInUser.details
        ? ([
            ...[loggedInUser.details],
            ...assignedEmployees,
            ...employees
          ] as Employee[])
        : [];

    const uniqEmployeeList = [
      ...new Map(
        employeeList.map((v) => [v.user ? v.user.gid.toUpperCase() : v.gid, v])
      ).values()
    ];
    return uniqEmployeeList.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async getAllUsers() {
    //hier ist der call
    return await this.httpClient
      .get<PaginationResponse<Employee>>(
        `${this.environment.edgeService}/employees`
      )
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  public async getAllUsersPicker() {
    //hier ist der call
    return await this.httpClient
      .get<PaginationResponse<Employee>>(
        `${this.environment.edgeService}/employees/picker`
      )
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  //NOT USED
  public getAllActiveUsers() {
    return this.getActiveEmployees();
  }

  public async getUserByEmail(email: string, own?: boolean): Promise<Employee> {
    let params = new HttpParams();
    const language = this.translate.getActiveLang();
    if (language) {
      params = params.append('language', language);
    }
    if (own) {
      params = params.append('own', 'true');
    }
    try {
      return await this.httpClient
        .get<Employee>(
          `${
            this.environment.edgeService
          }/employees/byEmail/${encodeURIComponent(email)}`,
          {
            params
          }
        )
        .toPromise();
    } catch (e) {
      console.error(`No user found with ${email}`);
      return Promise.reject();
    }
  }

  //TODO remove after user is implemented
  public async getEmployeeById(id: number) {
    const language = this.translate.getActiveLang();
    const user = await this.httpClient
      .get<Employee>(`${this.environment.edgeService}/employees/${id}`, {
        params: { language }
      })
      .toPromise();
    return user;
  }

  public getEmployeeByIdObs(id: number): Observable<Employee> {
    const language = this.translate.getActiveLang();
    return this.httpClient.get<Employee>(
      `${this.environment.edgeService}/employees/${id}`,
      {
        params: { language }
      }
    );
  }

  public async getPortfolioLeader(dept: string) {
    const language = this.translate.getActiveLang();
    const user = await this.httpClient
      .get<Employee[]>(
        `${this.environment.edgeService}/employees/portfolios/leader/${dept}`,
        { params: { language } }
      )
      .toPromise();
    return user;
  }

  public async getMerchants(dept: string) {
    const language = this.translate.getActiveLang();
    const user = await this.httpClient
      .get<Employee[]>(
        `${this.environment.edgeService}/employees/portfolios/merchants/${dept}`,
        { params: { language, dept } }
      )
      .toPromise();
    return user;
  }

  public async getByPortfolioID(id: number | null): Promise<Employee[]> {
    const language = this.translate.getActiveLang();
    const user = await this.httpClient
      .get<Employee[]>(
        `${this.environment.edgeService}/employees/portfolios/${id}`,
        { params: { language } }
      )
      .toPromise();
    return user;
  }

  public async getByBusinessAreaID(id: number | null): Promise<Employee[]> {
    const language = this.translate.getActiveLang();
    const user = await this.httpClient
      .get<Employee[]>(
        `${this.environment.edgeService}/employees/business/areas/${id}`,
        { params: { language } }
      )
      .toPromise();
    return user;
  }

  public async getByBusinessAreaTree(): Promise <Employee[]> {
    const user = await this.httpClient
      .get<Employee[]>(
        `${this.environment.edgeService}/employees/business/areas`
      )
      .toPromise();
    return user;
  }

  async getEmployeesByType(filter: EmployeeActiveFilter): Promise<Employee[]> {
    if (filter === EmployeeActiveFilter.All) {
      return await this.getAllUsers();
    } else if (filter === EmployeeActiveFilter.Active) {
      return await this.getActiveEmployees();
    } else {
      return await this.getInactiveEmployees();
    }
  }

  getActiveEmployees(): Promise<Employee[]> {
    return this.httpClient
      .get<PaginationResponse<Employee>>(
        `${this.environment.edgeService}/employees/active`
      )
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  getInactiveEmployees(): Promise<Employee[]> {
    return this.httpClient
      .get<PaginationResponse<Employee>>(
        `${this.environment.edgeService}/employees/inactive`
      )
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  async createUser(employee: Employee) {
    const user = await this.httpClient
      .post<Employee>(`${this.environment.edgeService}/employees`, employee)
      .toPromise();
    return user;
  }

  public async updateEmployee(employee: Employee) {
    const user = await this.httpClient
      .patch<Employee>(
        `${this.environment.edgeService}/employees/${employee.id}`,
        employee
      )
      .toPromise();
    return user;
  }

  public async getEmployeeMetadata(): Promise<EmployeeMetadata> {
    const language = this.translate.getActiveLang();
    return await this.httpClient
      .get<EmployeeMetadata>(
        `${this.environment.edgeService}/employees/metadata`,
        {
          params: { language }
        }
      )
      .toPromise();
  }

  async getSCDUserById(gid: string): Promise<SCDResponse> {
    return await this.httpClient
      .get<SCDResponse>(
        `${this.environment.edgeService}/scd-user-directory/${gid}`
      )
      .toPromise();
  }
}
