<mat-form-field class="mat-custom w-100" appearance="fill">
  <mat-label>{{placeholder}}</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input
      matStartDate
      formControlName="start"
      placeholder="Start date"
      readonly
    />
    <input
      matEndDate
      formControlName="end"
      placeholder="End date"
      readonly
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="isDisabled"></mat-datepicker-toggle>
  <mat-date-range-picker #picker [calendarHeaderComponent]="header"></mat-date-range-picker>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
    >Invalid start date</mat-error
  >
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
    >Invalid end date</mat-error
  >
</mat-form-field>
