import { BopService } from './services/bop.service';
import { ClassificationService } from '../program/services/classification.service';
import { ActivitiesService } from './services/activities.service';
import { TechnologiesService } from './services/technologies.service';
import { DateService } from '@ceres/shared/services';
import { ProjectProfileService } from './services/project-profile.service';
import { ControlCenterComponent } from './components/control-center/control-center.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslocoModule } from '@ngneat/transloco';
import { NavbarComponent } from './navbar/navbar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ClickOutsideModule } from 'ng-click-outside';
import { PaginatorService } from './services/paginator.service';

import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
  MatLegacyPaginatorModule as MatPaginatorModule,
  MatLegacyPaginatorIntl as MatPaginatorIntl
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeadroomModule } from '@ctrl/ngx-headroom';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderComponentPlaceholder } from './header-placeholder/header-component-placeholder.component';

import { CurrencyPipe } from './pipes/currency.pipe';
import { LocalizedDatePipe } from './pipes/date.pipe';
import { ngfModule } from 'angular-file';

import { CascadingDropdownComponent } from './components/cascading-dropdown/cascading-dropdown.component';
import { CascadePipe } from './pipes/cascade.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { CellNavigationService } from './services/cell-navigation.service';
import { FavoriteService } from './services/favorite.service';
import { NewsService } from './services/news.service';
import { PortfolioService } from './services/portfolio.service';
import { ImpersonationComponent } from './components/impersonation/impersonation.component';
import { AddNewsComponent } from './components/add-news/add-news.component';
import { ProgramService } from './services/program.service';
import { ProgramPickerComponent } from './components/program-picker/program-picker.component';
import { ProgramSelectionComponent } from './components/program-selection/program-selection.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProjectSelectionComponent } from './components/project-selection/project-selection.component';
import { SkillService } from './services/skill.service';
import { ContractBusinessAreasService } from './services/contract-business-areas.service';
import { ProgramDocumentService } from './services/program-document.service';
import { CapacityService } from './services/capacity.service';
import { CapacityCalculationService } from './services/time-calculation.service';
import { FolderService } from './services/folders.service';
import { BraketsPipe } from './pipes/brakets.pipe';
import { OrderPosNumPipe } from './pipes/orderPosNum.pipe';
import { ActualPricePipe } from './pipes/actualPrice.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { TimeSheetsService } from './services/time-sheets.service';
import { TimeSheetCalculationService } from './services/time-sheet-calculation.service';
import { BopNewDialogComponent } from './components/bop-new-dialog/bop-new-dialog.component';
import { ExtNamesPipe } from './pipes/ext-names.pipe';
import { BopAddDialogComponent } from './components/bop-add-dialog/bop-add-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SingleInputDialogComponent } from './components/single-input-dialog/single-input-dialog.component';
import { DiscardDialogComponent } from './components/discard-dialog/discard-dialog.component';
import { SearchComponent } from './header/search/search.component';
import { ErrorDialogComponent } from '../reports/dashboard/error-dialog/error-dialog.component';
import { ContractDocumentService } from '../contract/shared/document.service';
import { FilterModule } from '@ceres/filter/module';
import { FilterHeaderComponent } from './components/filter-header/filter-header.component';
import { ProjectSelectedPipe } from './pipes/project-selected.pipe';
import { SelectPipe } from './pipes/select.pipe';
import { FindPipe } from './pipes/find.pipe';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { EmployeesHaveAssignedActivitiesPipe } from './pipes/employees-have-assigned-activities.pipe';
import { EmployeeIsActivePipe } from './pipes/employee-is-active.pipe';
import { TimerPipe } from './pipes/timer.pipe';
import { ProjectChartService } from './services/project-chart.service';
import { WIDGET_COMPONENTS } from './components/widget';
import { SurroundingMonthsPipe } from './pipes/surrounding-months.pipe';
import { DisplayMonthPipe } from './pipes/display-month.pipe';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { ComingSoonComponent } from './badges/coming-soon/coming-soon.component';
import { BetaComponent } from './badges/beta/beta.component';
import { ServicePackagesService } from './services/service-packages.service';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ConvertCurrencyPipe } from './pipes/convert-currency.pipe';
import { CopyClipboardService } from './services/copy-clipboard.service';
import { DocPreviewDialogComponent } from './components/doc-preview-dialog/doc-preview-dialog.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { StepsVisualizerComponent } from './components/steps-visualizer/steps-visualizer.component';

import './register-locales';
import { ExRateBasedInputComponent } from './components/ex-rate-based-input/ex-rate-based-input.component';
import { FeaturesModule } from '../features/features.module';
import {
  ButtonModule,
  LayoutModule,
  ConfirmDialogModule,
  DisableControlModule,
  LoadingModule,
  OrderByModule,
  SearchFieldModule,
  TranslateOrderModule,
  TranslationKeyExtractModule
} from '@ceres/ui';
import { SharedUserPickerModule } from '@ceres/shared/user-picker';
import { ProjectTypeService } from './services/project-type.service';
import { HomeGuestUserService } from './services/home-guest-user.service';
import { SessionTimerComponent } from './session-timer/session-timer.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { InlineNavbarComponent } from './components/inline-navbar/inline-navbar.component';
import { DecimalQuarterPipe } from './pipes/decimal-quarter.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { EmployeeSettingsService } from '@ceres/shared/services';
import { ThemeService } from './services/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomFilterComponent } from './components/custom-filter/custom-filter.component';
import { CustomFilterSaveDialog } from './components/custom-filter-save-dialog/custom-filter-save-dialog.component';
import { ProjectFilterService } from '../project/services/project-filter.service';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CapacityColumnHeaderComponent } from './components/capacity-column-header/capacity-column-header.component';
import { ProjectPickerComponent } from "@app/shared/components/project-picker/project-picker.component";
import { BusinessPartnerConfigService } from "@app/shared/services/business-partner-config.service";
import { EmployeeConfigService } from '@app/shared/services/employee-config.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FeaturesModule.forChild(),
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    HeadroomModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSliderModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    NgxChartsModule,
    DragDropModule,
    FilterModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatBadgeModule,
    ClickOutsideModule,
    ngfModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300
    }),
    PdfViewerModule,
    OrderByModule,
    SharedUserPickerModule,
    LoadingModule,
    TranslationKeyExtractModule,
    SearchFieldModule,
    DisableControlModule,
    ConfirmDialogModule,
    TranslateOrderModule,
    ButtonModule,
    LayoutModule,
    MatSlideToggleModule,
    TextFieldModule
  ],
  exports: [
    TranslocoModule,
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NavbarComponent,
    BreadcrumbComponent,
    FooterComponent,
    HeaderComponent,
    HeaderComponentPlaceholder,
    SessionTimerComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatListModule,
    MatMenuModule,
    CapacityColumnHeaderComponent,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    CurrencyPipe,
    LocalizedDatePipe,
    TimerPipe,
    CascadePipe,
    DecimalQuarterPipe,
    CascadingDropdownComponent,
    ControlCenterComponent,
    ProgramPickerComponent,
    ProjectPickerComponent,
    NgxChartsModule,
    DragDropModule,
    DisableControlModule,
    SearchComponent,
    BraketsPipe,
    OrderPosNumPipe,
    ActualPricePipe,
    MatExpansionModule,
    ErrorDialogComponent,
    MatTabsModule,
    FilterModule,
    MatButtonToggleModule,
    BopNewDialogComponent,
    MatBadgeModule,
    ExtNamesPipe,
    ngfModule,
    FileUploadComponent,
    ClickOutsideModule,
    NgCircleProgressModule,
    SelectPipe,
    FindPipe,
    UploadProgressComponent,
    EmployeeIsActivePipe,
    WIDGET_COMPONENTS,
    SurroundingMonthsPipe,
    DisplayMonthPipe,
    ComingSoonComponent,
    BetaComponent,
    AutofocusDirective,
    ConvertCurrencyPipe,
    ExRateBasedInputComponent,
    EmployeeIsActivePipe,
    PdfViewerModule,
    StepsVisualizerComponent,
    OrderByModule,
    SharedUserPickerModule,
    LoadingModule,
    TranslationKeyExtractModule,
    SearchFieldModule,
    ConfirmDialogModule,
    ButtonModule,
    LayoutModule,
    InlineNavbarComponent,
    CustomFilterComponent,
    CustomFilterSaveDialog
  ],
  declarations: [
    AutofocusDirective,
    FilterHeaderComponent,
    NavbarComponent,
    BreadcrumbComponent,
    FooterComponent,
    HeaderComponent,
    HeaderComponentPlaceholder,
    SessionTimerComponent,
    BreadcrumbComponent,
    CurrencyPipe,
    LocalizedDatePipe,
    TimerPipe,
    CascadePipe,
    CascadingDropdownComponent,
    DecimalQuarterPipe,
    UniquePipe,
    ControlCenterComponent,
    ImpersonationComponent,
    AddNewsComponent,
    CapacityColumnHeaderComponent,
    ProgramPickerComponent,
    ProjectPickerComponent,
    ProgramSelectionComponent,
    ProjectSelectionComponent,
    BraketsPipe,
    OrderPosNumPipe,
    ActualPricePipe,
    ErrorDialogComponent,
    BopNewDialogComponent,
    ExtNamesPipe,
    BopAddDialogComponent,
    FileUploadComponent,
    SingleInputDialogComponent,
    DiscardDialogComponent,
    SearchComponent,
    ProjectSelectedPipe,
    SelectPipe,
    FindPipe,
    UploadProgressComponent,
    EmployeesHaveAssignedActivitiesPipe,
    EmployeeIsActivePipe,
    WIDGET_COMPONENTS,
    SurroundingMonthsPipe,
    DisplayMonthPipe,
    ComingSoonComponent,
    BetaComponent,
    ConvertCurrencyPipe,
    ExRateBasedInputComponent,
    EmployeeIsActivePipe,
    DocPreviewDialogComponent,
    PdfViewerDialogComponent,
    StepsVisualizerComponent,
    InlineNavbarComponent,
    CustomFilterComponent,
    CustomFilterSaveDialog
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: MatPaginatorIntl,
          useClass: PaginatorService
        },
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: true }
        },
        LocalizedDatePipe,
        TimerPipe,
        DecimalQuarterPipe,
        CellNavigationService,
        FavoriteService,
        ProjectProfileService,
        ProjectTypeService,
        BusinessPartnerConfigService,
        EmployeeConfigService,
        HomeGuestUserService,
        DateService,
        TechnologiesService,
        NewsService,
        PortfolioService,
        ActivitiesService,
        ProgramDocumentService,
        ProgramService,
        ClassificationService,
        BopService,
        SkillService,
        ContractBusinessAreasService,
        ProjectProfileService,
        ProjectChartService,
        CapacityService,
        CapacityCalculationService,
        FolderService,
        TimeSheetsService,
        TimeSheetCalculationService,
        ContractDocumentService,
        ServicePackagesService,
        CopyClipboardService,
        EmployeeSettingsService,
        ThemeService,
        OverlayContainer,
        ProjectFilterService
      ]
    };
  }
}
