<div
  class="drag"
  [ngClass]="{ active: dragging }"
  (dragover)="dragOver($event)"
  (dragenter)="dragStart()"
  (dragleave)="dragEnd()"
  (drop)="drop($event)"
  (click)="fileInput.click()"
>
  {{ 'shared.miscellaneous.drop' | transloco }}
</div>
<input
  type="file"
  #fileInput
  hidden
  (change)="fileClicked($event.target.files)"
  [multiple]="true"
  [accept]="allowedFileTypes"
/>
<div class="upload-container" *ngIf="!uploading">
  <div class="upload-item" *ngFor="let file of dndFiles; let i = index">
    <span class="upload-name">{{ file.name }}</span>
    <span>
      <i class="material-icons" (click)="removeFromUpload(i)">delete</i>
    </span>
  </div>
</div>

<div class="upload-container" *ngIf="uploading">
  <div class="upload-item" *ngFor="let upload of uploads">
    <div class="upload-name">{{ upload.name }}</div>
    <mat-progress-bar
      mode="determinate"
      [value]="upload.progress"
    ></mat-progress-bar>
  </div>
</div>
