import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileDialogComponent } from './upload-file-dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ButtonModule } from '../button/button.module';
import { UploadFileModule } from '../upload-file/upload-file.module';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    DragDropModule,
    ButtonModule,
    UploadFileModule
  ],
  declarations: [UploadFileDialogComponent],
  exports: [UploadFileDialogComponent]
})
export class UploadFileDialogModule {}
