/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentType } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { DateRangeType } from '@ceres/domain';
import { DateRange } from '../../interfaces/date-range.interface';
import { DateRangePickerFyHeaderComponent } from '../date-range-picker-fy-header/date-range-picker-fy-header.component';

import { DateService } from '@ceres/shared/services';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { getDateType } from '@app/shared/helpers/timezone';

export const DATE_RANGE_VALUE_ACCESSOR_PROVIDER = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangePickerComponent),
  multi: true,
};

@Component({
  selector: 'ceres-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [DATE_RANGE_VALUE_ACCESSOR_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent<T extends moment.Moment>
  implements OnChanges, OnDestroy, ControlValueAccessor {
  private readonly subscriptions = new Subscription();

  @Input() appearance?: 'project-list' | undefined;
  @Input() placeholder: string | null = null;

  public value: DateRange<T> | undefined;

  public isDisabled = false;
  public onChange!: (value: DateRange<T>) => void;
  public onTouched!: () => void;

  public readonly fiscalYears: {
    date: Date;
    dateEnd: Date;
    label: string;
  }[];

  public readonly fiscalYearCtrl = new FormControl();

  public readonly range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  @Input() dateRangeType: DateRangeType = DateRangeType.Dynamic;

  public header: ComponentType<unknown> | null = null;

  public constructor(
    private readonly dateService: DateService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.fiscalYears = this.dateService.getBusinessYearsWithTimezoneAdjustment();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['dateRangeType']) {
      this.header =
        this.dateRangeType === DateRangeType.FiscalYear
          ? DateRangePickerFyHeaderComponent
          : null;
    }
  }

  private setCurrentFiscalYear(): void {
    const currentFiscalYearIndex = ~~(this.fiscalYears.length / 2);
    const currentFiscalYear = this.fiscalYears[currentFiscalYearIndex];
    this.range.setValue({
      start: this.range.value.start ?? Object.assign(moment(currentFiscalYear.date)),
      end: this.range.value.end ?? Object.assign(moment(currentFiscalYear.dateEnd))
    });

    this.fiscalYearCtrl.setValue(currentFiscalYear);
  }

  private listenForRangeChanges(): void {
    this.subscriptions.add(
      this.range.valueChanges
        .pipe(
          filter(({ start, end }) => !!start && !!end),
          distinctUntilChanged((a: Partial<DateRange<T>>, b: Partial<DateRange<T>>) => {
            const aStart = a.start ?? '';
            const bStart = b.start ?? '';
            const aEnd = a.end ?? '';
            const bEnd = b.end ?? '';

            return aStart === bStart && aEnd === bEnd;
          })
        )
        .subscribe({
          next: ({ start, end }: Partial<DateRange<T>>) => {
            this.changeValue({
              start: getDateType(start as T) as unknown as T,
              end: getDateType(end as T) as unknown as T
            });
          }
        })
    );
  }

  public changeValue(value?: DateRange<T>) {
    this.value = value;

    if (this.value) {
      this.onChange(this.value);
    }

    if (this.range.touched) {
      this.onTouched();
    }
  }

  public writeValue(value: DateRange<T>): void {
    if (!value) {
      return;
    }

    if ((!moment(value?.start).isSame(moment(this.value?.start)) ||
      !moment(value?.end).isSame(moment(this.value?.end))) && moment(value?.end) > moment(value?.start)) {
      this.range.setValue({
        start: value.start,
        end: value.end,
      });
    }
  }

  public registerOnChange(fn: (value: DateRange<T>) => void): void {
    this.onChange = fn;

    this.listenForRangeChanges();
    this.setCurrentFiscalYear();
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.range.get('start')?.disable();
      this.range.get('end')?.disable();
    } else {
      this.range.get('start')?.enable();
      this.range.get('end')?.enable();
    }

    this.cdr.markForCheck();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
