import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Portfolio, PortfolioWithBusinessAreas } from '@ceres/domain';
import { Observable } from 'rxjs';
import { ImpersonatedHttpClient } from "@ceres/shared/services";

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  constructor(private httpClient: ImpersonatedHttpClient) {}

  public async getAll(): Promise<Portfolio[]> {
    return await this.httpClient
      .get<Portfolio[]>(`${environment.edgeService}/portfolios`)
      .toPromise();
  }

  public getAllWithBusinessAreas(): Observable<PortfolioWithBusinessAreas[]> {
    return this.httpClient.get<PortfolioWithBusinessAreas[]>(
      `${environment.edgeService}/portfolios/business/areas`
    );
  }

  public async create(portfolio: Portfolio) {
    return await this.httpClient
      .post<Portfolio[]>(`${environment.edgeService}/portfolios`, portfolio)
      .toPromise();
  }

  public async update(portfolio: Portfolio) {
    return await this.httpClient
      .patch<Portfolio[]>(
        `${environment.edgeService}/portfolios/${portfolio.id}`,
        portfolio
      )
      .toPromise();
  }

  public async delete(portfolio: Portfolio) {
    return await this.httpClient
      .delete(`${environment.edgeService}/portfolios/${portfolio.id}`)
      .toPromise();
  }
}
