import { ListItem } from "@ceres/frontend-helper";

const projectListColumns: ListItem[] = [
  { title: 'mpNumber', selected: true },
  { title: 'mpTitle', selected: true },
  { title: 'businessPartner.fullName', selected: true },
  { title: 'abteilungKunde', selected: true },
  { title: 'projectLead.name', selected: true },
  { title: 'plannedHours', selected: false },
  { title: 'actualHours', selected: false },
  { title: 'fsgGesamtprojekt', selected: false },
  { title: 'fsgExtern', selected: false },
  { title: 'projectStatus', selected: true },
  { title: 'verrechnungsart', selected: false },
  { title: 'verrechnungszyklus', selected: false },
  { title: 'bestellwert', selected: true },
  { title: 'auftragswert', selected: true },
  { title: 'hours-settled', selected: true },
  { title: 'hours-settled-value', selected: true },
  { title: 'proposal-billing-hours', selected: true },
  { title: 'proposal-billing-hours-value', selected: true },
  { title: 'linearForecast', selected: true },
  { title: 'linearForecastPercentage', selected: true },
  { title: 'restBudget', selected: true },
  { title: 'istkostenExtern', selected: false },
  { title: 'startDate', selected: false },
  { title: 'endDate', selected: false },
  { title: 'orgID', selected: false },
  { title: 'plannedExternalCosts', selected: false },
  { title: 'costCenterInternal', selected: true },
  { title: 'bestellnummer', selected: false },
  { title: 'projectComment', selected: false },
  { title: 'abgerechneterWert', selected: false },
  { title: 'partnerDepthStructure', selected: false },
  { title: 'contractNumber', selected: false },
  { title: 'forecastCostsOverallFY', selected: false },
  { title: 'forecastCostsExternalFY', selected: false },
  { title: 'contractNumber', selected: false },
  { title: 'projektbeschreibung', selected: false },
  { title: 'projectType.translationKey', selected: true }
];

export default projectListColumns;
