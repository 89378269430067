<div class="row"
  [ngClass]="{'main-container-spinner': !minimumHeight, 'more-space': minimumHeight, 'override-space': overrideSpace,  'inside-button': insideButton, 'loading-table': insideTable}"
  style="position:relative">
  <mat-spinner class="main-spinner" diameter="{{diameterSize ? diameterSize : 40}}" [mode]="spinnerMode"></mat-spinner>
  <div class="text custom-text-color" *ngIf="!minimumHeight && !insideButton && !noMessage">
    {{ message ? message : ('shared.miscellaneous.loading' | transloco)}}
  </div>
  <div class="text small custom-text-color" *ngIf="minimumHeight && !insideButton && !noMessage">
    {{ message ? message : ('shared.miscellaneous.loading' | transloco)}}</div>
  <div *ngIf="insideButton && !noMessage" class="insideButton">{{ message ? message : ('shared.miscellaneous.loading' | transloco)}}</div>
</div>
