import { Task as CeresTask, Employee, Portfolio } from '@ceres/domain';

export class Task implements CeresTask {
    
    id: number;
    title: string;
    description: string;
    employees: Employee[];
    status: string;
    module: string;
    created: Date;
    modified: Date;
    dueDate: Date;
    portfolio: Portfolio;
    action: string;
    EmployeesId: number[];
    itemId: number;

    constructor(object?: Partial<Task>) {
        if (object) {
            Object.assign(this, object);
        }
    }

}