import { AppUserService } from "@ceres/shared/services";
import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { navigation } from './navigation';
import { rolePermissions } from '@ceres/domain';
import { FeatureOf, FEATURES } from "@ceres/feature-toggles";
import { CeresFeaturesConfig } from "../../../features/interfaces/ceres-features-config.interface";

@Component({
  selector: 'ceres-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() isPartlyOpen: boolean;
  @Input() isMenuOpen: boolean;
  @Output() partlyOpen = new EventEmitter<boolean>();
  @Output() menuOpen = new EventEmitter<boolean>();
  navigation = navigation;
  permission = rolePermissions;
  public currentUser;

  constructor(
    @Inject(FEATURES) public readonly features: FeatureOf<CeresFeaturesConfig>,
    public translateService: TranslocoService,
    public appUserService: AppUserService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.appUserService.loggedInUser$.asObservable().subscribe(user => {
      this.currentUser = user;
    });
  }

  closeMenu() {
    if (!this.isMenuOpen) {
      return;
    }
    this.isMenuOpen = false;
    this.menuOpen.emit(false);
    this.partlyOpen.emit(false);
  }

  openSubMenu(elem, item: number) {
    this.isMenuOpen = true;
    this.menuOpen.emit(true);
    for (let i = 0; i < navigation.length; i++) {
      if (i === item) {
        navigation[item].open = !navigation[item].open;
      } else {
        navigation[i].open = false;
      }
    }
  }
}
