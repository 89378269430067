<div class="header--container">
  <ng-content select="[header]"></ng-content>
</div>
<div class="layout--body">
  <div class="sidebar--container">
    <ng-content select="[sidebar]"></ng-content>
  </div>
  <div
    class="content--container"
    [ngClass]="{
      'background--grey': background === 'gray' || background === 'grey',
      'background--white': background === 'white'
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
