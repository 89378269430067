import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppUserService, AppUserSessionService } from '@ceres/shared/services';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthUserService } from './auth-user.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private authUserService: AuthUserService,
    private appUserService: AppUserService,
    private appUserSessionService: AppUserSessionService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.oidcSecurityService.getUserData().pipe(
      map(response => {
        if (!response) {
          return false;
        }

        return response;
      }),
      switchMap(authUser => {
        if (!authUser) {
          return of(false);
        }

        return this.appUserService.loggedInUser$.pipe(map(user => {
            if (!user) {
              this.appUserSessionService.setSession(authUser);
              this.authUserService.loggedInAuthUser$.next(authUser);
            }

            return true;
          })
        );
      }),
      catchError((_) => {
        return of(false);
      })
    )
  }
}

