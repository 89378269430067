import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from './window';

// from https://indepth.dev/posts/1440/global-objects-in-angular
export const NAVIGATOR = new InjectionToken<Navigator>(
  'An abstraction over window.navigator object',
  {
    factory: () => inject(WINDOW).navigator
  }
);
