import { TasksService } from './shared/services/tasks.service';
import {
  AppUserService,
  EmployeeSettingsService
} from '@ceres/shared/services';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil, filter, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ThemeService } from './shared/services/theme.service';

export const isIE = false || !!(document as any).documentMode;

@Component({
  selector: 'ceres-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isAppInitialProcessLoaded: boolean = false;
  public isIE = isIE;

  private subscriptions: Subject<boolean> = new Subject();

  constructor(
    public translateService: TranslocoService,
    public appUserService: AppUserService,
    private taskService: TasksService,
    private employeeSettingsService: EmployeeSettingsService,
    private themeService: ThemeService
  ) {}

  //TODO ID1: look after this impelementation that it still works like it should
  // clean up code and extract methods
  ngOnInit() {
    this.appUserService.loggedInUser$
      .pipe(
        takeUntil(this.subscriptions),
        tap((user) => {
          if (!user) {
            setTimeout(() => {
              this.isAppInitialProcessLoaded = true;
            }, 10000);
          }
        }),
        filter((user) => !!(user && user.details && user.details.gid))
      )
      .subscribe((user) => {
        this.setSettings(user.details.gid);
        this.taskService.getMyTasksWithStatus(user.details.id, 'open');
        this.employeeSettingsService
          .getPersistentFiltersForAppInitialization(user.details.gid)
          .toPromise()
          .then(() => {
            this.isAppInitialProcessLoaded = true;
          });
      });

    console.log('CERES v 2023-11-17');
  }

  private setSettings(gid: string) {
    this.employeeSettingsService
      .getPersonalSettings(gid)
      .pipe(takeUntil(this.subscriptions))
      .subscribe((settings) => {
        this.themeService.applySettings(settings);
      });
  }

  ngOnDestroy() {
    this.subscriptions.next();
    this.subscriptions.unsubscribe();
  }
}
