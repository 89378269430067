import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const ID_TOKEN_KEY = 'id_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const EXP_HINT = 'expires_at';
export const NONCE_KEY = 'nonce';
export const EXP_FIELD = 'exp';

// AUTH CODE EXAMPLE: https://github.com/damienbod/angular-auth-oidc-client/blob/version-13/projects/sample-code-flow-auto-login-all-routes/src/app/navigation/navigation.component.ts
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;

  constructor(
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.isAuthenticated$ = this.oidcSecurityService.checkAuth().pipe(
      map((auth) => {
        return auth.isAuthenticated;
      })
    );
  }

  public login(): void {
    this.oidcSecurityService.authorize();
  }

  refreshSession() {
    this.oidcSecurityService.authorize();
  }

  public logout(): void {
    this.oidcSecurityService.logoff();
  }

}
