import { Injectable } from '@angular/core';
import { DateService } from "./date.service";
import { format } from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class DateValidationService {
  constructor(
    private dateService: DateService
  ) {
  }

  public recalculateDates(byEndDate, formGroup, startFieldName, endFieldName): boolean {
    const values = formGroup.getRawValue();
    if (!values[startFieldName] || !values[endFieldName]) return false;

    if (
      format(this.dateService.getStartOfFiscalYear(values[endFieldName]), 'yyyy-MM-dd')
      !== format(this.dateService.getStartOfFiscalYear(values[startFieldName]), 'yyyy-MM-dd')
    ) {
     if (byEndDate) {
        formGroup.patchValue({
          [startFieldName]: format(this.dateService.getStartOfFiscalYear(values[endFieldName]), 'yyyy-MM-dd')
        }, { emitEvent: false });
      } else {
        formGroup.patchValue({
          [endFieldName]: format(this.dateService.getEndOfFiscalYear(values[startFieldName]), 'yyyy-MM-dd')
        }, { emitEvent: false });
      }
      return true;
    } else if (values[startFieldName] > values[endFieldName]) { //Check if dates are valid to each other else assign other value to both formControls
      if (byEndDate) {
        formGroup.patchValue({
          [startFieldName]: values[endFieldName]
        }, { emitEvent: false });
      } else {
        formGroup.patchValue({
          [endFieldName]: values[startFieldName]
        }, { emitEvent: false });
      }
      return true;
    }
    return false;
  }

  public recalculateDatesModel(byEndDate, model, startFieldName, endFieldName): boolean {
    const values = model;
    if (!values[startFieldName] || !values[endFieldName]) return false;
    if (this.dateService.getStartOfFiscalYear(values[startFieldName]).getTime() !== this.dateService.getStartOfFiscalYear(values[endFieldName]).getTime()) {
      if (byEndDate) {
        model[startFieldName] = this.dateService.getStartOfFiscalYear(values[endFieldName]);
      } else {
        model[endFieldName] = this.dateService.getEndOfFiscalYear(values[startFieldName]);
      }
      return true;
    } else {
      if (values[startFieldName] > values[endFieldName]) {
        if (byEndDate) {
          model[startFieldName] = values[endFieldName];
        } else {
          model[endFieldName] = values[startFieldName];
        }
        return true;
      }
    }
    return false;
  }
}
