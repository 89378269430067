import {Injectable} from '@angular/core';

export interface NavigationCell {
  canLeave: boolean;
  canEnter: boolean;

  onEnter(): void;

  onLeave(): void;
}

type Cell = NavigationCell;

function leave(cell: Cell) {
  if (cell && cell.onLeave) {
    cell.onLeave();
  }
}

function enter(cell: Cell) {
  if (cell && cell.onEnter) {
    cell.onEnter();
  }
}

function enterNextInRow(currentCell: Cell, currentRow: Cell[], currentColIdx: number) {
  if (currentRow.length > currentColIdx) {
    const next = currentRow
      .slice(currentColIdx + 1)
      .find(cell => cell && cell.canEnter);
    if (next) {
      leave(currentCell);
      enter(next);
      return true;
    }
  }
  return false;
}

function enterPrevInRow(currentCell: Cell, currentRow: Cell[], currentColIdx: number) {
  if (currentColIdx > 0) {
    const prev = currentRow
      .slice(0, currentColIdx)
      .reverse()
      .find(cell => cell && cell.canEnter);
    if (prev) {
      leave(currentCell);
      enter(prev);
      return true;
    }
  }
  return false;
}

@Injectable()
export class CellNavigationService {
  private cells: Cell[][] = [];

  registerCell(rowIdx: number, colIdx: number, cell: Cell) {
    let row = this.cells[rowIdx];
    if (!row) {
      row = this.cells[rowIdx] = [];
    }
    row[colIdx] = cell;
  }

  unregisterCell(rowIdx: number, colIdx: number) {
    const row = this.cells[rowIdx];
    if (row) {
      row[colIdx] = null;
    }

    if (this.cells[rowIdx] && this.cells[rowIdx].filter(e => e === null).length >=
      this.cells[rowIdx].length - 1) {
      delete this.cells[rowIdx];

      this.cells = this.cells.filter(e => e);
    }
  }

  private getCell(rowIdx: number, colIdx: number) {
    const row = this.cells[rowIdx] || [];
    const cell = row[colIdx];
    return {
      row,
      cell,
      canLeave: !cell || cell.canLeave,
      canEnter: cell && cell.canEnter
    };
  }

  navigateRight(rowIdx: number, colIdx: number) {
    const cell = this.getCell(rowIdx, colIdx);
    const current = cell.cell;
    let currentRow = cell.row;
    const canLeave = cell.canLeave;

    if (canLeave) {
      do {
        if (enterNextInRow(current, currentRow, colIdx)) {
          return true;
        }
        rowIdx++;
        colIdx = 0;
        currentRow = this.cells[rowIdx] || [];
      } while (this.cells.length >= rowIdx);
    }
    return false;
  }

  navigateLeft(rowIdx: number, colIdx: number) {
    const cell = this.getCell(rowIdx, colIdx);
    const current = cell.cell;
    let currentRow = cell.row;
    const canLeave = cell.canLeave;

    if (canLeave) {
      do {
        if (enterPrevInRow(current, currentRow, colIdx)) {
          return true;
        }
        rowIdx--;
        currentRow = this.cells[rowIdx] || [];
        colIdx = currentRow.length;
      } while (rowIdx >= 0);
    }
    return false;
  }

  navigateDown(rowIdx: number, colIdx: number) {
    const {cell: current, canLeave} = this.getCell(rowIdx, colIdx);
    if (canLeave) {
      while (this.cells.length > rowIdx) {
        const {cell, canEnter} = this.getCell(++rowIdx, colIdx);
        if (canEnter) {
          leave(current);
          enter(cell);
          return true;
        }
      }
    }
    return false;
  }

  navigateUp(rowIdx: number, colIdx: number) {
    const {cell: current, canLeave} = this.getCell(rowIdx, colIdx);
    if (canLeave) {
      while (rowIdx > 0) {
        const {cell, canEnter} = this.getCell(--rowIdx, colIdx);
        if (canEnter) {
          leave(current);
          enter(cell);
          return true;
        }
      }
    }
    return false;
  }

  navigateEnd(rowIdx: number, colIdx: number) {
    const {cell: current, row: currentRow, canLeave} = this.getCell(rowIdx, colIdx);
    if (canLeave && currentRow.length > colIdx) {
      const last = currentRow
        .slice(colIdx + 1)
        .reverse()
        .find(cell => cell && cell.canEnter);
      if (last) {
        leave(current);
        enter(last);
        return true;
      }
    }
    return false;
  }

  navigateStart(rowIdx: number, colIdx: number) {
    const {cell: current, row: currentRow, canLeave} = this.getCell(rowIdx, colIdx);
    if (canLeave && colIdx > 0) {
      const first = currentRow
        .slice(0, colIdx)
        .find(cell => cell && cell.canEnter);
      if (first) {
        leave(current);
        enter(first);
        return true;
      }
    }
    return false;
  }
}
