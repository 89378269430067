<div class="pwo-filter filter-header">
  <div class="input">
    <mat-form-field class="mat-custom w-100 mat-fix-padding" appearance="fill">
      <mat-label>{{
        'shared.miscellaneous.search-dots' | transloco
      }}</mat-label>
      <input [(ngModel)]="searchTerm" (ngModelChange)="search()" matInput />
    </mat-form-field>
  </div>
</div>

<div class="loader" *ngIf="isLoading">
  <mat-spinner diameter="30"></mat-spinner>
</div>

<div class="pwo-filter filter-values" *ngIf="!isLoading">
  <mat-checkbox
    (change)="$event && toggleAll()"
    [checked]="allSelected"
    [indeterminate]="fewSelected"
  >
    {{ 'shared.buttons.select-all' | transloco }}
    {{ searchTerm.length > 0 ? 'search results' : '' }}
  </mat-checkbox>

  <mat-checkbox *ngIf="searchTerm.length > 0" [(ngModel)]="addToFilter">
    Add selection to filter
  </mat-checkbox>

  <cdk-virtual-scroll-viewport
    itemSize="1"
    class="selection-viewport"
    [style.height]="filterValues?.length * 24 + 'px'"
    minBufferPx="400"
    maxBufferPx="5000"
    appendOnly
  >
    <mat-checkbox
      *cdkVirtualFor="let f of filterValues"
      [class.empty]="f === undefined || f === null || f.toString().length === 0"
      (click)="$event.stopPropagation()"
      (change)="$event ? selection.toggle(f) : null"
      [checked]="selection.isSelected(f)"
    >
      {{
        isTranslationKey && f
          ? translationPrefix
            ? (translationPrefix + f | transloco)
            : (f | transloco)
          : f
      }}
    </mat-checkbox>
  </cdk-virtual-scroll-viewport>
</div>

<button
  (click)="apply()"
  [disabled]="isLoading"
  class="a-button a-button--pwo full"
  type="button"
  mat-button
>
  <mat-icon>check</mat-icon>
  {{ 'shared.buttons.apply' | transloco }}
</button>
