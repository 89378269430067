<div
  class="row dialog-header mb-2"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="col-11 left">
    <h4>{{ title | transloco}}</h4>
  </div>
  <div class="col-1 right">
    <button (click)="close.emit()">
      <i class="a-icon a-icon--close"></i>
    </button>
  </div>
</div>
