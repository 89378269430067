import { Injectable } from '@angular/core';
import { Document, SPFile } from '@ceres/domain';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { ImpersonatedHttpClient } from "@ceres/shared/services";

@Injectable()
export class ProgramDocumentService {
  private readonly documentLibraryName = 'GMSProjectDocuments';
  private readonly defaultFolder = `${environment.siteCollection}/${this.documentLibraryName}/`;

  copiedDocuments: any[];

  constructor(private httpClient: ImpersonatedHttpClient) {}

  public async getDocuments(folderUrl: string) {
    folderUrl = 'I3221';
    return await this.httpClient
      .get<Document[]>(
        `${environment.edgeService}/documents/programs/${folderUrl}`
      )
      .toPromise();
  }

  public async getItem(url: string) {
    return await this.httpClient
      .get<SPFile>(`${environment.edgeService}/documents/files`, {
        params: new HttpParams().append('url', url)
      })
      .toPromise();
  }

  //USED IN PRORGRAM DOCUMENT COMPONENT
  public async delete(urls: { file: string; folder: string }[]) {
    return await this.httpClient
      .delete(`${environment.edgeService}/documents/files`, {
        params: new HttpParams().append('urls', JSON.stringify(urls))
      })
      .toPromise();
  }

  public async upload(file: File, folder: string) {
    if (file.size > 6000000) {
      throw Error('File size too large');
    }
    const formData: FormData = new FormData();
    formData.append('file', file);
    //formData.append('folder', folder);

    return await this.httpClient
      .post<SPFile>(
        `${environment.edgeService}/documents/programs/${folder}/files`,
        formData
      )
      .toPromise();
  }

  //USED IN PRORGRAM DOCUMENT COMPONENT
  public async uploadMultiple(
    files: File[],
    projectID: number,
    currentFolder: string
  ): Promise<Document[]> {
    const values = [];
    if (!currentFolder) {
      currentFolder = this.defaultFolder + projectID;
    }
    for (const file of files) {
      const result = await this.upload(file, currentFolder);
      const item = await this.getItem((result as any).data.ServerRelativeUrl);
      values.push({ id: item.Id });
    }
    return values;
  }

  public async copy(document: {
    name: string;
    file: ArrayBuffer;
    currentFolder: string;
  }) {
    if (document.file.byteLength > 6000000) {
      throw Error('File size too large!');
    }
    return await this.httpClient
      .put(`${environment.edgeService}/documents/files/upload`, document)
      .toPromise();
  }

  //USED IN PRORGRAM DOCUMENT COMPONENT
  public async createFolder(
    name: string,
    projectID?: number,
    currentFolder?: string
  ): Promise<Document> {
    if (!currentFolder) {
      if (projectID) {
        currentFolder = this.defaultFolder + projectID;
      } else {
        currentFolder = this.defaultFolder;
      }
    }
    return await this.httpClient
      .post<Document>(`${environment.edgeService}/documents/programs/folders`, {
        name,
        folder: currentFolder
      })
      .toPromise();
  }
}
