import { PortfolioCategory } from './portfolio-category';
import { Portfolio } from './portfolio';
import { Employee } from './employee';
import { BusinessPartner } from './business-partner';
import { BusinessArea } from './business-area';
import {
  TranslationOption,
  TranslationOptionProbabilityClass
} from './translation-option';

export enum BopStatusType {
  None = 'None',
  InProgress = 'InProgress',
  Order = 'Order',
  Lost = 'Lost',
  Other = 'Other'
}

export interface Bop {
  id: number;
  bopNumber: string;
  bopLeader: Employee;
  portfolioCategory: PortfolioCategory;
  portfolio: Portfolio;
  businessArea: BusinessArea;
  plannedProjectStart: Date;
  plannedProjectEnd: Date;
  bopTitle: string;
  content: string;
  offerDate: Date;
  offerTitle: string;
  bopStatus: BopStatus;
  probabilityClass: TranslationOptionProbabilityClass;
  declineReason: TranslationOption;
  declineComment: string;
  businessPartner: BusinessPartner;
  chargeVolumeOwn: number;
  chargeVolumeOthers: number;
  materialCosts: number;
}

export interface BopStatus {
  status: BopStatusType;
  translationKey: string;
  rankOrder: number;
  id: number;
}

export interface BOPMetadata {
  lookupValues: BOPLookupValues;
}

export interface BOPLookupValues {
  portfolioCategories: PortfolioCategory[];
  portfolios: Portfolio[];
  businessAreas: BusinessArea[];
  declinedReasons: TranslationOption[];
  probabilityClasses: TranslationOptionProbabilityClass[];
  bopStatusOptions: BopStatus[];
}

export interface BopFilterValues {
  'bopStatus.translationKey': string[];
  'probabilityClass.probability': string[];
  'bopLeader.name': string[];
  'businessArea.title': string[];
  'businessPartner.fullName': string[];
  'businessPartner.department': string[];
  'businessPartner.countryCode': string[];
  updatedByEmail: string[];
}
